import React from 'react';

const SideMenu = () => (
    <svg
        width='2'
        height='10'
        viewBox='0 0 2 10'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M1 2C1.55228 2 2 1.55228 2 1C2 0.447715 1.55228 0 1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2Z'
            fill='#F4F4F4'
        />
        <path
            d='M1 6C1.55228 6 2 5.55228 2 5C2 4.44772 1.55228 4 1 4C0.447715 4 0 4.44772 0 5C0 5.55228 0.447715 6 1 6Z'
            fill='#F4F4F4'
        />
        <path
            d='M1 10C1.55228 10 2 9.55228 2 9C2 8.44771 1.55228 8 1 8C0.447715 8 0 8.44771 0 9C0 9.55228 0.447715 10 1 10Z'
            fill='#F4F4F4'
        />
    </svg>
);
export default SideMenu;
