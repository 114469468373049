import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';
// main
import AppLayout, { NoMatch } from 'layouts/AppLayout';
import Home from 'pages/Home';
import AuthLayout from 'layouts/AuthLayout';
import Login from 'pages/Login';
import Viewer from 'pages/Viewer';
import PatientList from 'pages/PatientList';
import PatientLayout from 'layouts/PatientLayout';

// dev
import DevAppLayout from 'layouts/dev/AppLayout';
import WorkspaceLayout from 'layouts/dev/WorkspaceLayout';
import DevHome from 'pages/dev/Home';
import BabylonTest from 'pages/dev/workspaces/BabylonTest';
import MeshTrimming from 'pages/dev/workspaces/MeshTrimming';

import { paths } from 'modules/defines/paths';
import ChartLink from 'pages/ChartLink';

const App = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            <Routes>
                {/* -- main app -- */}
                <Route
                    path={paths.home}
                    element={
                        <AppLayout
                            windowWidth={windowWidth}
                            windowHeight={windowHeight}
                        />
                    }
                >
                    <Route index element={<Home />} />
                    <Route path={paths.auth} element={<AuthLayout />}>
                        <Route path={paths.login} element={<Login />} />
                    </Route>
                    <Route path={paths.patient} element={<PatientLayout />}>
                        <Route
                            path={paths.patientList}
                            element={<PatientList />}
                        />
                    </Route>
                    <Route
                        path={paths.chartLink}
                        element={
                            <ChartLink
                                windowWidth={windowWidth}
                                windowHeight={windowHeight}
                            />
                        }
                    />
                    <Route path={paths.viewer} element={<Viewer />} />
                    {/* -- additional app pages here -- */}
                </Route>

                {/* -- dev workspaces -- */}
                <Route path={paths.dev} element={<DevAppLayout />}>
                    <Route index element={<DevHome />} />
                    <Route
                        path={paths.workspaces}
                        element={<WorkspaceLayout />}
                    >
                        <Route
                            path={paths.meshTrimming}
                            element={<MeshTrimming />}
                        />
                        <Route
                            path={paths.babylonTest}
                            element={<BabylonTest />}
                        />
                        {/* -- new workspace pages should go here -- */}
                    </Route>
                </Route>
                <Route path='*' element={<NoMatch />} />
            </Routes>
        </div>
    );
};

export default App;
