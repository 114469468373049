import React from 'react';

const Tooth11 = ({ color }) => (
    <svg
        style={{
            position: 'absolute',
            top: '0',
            left: '79px',
        }}
        width='13'
        height='32'
        viewBox='0 0 13 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M7.55911 31.3834C5.62208 31.3834 3.58765 30.8863 1.86705 27.3145C1.71555 27.0119 1.60193 26.7579 1.50453 26.5094C1.50453 26.5094 1.49912 26.4878 1.4883 26.4607C1.39091 26.223 1.29893 25.9906 1.21236 25.7529C0.471089 23.7373 0.460268 22.0947 1.17448 20.8735C1.93198 19.5766 3.51731 18.7228 5.8872 18.3338C6.7475 18.1933 7.53205 18.123 8.2138 18.123C9.74503 18.123 10.8055 18.4689 11.4602 19.1821C12.3043 20.1008 12.5748 21.711 12.3368 24.4074C11.7903 30.8538 9.76668 31.3888 7.56452 31.3888L7.55911 31.3834Z'
            fill={color || 'white'}
        />
        <path
            d='M1.74306 6V5.408H3.30306V0.912H3.24706L1.83906 2.224L1.44706 1.8L2.92706 0.416H3.97506V5.408H5.41506V6H1.74306ZM6.65609 6V5.408H8.21609V0.912H8.16009L6.75209 2.224L6.36009 1.8L7.84009 0.416H8.88809V5.408H10.3281V6H6.65609Z'
            fill={color || '#F4F4F4'}
        />
    </svg>
);

export default Tooth11;
