import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

const useDataExportStore = create((set, get) => ({
    // pathname: window.location.pathname,
    // setPathname: (newPathname) => set({ pathname: newPathname }),

    pathname: window.location.pathname,
    export: false,

    methods: {
        setPathname: (newPathname) => set({ pathname: newPathname }),

        setExport: (state) => set({ export: state }),
    },
}));

export const useDataExportStoreMethods = () =>
    useDataExportStore((state) => state.methods, shallow);
export const useDataExporAndMethods = (stateGetter, flags) => [
    useDataExportStore(stateGetter, flags),
    useDataExportStore((state) => state.methods, shallow),
];

export default useDataExportStore;
