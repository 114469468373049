import { Curve3, Vector3, Ray, MeshBuilder, Mesh, VertexData, VertexBuffer } from '@babylonjs/core';

// references:
// https://doc.babylonjs.com/features/featuresDeepDive/mesh/drawCurves#catmull-rom-spline -> https://playground.babylonjs.com/#1AU0M4#18
// https://doc.babylonjs.com/features/featuresDeepDive/mesh/creation/param/tube
// https://doc.babylonjs.com/features/featuresDeepDive/mesh/creation/param/lines - these have no controllable radius, stay same size on zooming (I think)

export const createSpline = (points, closed, material, radius, resolution, scene) => {
    const catmullRom = Curve3.CreateCatmullRomSpline(points, resolution, closed);
    const options =  {
        path: catmullRom.getPoints(),
        radius,
        tessellation: 16,
    }
    const lineMesh =  MeshBuilder.CreateTube("curve", options, scene);
    lineMesh.material = material;

    return [lineMesh, options.path];
};

const addProjectionOnMesh = (mesh, p, pi, pathOnMesh, scene, facetData) => {
    const [facetPositions, positions, indices, facetNormals] = facetData;
    let shortest = Number.MAX_VALUE;
    let closest = null;

    // find closest facet to given point on spline path
    const lp = Vector3.TransformCoordinates(p, mesh.getWorldMatrix());
    const facetsInBlock = mesh.getFacetsAtLocalCoordinates(lp.x, lp.y, lp.z);
    if (facetsInBlock) {
        for (let idx = 0; idx < facetsInBlock.length; idx++) {
            const fib = facetsInBlock[idx];
            const p0 = facetPositions[fib];
            const d = (p0.x - p.x) * (p0.x - p.x) + (p0.y - p.y) * (p0.y - p.y) + (p0.z - p.z) * (p0.z - p.z);
            if (d < shortest) {
                shortest = d;
                closest = fib;
            }
        }

        // project ray through spline point in facet normal direction to find point under path on mesh
        // construct a mesh with just the closest facet, to speed ray intersection
        // HEY, make this the whole local facet-block or several face neighborhood to increase chance of intersection
        const facetMesh = new Mesh("facet", scene);
        const fmPositions = [];
        const fmIndices = [0, 1, 2];
        let vi = 0;
        for (let i = 0; i < 3; i++) {
            const index = indices[3 * closest + i];
            for (let j = 0; j < 3; j++) {
                fmPositions[vi] = positions[index * 3 + j];
                vi += 1;
            }
        }
        const vertexData = new VertexData();
        vertexData.positions = fmPositions;
        vertexData.indices = fmIndices;
        vertexData.applyToMesh(facetMesh);

        // find intersect point on facet from ideal spline pos in direction of facet normal (try both directions as we don't know what side the ideal point is on)
        //  and use that intersect as the curve point.   Has the effect of pulling the curve point onto the facet perpendicular to the facet, retaining facet parallel positioning
        const normal = facetNormals[closest];
        const ray = new Ray(p, normal, 10);
        const pickInfo = scene.pickWithRay(ray, m => m === facetMesh, true);
        if (pickInfo.pickedMesh === facetMesh) {
            pathOnMesh[pi] = pickInfo.pickedPoint;
        }
        else {
            ray.direction.scaleInPlace(-1);
            const pickInfo = scene.pickWithRay(ray, m => m === facetMesh, true);
            if (pickInfo.pickedMesh === facetMesh) {
                pathOnMesh[pi] = pickInfo.pickedPoint;
            } else {
                pathOnMesh[pi] = null;  // missed!  we'll filter these nulls out to draw tube, but need to increase target mesh to some neighborhood
            }
        }
        facetMesh.dispose();
    } else {
        console.log('failed closestFace', lp);
        mesh.getFacetsAtLocalCoordinates(lp.x, lp.y, lp.z);
    }
}

export const createSplineOnMesh = (mesh, points, material, radius, resolution, scene) => {

    const facetData = [
        mesh.getFacetLocalPositions(),
        mesh.getVerticesData(VertexBuffer.PositionKind),
        mesh.getIndices(),
        mesh.getFacetLocalNormals(),
    ];

    const catmullRom = Curve3.CreateCatmullRomSpline(points, resolution, false);
    const crPoints = catmullRom.getPoints();
    const pathOnMesh = [];

    for (let pi = 0; pi < crPoints.length; pi++) {
        addProjectionOnMesh(mesh, crPoints[pi], pi, pathOnMesh, scene, facetData);
    }

    const tubeMesh =  MeshBuilder.CreateTube("curve", { path: pathOnMesh.filter(p => p != null), radius, tessellation: 16 }, scene);
    tubeMesh.material = material;

    return { tubeMeshes: [tubeMesh], crPoints, pathOnMesh, mesh, points, material, radius, resolution, scene };
};

export const updateSplineOnMesh = (curve, closed) => {
    const { tubeMeshes, crPoints, pathOnMesh, mesh, points, material, radius, resolution, scene } = curve;

    const facetData = [
        mesh.getFacetLocalPositions(),
        mesh.getVerticesData(VertexBuffer.PositionKind),
        mesh.getIndices(),
        mesh.getFacetLocalNormals(),
    ];

    // rebuild spline curve
    const catmullRom = Curve3.CreateCatmullRomSpline(points, resolution, closed);
    const newCrPoints = catmullRom.getPoints();

    // update path on mesh for extended curve & (re)construct segment tube meshes for any points that change
    const segmentsToBuild = new Set();
    for (let pi = 0; pi < newCrPoints.length - 1; pi++) {
        const p = newCrPoints[pi];
        if (pi >= crPoints.length || !p.equals(crPoints[pi])) {
            crPoints[pi] = p;
            segmentsToBuild.add(Math.floor(pi / resolution));
            addProjectionOnMesh(mesh, p, pi, pathOnMesh, scene, facetData);
        }
    }
    if (closed && tubeMeshes.length > points.length) {
        // been a deletion, remake all segment tubes
        segmentsToBuild.clear();
        points.forEach((p, i) => segmentsToBuild.add(i));
        tubeMeshes.forEach((tm, i) => { tm.dispose(); tubeMeshes[i] = null; });
        tubeMeshes.splice(0, points.length - 1);
    }

    // rebuild changed segment tubes
    segmentsToBuild.forEach(si => {
        if (tubeMeshes[si])
            tubeMeshes[si].dispose();
        const tubePoints = pathOnMesh.slice(si * resolution, (si + 1) * resolution).filter(p => p !== null);
        const tubeMesh =  MeshBuilder.CreateTube("curve", { path: tubePoints, radius, tessellation: 16 }, scene);
        tubeMesh.material = material;
        tubeMeshes[si] = tubeMesh;
    });
}

// https://doc.babylonjs.com/typedoc/classes/BABYLON.Mesh-1#getClosestFacetAtCoordinates
// https://dental.formlabs.com/blog/how-to-make-models-from-dental-3d-intraoral-scans/