import React from 'react';

const Close = ({ width, height, color, opacity }) => (
    <svg
        width={width || '10'}
        height={height || '10'}
        viewBox='0 0 10 10'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M10 0.875L9.125 0L5 4.125L0.875 0L0 0.875L4.125 5L0 9.125L0.875 10L5 5.875L9.125 10L10 9.125L5.875 5L10 0.875Z'
            fill={color || '#F4F4F4'}
            fillOpacity={opacity || '0.25'}
        />
    </svg>
);

export default Close;
