import React from 'react';
import { palette } from 'modules/defines/styles';
import styled from 'styled-components';
import Preview from 'components/board/Preview';
import PatientInfo from 'components/board/PatientInfo';

const PreviewWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: ${palette.primary.gray};
    /* margin: 30px 0; */
    /* background-color: white; */
`;

const PatientInfoWrapper = styled.div`
    width: 210px;
    margin-right: 112px;
    min-width: 210px;
`;

const ChartLinkWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    /* padding: 30px; */
`;

const ChartLinkContainer = ({ windowWidth, windowHeight }) => {
    return (
        <ChartLinkWrapper
            style={{
                padding: (windowWidth > 425 || windowHeight > 900) && '30px',
            }}
        >
            {(windowWidth > 425 || windowHeight > 900) && (
                <PatientInfoWrapper>
                    <PatientInfo />
                </PatientInfoWrapper>
            )}
            <PreviewWrapper className='PreviewWrapper'>
                <Preview
                    windowWidth={windowWidth}
                    windowHeight={windowHeight}
                />
            </PreviewWrapper>
            {(windowWidth > 425 || windowHeight > 900) && (
                <PatientInfoWrapper></PatientInfoWrapper>
            )}
        </ChartLinkWrapper>
    );
};

export default ChartLinkContainer;
