import React from 'react';

const LowerTeeth = ({ width, height }) => (
    <svg
        width={width || '16'}
        height={height || '16'}
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M1.3538 5.47545C0.854626 5.47545 0.478862 5.03336 0.573149 4.55893L0.871265 3.03049C1.14997 1.5991 2.43811 0.562622 3.937 0.562622H11.1791C12.678 0.562622 13.9662 1.5991 14.2463 3.02914L14.5444 4.55758C14.6345 5.01854 14.2851 5.44849 13.8081 5.47275'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M3.12429 7.52272C2.80121 7.52541 2.49755 7.54159 2.29372 7.55506C1.97897 7.57528 1.67808 7.39467 1.53804 7.08063C1.42572 6.82724 1.34114 6.4458 1.35501 5.87432C1.35501 5.7948 1.3661 4.06419 2.37969 3.14632C2.66533 2.88754 3.03277 2.76624 3.40161 2.76624H3.44043C3.80926 2.76624 4.17671 2.88754 4.46234 3.14632C5.47594 4.06419 5.48703 5.79615 5.48703 5.87432C5.50228 6.39324 5.44404 6.75445 5.35808 7.00515C5.23328 7.36367 4.90466 7.58067 4.56079 7.5618C4.32923 7.54832 3.9812 7.5308 3.71775 7.52406H3.12429V7.52272Z'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M7.34694 7.52272C7.02387 7.52541 6.72021 7.54158 6.51638 7.55506C6.20163 7.57528 5.90074 7.39467 5.76069 7.08063C5.64838 6.82724 5.5638 6.4458 5.57766 5.87432C5.57766 5.7948 5.58875 4.06419 6.60235 3.14632C6.88798 2.88754 7.25543 2.76624 7.62426 2.76624H7.66309C8.03192 2.76624 8.39936 2.88754 8.685 3.14632C9.69859 4.06419 9.70969 5.79615 9.70969 5.87432C9.72494 6.39324 9.6667 6.75445 9.58073 7.00515C9.45594 7.36367 9.12732 7.58067 8.78345 7.5618C8.55189 7.54832 8.20385 7.5308 7.9404 7.52406H7.34694V7.52272Z'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M11.469 7.52272C11.1459 7.52541 10.8423 7.54159 10.6384 7.55506C10.3237 7.57528 10.0228 7.39467 9.88276 7.08063C9.77045 6.82724 9.68587 6.4458 9.69973 5.87432C9.69973 5.7948 9.71083 4.06419 10.7244 3.14632C11.0101 2.88754 11.3775 2.76624 11.7463 2.76624H11.7852C12.154 2.76624 12.5214 2.88754 12.8071 3.14632C13.8207 4.06419 13.8318 5.79615 13.8318 5.87432C13.847 6.39324 13.7888 6.75445 13.7028 7.00515C13.578 7.36367 13.2494 7.58067 12.9055 7.5618C12.674 7.54832 12.3259 7.5308 12.0625 7.52406H11.469V7.52272Z'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M13.7634 9.64979C14.2626 9.64979 14.6383 10.0919 14.544 10.5663L14.2459 12.0948C13.9672 13.5261 12.6791 14.5626 11.1802 14.5626L3.93805 14.5626C2.43915 14.5626 1.15102 13.5261 0.870927 12.0961L0.572812 10.5677C0.482684 10.1067 0.832104 9.67675 1.30909 9.65249'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M11.9929 7.60253C12.316 7.59983 12.6196 7.58366 12.8235 7.57018C13.1382 7.54996 13.4391 7.73057 13.5792 8.04462C13.6915 8.29801 13.776 8.67944 13.7622 9.25092C13.7622 9.33044 13.7511 11.0611 12.7375 11.9789C12.4519 12.2377 12.0844 12.359 11.7156 12.359L11.6768 12.359C11.3079 12.359 10.9405 12.2377 10.6548 11.9789C9.64125 11.0611 9.63016 9.32909 9.63016 9.25092C9.61491 8.73201 9.67314 8.37079 9.75911 8.12009C9.8839 7.76157 10.2125 7.54457 10.5564 7.56344C10.788 7.57692 11.136 7.59444 11.3994 7.60118L11.9929 7.60118L11.9929 7.60253Z'
            fill='#0F62FE'
            stroke='#0F62FE'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M7.77024 7.60253C8.09332 7.59983 8.39698 7.58366 8.60081 7.57018C8.91556 7.54996 9.21645 7.73057 9.3565 8.04462C9.46881 8.29801 9.55339 8.67944 9.53953 9.25092C9.53953 9.33044 9.52843 11.0611 8.51484 11.9789C8.2292 12.2377 7.86176 12.359 7.49293 12.359L7.4541 12.359C7.08527 12.359 6.71782 12.2377 6.43219 11.9789C5.41859 11.0611 5.4075 9.32909 5.4075 9.25092C5.39225 8.73201 5.45049 8.37079 5.53646 8.12009C5.66125 7.76157 5.98987 7.54457 6.33374 7.56344C6.5653 7.57692 6.91333 7.59444 7.17678 7.60118L7.77024 7.60118L7.77024 7.60253Z'
            fill='#0F62FE'
            stroke='#0F62FE'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M3.64817 7.60253C3.97125 7.59983 4.27491 7.58366 4.47874 7.57018C4.79349 7.54996 5.09438 7.73057 5.23443 8.04462C5.34674 8.29801 5.43132 8.67944 5.41746 9.25092C5.41746 9.33044 5.40636 11.0611 4.39277 11.9789C4.10713 12.2377 3.73969 12.359 3.37086 12.359L3.33203 12.359C2.9632 12.359 2.59576 12.2377 2.31012 11.9789C1.29653 11.0611 1.28543 9.32909 1.28543 9.25092C1.27018 8.73201 1.32842 8.37079 1.41438 8.12009C1.53918 7.76157 1.8678 7.54457 2.21167 7.56344C2.44323 7.57692 2.79126 7.59444 3.05471 7.60118L3.64817 7.60118L3.64817 7.60253Z'
            fill='#0F62FE'
            stroke='#0F62FE'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export default LowerTeeth;
