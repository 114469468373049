import React from 'react';

const LowerTeethWithGum = ({ width, height }) => (
    <svg
        width={width || '16'}
        height={height || '16'}
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M1.3538 5.80626C0.854626 5.80626 0.478862 5.36417 0.573149 4.88974L0.871265 3.3613C1.14997 1.92991 2.43811 0.893433 3.937 0.893433H11.1791C12.678 0.893433 13.9662 1.92991 14.2463 3.35996L14.5444 4.88839C14.6345 5.34935 14.2851 5.7793 13.8081 5.80357'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M3.12429 7.85353C2.80121 7.85622 2.49755 7.8724 2.29372 7.88587C1.97897 7.90609 1.67808 7.72548 1.53804 7.41144C1.42572 7.15805 1.34114 6.77661 1.35501 6.20513C1.35501 6.12561 1.3661 4.395 2.37969 3.47713C2.66533 3.21835 3.03277 3.09705 3.40161 3.09705H3.44043C3.80926 3.09705 4.17671 3.21835 4.46234 3.47713C5.47594 4.395 5.48703 6.12696 5.48703 6.20513C5.50228 6.72405 5.44404 7.08527 5.35808 7.33596C5.23328 7.69448 4.90466 7.91148 4.56079 7.89261C4.32923 7.87914 3.9812 7.86161 3.71775 7.85487H3.12429V7.85353Z'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M7.34694 7.85353C7.02387 7.85622 6.72021 7.8724 6.51638 7.88587C6.20163 7.90609 5.90074 7.72548 5.76069 7.41144C5.64838 7.15805 5.5638 6.77661 5.57766 6.20513C5.57766 6.12561 5.58875 4.395 6.60235 3.47713C6.88798 3.21835 7.25543 3.09705 7.62426 3.09705H7.66309C8.03192 3.09705 8.39936 3.21835 8.685 3.47713C9.69859 4.395 9.70969 6.12696 9.70969 6.20513C9.72494 6.72405 9.6667 7.08526 9.58073 7.33596C9.45594 7.69448 9.12732 7.91148 8.78345 7.89261C8.55189 7.87913 8.20385 7.86161 7.9404 7.85487H7.34694V7.85353Z'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M11.469 7.85353C11.1459 7.85622 10.8423 7.8724 10.6384 7.88587C10.3237 7.90609 10.0228 7.72548 9.88276 7.41144C9.77045 7.15805 9.68587 6.77661 9.69973 6.20513C9.69973 6.12561 9.71083 4.395 10.7244 3.47713C11.0101 3.21835 11.3775 3.09705 11.7463 3.09705H11.7852C12.154 3.09705 12.5214 3.21835 12.8071 3.47713C13.8207 4.395 13.8318 6.12696 13.8318 6.20513C13.847 6.72405 13.7888 7.08527 13.7028 7.33596C13.578 7.69448 13.2494 7.91148 12.9055 7.89261C12.674 7.87914 12.3259 7.86161 12.0625 7.85487H11.469V7.85353Z'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M11.9929 7.93334C12.316 7.93064 12.6196 7.91447 12.8235 7.90099C13.1382 7.88077 13.4391 8.06138 13.5792 8.37543C13.6915 8.62882 13.776 9.01025 13.7622 9.58173C13.7622 9.66125 13.7511 11.3919 12.7375 12.3097C12.4519 12.5685 12.0844 12.6898 11.7156 12.6898L11.6768 12.6898C11.3079 12.6898 10.9405 12.5685 10.6548 12.3097C9.64125 11.3919 9.63016 9.6599 9.63016 9.58173C9.61491 9.06282 9.67314 8.7016 9.75911 8.4509C9.8839 8.09238 10.2125 7.87538 10.5564 7.89425C10.788 7.90773 11.136 7.92525 11.3994 7.93199L11.9929 7.93199L11.9929 7.93334Z'
            stroke='#0F62FE'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M7.77024 7.93334C8.09332 7.93064 8.39698 7.91447 8.60081 7.90099C8.91556 7.88077 9.21645 8.06138 9.3565 8.37543C9.46881 8.62882 9.55339 9.01025 9.53953 9.58173C9.53953 9.66125 9.52843 11.3919 8.51484 12.3097C8.2292 12.5685 7.86176 12.6898 7.49293 12.6898L7.4541 12.6898C7.08527 12.6898 6.71783 12.5685 6.43219 12.3097C5.4186 11.3919 5.4075 9.6599 5.4075 9.58173C5.39225 9.06282 5.45049 8.7016 5.53646 8.4509C5.66125 8.09238 5.98987 7.87538 6.33374 7.89425C6.5653 7.90773 6.91333 7.92525 7.17678 7.93199L7.77024 7.93199L7.77024 7.93334Z'
            stroke='#0F62FE'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M3.64817 7.93334C3.97125 7.93064 4.27491 7.91447 4.47874 7.90099C4.79349 7.88077 5.09438 8.06138 5.23443 8.37543C5.34674 8.62882 5.43132 9.01025 5.41746 9.58173C5.41746 9.66125 5.40636 11.3919 4.39277 12.3097C4.10713 12.5685 3.73969 12.6898 3.37086 12.6898L3.33203 12.6898C2.9632 12.6898 2.59576 12.5685 2.31012 12.3097C1.29653 11.3919 1.28543 9.6599 1.28543 9.58173C1.27018 9.06282 1.32842 8.7016 1.41438 8.4509C1.53918 8.09238 1.8678 7.87538 2.21167 7.89425C2.44323 7.90773 2.79126 7.92525 3.05471 7.93199L3.64817 7.93199L3.64817 7.93334Z'
            stroke='#0F62FE'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M13.7634 9.9806C14.2626 9.9806 14.6383 10.4227 14.544 10.8971L14.2459 12.4256C13.9672 13.857 12.6791 14.8934 11.1802 14.8934L3.93805 14.8934C2.43915 14.8934 1.15102 13.857 0.870927 12.4269L0.572812 10.8985C0.482684 10.4375 0.832104 10.0076 1.30909 9.9833'
            stroke='#0F62FE'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export default LowerTeethWithGum;
