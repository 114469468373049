import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import styled from 'styled-components/macro';

import { paths } from 'modules//defines/paths';
import HeaderContainer from 'containers/common/HeaderContainer';
import { GlobalStyles, palette, size } from 'modules/defines/styles';

const AppWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    /* margin: auto; */
    /* width: ${window.innerWidth}px; */
    width: ${(props) => props.windowWidth}px;
    height: ${(props) => props.windowHeight}px;
    color: ${palette.white};
    min-height: ${size.minHeight}px;
    min-width: ${size.minWidth}px;
`;

export const NoMatch = ({}) => {
    return (
        <div>
            <h2>Nothing to see here!</h2>
            <p>
                <Link to={paths.home}>Go to the home page</Link>
            </p>
        </div>
    );
};

const AppLayout = ({ windowWidth, windowHeight }) => {
    return (
        <>
            <GlobalStyles address={window.location.pathname} />
            <AppWrapper
                className='appWrapper'
                windowWidth={windowWidth}
                windowHeight={windowHeight}
            >
                <HeaderContainer />
                <Outlet />
                {/* <PopupDialogs /> */}
            </AppWrapper>
        </>
    );
};

export default AppLayout;
