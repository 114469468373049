import React from 'react';

const Tooth47 = ({ color }) => (
    <svg
        style={{
            position: 'absolute',
            bottom: '92px',
            left: '3px',
        }}
        width='40'
        height='19'
        viewBox='0 0 40 19'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M30.4263 18.3709C29.8906 18.3709 29.3333 18.3115 28.7598 18.1872C25.443 17.4793 21.7637 14.7506 21.3742 9.22811C21.0712 4.9647 21.9477 1.9387 23.836 0.712096C23.836 0.712096 23.8847 0.68508 23.8901 0.679676C23.9551 0.641851 24.0092 0.604036 24.0687 0.571615C24.5502 0.30684 25.0751 0.160939 25.627 0.144729C25.7839 0.144729 25.9354 0.139315 26.0869 0.133911C26.0923 0.133911 26.233 0.133911 26.233 0.133911C27.4774 0.133911 28.5001 0.2582 29.4902 0.377078C30.2098 0.463535 30.9511 0.55 31.7411 0.587825C35.7179 0.771546 38.0716 2.20348 38.196 4.52701C38.2502 5.53207 38.5207 6.7695 38.8345 8.20144C39.197 9.84953 39.6028 11.7137 39.7543 13.6104C39.8517 14.8586 38.9644 15.9339 37.1085 16.8147C37.0977 16.8147 37.0923 16.8255 37.0814 16.8255C36.8596 16.9282 36.6486 17.02 36.4322 17.1065C36.1778 17.2092 35.9398 17.2956 35.6963 17.3767C35.6855 17.3767 35.6638 17.3875 35.653 17.3929C35.4366 17.4631 35.1823 17.5442 34.928 17.6144C34.6629 17.6901 34.4031 17.7549 34.1434 17.8198C34.1434 17.8198 34.1434 17.8198 34.138 17.8198C33.8783 17.8792 33.6186 17.9387 33.3589 17.9927C33.3589 17.9927 33.3481 17.9927 33.3426 17.9927C33.0829 18.0467 32.8178 18.0954 32.5635 18.1332C32.5635 18.1332 32.5581 18.1332 32.5527 18.1332C32.0549 18.2142 31.5517 18.2791 31.0539 18.3331C30.8537 18.3547 30.6373 18.3655 30.4263 18.3655V18.3709Z'
            fill={color || 'white'}
        />
        <path
            // opacity='0.8'
            d='M3.57227 16.1953V15.0993H0.876266V14.5073L3.21227 10.6113H4.21227V14.5393H5.02027V15.0993H4.21227V16.1953H3.57227ZM1.50827 14.5393H3.57227V11.1313H3.53227L1.50827 14.5393ZM6.88529 16.1953L9.02129 11.1873H6.63729V12.2273H6.03729V10.6113H9.70129V11.2033L7.60529 16.1953H6.88529Z'
            fill={color || '#F4F4F4'}
        />
    </svg>
);

export default Tooth47;
