import React from 'react';

const SliderBack = ({ color }) => (
    <svg
        width='14'
        height='12'
        viewBox='0 0 14 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M1.61539 -1.0827e-06L1.61538 12L0 12L1.04907e-06 -1.22392e-06L1.61539 -1.0827e-06ZM14 0L14 12L4.68462 6L14 0Z'
            fill={color || '#525252'}
        />
    </svg>
);

export default SliderBack;
