import React from 'react';

const Minus = ({ width, height, color }) => (
    <svg
        width={width || '10'}
        height={height || '2'}
        viewBox='0 0 10 2'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M4.26609 1.82724H0.5V0.567984H4.26609L5.73391 0.567261L9.5 0.567984V1.82724H5.73391H4.26609Z'
            fill={color || 'white'}
        />
    </svg>
);

export default Minus;
