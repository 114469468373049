import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components/macro';

import { Panel } from 'components/dev/ui/panels';
import { buttonStyle } from 'components/dev/ui/buttons';

import { paths } from 'modules//defines/paths';

const DevHomeWrapper = styled(Panel)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 30px;
`;


const WSLink = styled(Link)`
    ${buttonStyle};
    font-size: 20px;
    text-decoration: none;
    padding: 15px 25px;
`;

const WorkspaceLinks = styled.div`
    display: flex;
    flex-direction: row;
  
    ${WSLink} + ${WSLink} {
        margin-left: 20px;
    }
`;

const DevHome = ({ }) => {
    return (
        <DevHomeWrapper>
            <WorkspaceLinks>
                <WSLink to={paths.babylonTest}>Babylon test</WSLink>
                <WSLink to={paths.meshTrimming}>Mesh trimming</WSLink>
            </WorkspaceLinks>
        </DevHomeWrapper>
    );
};

export default DevHome;