import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

// state store for the mesh-trimming worksppace.

export const useMeshTrimStore = create((set, get) => ({
    testMode: 'none',

    methods: {
        setTestMode: (testMode) => set({ testMode }),
    }
}));

export const useMeshTrimStoreMethods = () => useMeshTrimStore(state => state.methods, shallow);
export const useMeshTrimStoreAndMethods = (stateGetter, flags) => [
    useMeshTrimStore(stateGetter, flags),
    useMeshTrimStore(state => state.methods, shallow,
)];
