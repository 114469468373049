import { createGlobalStyle } from 'styled-components';
import { paths } from './paths';

export const fonts = {
    logo: 'Proxima Nova',
    ibm: 'IBM Plex Sans',
};

export const zIndex = {
    popupDialog: 100,
    modal: 2000,
};

// need to redefine the colors after defined in figma
export const palette = {
    white: '#fff',
    text: '#3c3c3b',
    gray: {
        0: '#F4F4F4',
        1: '#6f6f6f',
        2: '#393939',
        3: '#C4C4C4',
        4: '#353535',
        5: '#525252',
        6: '#C6C6C6',
        7: 'rgba(141, 141, 141, 0.24)',
        8: '#8d8d8d41',
        light: '#dfdfdf',
        middle: '#f7f7f7',
        border: '#c8c8c8',
        dark: '#979797',
    },

    blue: {
        0: '#4589ff',
        1: '#002D9C',
        2: '#78A9FF',
        3: '#0F62FE',
    },

    shadow: '#9fbcba',
    black: '#3c3c3b',

    primary: {
        gray: '#262626',
        blue: '#0F62FE',
        linkBlue: '#78A9FF',
        black: '#161616',
    },

    disabled: {
        backgroundColor: '#525252',
        textColor: 'rgba(244, 244, 244, 0.25)',
    },
};

export const size = {
    header: 48,
    minHeight: 700,
    minWidth: 1100,
    modalWidth: 705,
};

export const GlobalStyles = createGlobalStyle`
  * {
     font-family: ${fonts.ibm};
  }
  body {
    letter-spacing: 0.16px;
    -ms-overflow-style: none;
  }

  body::-webkit-scrollbar {
    display: none;
  }

  body div {
    box-sizing: border-box;
  }

  button {
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  input {
    background: transparent;
        border: 0;
        outline: none;
  }
  @media screen and (max-width: 425px) and (max-height: 900px) {
    .appWrapper  {
        ${(props) => props.address === paths.chartLink && `min-width: 200px;`}
    }

    .PatientWrapper {
        ${(props) =>
            props.address === paths.chartLink &&
            `width: 100%; height: 100%; margin-top: 0; min-width: 200px;`}
    }

    .PreviewWrapper {
        ${(props) =>
            props.address === paths.chartLink && `width: 100%; margin: 0`}
    }
    
    .headerWrapper {
        ${(props) =>
            props.address === paths.chartLink &&
            `min-width: 200px; height: 60px;`}
    }
    
    .previewBodyContainer {
        ${(props) =>
            props.address === paths.chartLink &&
            `height: 100%; align-items: center; justify-content: flex-start;`}
    }
    
    .imageWrapper {
        ${(props) =>
            props.address === paths.chartLink && `height: 28%; width: 85%;`}
    }

    .innerButtonContainer {
        ${(props) =>
            props.address === paths.chartLink &&
            `width: 100%; padding: 12px 30px; margin-bottom: 0`}
    }

    .previewSliderContainer {
        ${(props) =>
            props.address === paths.chartLink &&
            `width: 100%; padding: 12px 30px; margin: 0;`}
    }

    .sliderControllersContainer {
        ${(props) =>
            props.address === paths.chartLink &&
            `width: 100%; padding: 12px 30px;`}
    }
    }
    `;
