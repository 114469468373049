import React from 'react';

const StraightCutting = () => (
    <svg
        width='69'
        height='20'
        viewBox='0 0 69 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <circle cx='15.5' cy='9.5' r='2' fill='white' stroke='white' />
        <circle cx='54.5' cy='9.5' r='2' fill='white' stroke='white' />
        <line x1='18' y1='9.5' x2='53' y2='9.5' stroke='white' />
        <circle cx='34.5' cy='9.5' r='2' fill='white' stroke='white' />
    </svg>
);

export default StraightCutting;
