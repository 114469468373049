import React from 'react';

const Reset = () => (
    <svg
        width='15'
        height='16'
        viewBox='0 0 15 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M0.5 8C0.5 9.38447 0.910543 10.7378 1.67971 11.889C2.44888 13.0401 3.54213 13.9373 4.82122 14.4672C6.1003 14.997 7.50776 15.1356 8.86563 14.8655C10.2235 14.5954 11.4708 13.9287 12.4497 12.9497C13.4287 11.9708 14.0954 10.7235 14.3655 9.36563C14.6356 8.00777 14.497 6.6003 13.9672 5.32122C13.4373 4.04214 12.5401 2.94889 11.389 2.17972C10.2378 1.41055 8.88447 1 7.5 1C5.49474 0.997785 3.56594 1.76931 2.11538 3.15385'
            stroke='white'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M2.6537 1L2.11523 3.15384L4.26908 3.69231'
            stroke='white'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export default Reset;
