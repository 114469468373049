import React from 'react';

const Tooth21 = ({ color }) => (
    <svg
        style={{
            position: 'absolute',
            // top: '22px',
            left: '95px',
        }}
        width='13'
        height='32'
        viewBox='0 0 13 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M4.8744 31.3834C2.66684 31.3834 0.643233 30.8484 0.0967521 24.4074C-0.141319 21.711 0.134624 20.1008 0.973283 19.1821C1.62798 18.4689 2.68848 18.123 4.21971 18.123C4.90145 18.123 5.68601 18.1933 6.54631 18.3338C8.9162 18.7228 10.5015 19.582 11.259 20.8735C11.9732 22.0947 11.957 23.7373 11.2212 25.7529C11.1346 25.9906 11.0426 26.2176 10.9506 26.4391C10.9398 26.4607 10.9236 26.504 10.9182 26.5256C10.8262 26.7579 10.7125 27.0065 10.5665 27.2983C8.84044 30.8809 6.81143 31.378 4.86899 31.3834H4.8744Z'
            fill={color || 'white'}
        />
        <path
            d='M7.42025 6H3.75625V5.312L5.58025 3.632C5.84158 3.392 6.06025 3.13867 6.23625 2.872C6.41225 2.6 6.50025 2.328 6.50025 2.056V1.96C6.50025 1.65067 6.41492 1.4 6.24425 1.208C6.07358 1.016 5.81492 0.92 5.46825 0.92C5.29758 0.92 5.14825 0.944 5.02025 0.992C4.89225 1.04 4.78025 1.10667 4.68425 1.192C4.59358 1.27733 4.51625 1.37867 4.45225 1.496C4.39358 1.608 4.34558 1.73067 4.30825 1.864L3.70825 1.632C3.76158 1.46667 3.83358 1.30667 3.92425 1.152C4.02025 0.992 4.13758 0.850666 4.27625 0.728C4.42025 0.605333 4.59092 0.506666 4.78825 0.432C4.99092 0.357333 5.22825 0.32 5.50025 0.32C5.77225 0.32 6.01492 0.36 6.22825 0.44C6.44158 0.52 6.62025 0.632 6.76425 0.776C6.90825 0.92 7.01758 1.09067 7.09225 1.288C7.17225 1.48533 7.21225 1.704 7.21225 1.944C7.21225 2.16267 7.18025 2.36533 7.11625 2.552C7.05758 2.73867 6.97492 2.91733 6.86825 3.088C6.76158 3.25333 6.63092 3.416 6.47625 3.576C6.32692 3.736 6.16425 3.896 5.98825 4.056L4.48425 5.408H7.42025V6ZM8.77328 6V5.408H10.3333V0.912H10.2773L8.86928 2.224L8.47728 1.8L9.95728 0.416H11.0053V5.408H12.4453V6H8.77328Z'
            fill={color || '#F4F4F4'}
        />
    </svg>
);

export default Tooth21;
