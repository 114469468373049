import React from 'react';

const Plus = ({ width, height, color }) => (
    <svg
        width={width || '10'}
        height={height || '10'}
        viewBox='0 0 10 10'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M4.26609 9.80664V5.93627H0.5V4.67701H4.26609V0.806641H5.73391V4.67701H9.5V5.93627H5.73391V9.80664H4.26609Z'
            fill={color || 'white'}
        />
    </svg>
);

export default Plus;
