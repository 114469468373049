import { RectangularButton } from 'components/ui/webButtons';
import { fonts, palette, size, zIndex } from 'modules/defines/styles';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { InnerButtonContainer } from 'components/ui/containers';
import Close from 'components/icons/Close';
import Setting from 'components/icons/Setting';
import Draggable from 'react-draggable';

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: ${zIndex.modal};
    pointer-events: 'none';
`;

const ModalLayout = styled.div`
    ${(props) =>
        props.type === 'marker'
            ? `
            width: 300px;
            position: fixed;
            right: 30px;
            top: 420px;
            background-color: ${palette.primary.gray};
            `
            : props.type === 'movement'
            ? `
            width: 300px;
            position: fixed;
            right: 30px;
            top: 420px;
            background-color: ${palette.primary.gray};
            `
            : props.type === 'overlap'
            ? `
            width: 300px;
            background-color: ${palette.primary.gray};
            `
            : `
            width: ${
                props.type === 'treatment'
                    ? '480px'
                    : props.type === 'step'
                    ? '321px'
                    : '590px'
            };
            position: fixed;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: ${zIndex.modal};
            background-color: ${palette.primary.gray};
            display: flex;
            flex-direction: column;
            justify-content: space-between;
             `}
`;

const ModalTitle = styled.div`
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: ${palette.gray[0]};
    height: 60px;
    display: flex;
    align-items: center;
    padding: 16px;
    justify-content: space-between;
`;

export const ModalText = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${palette.gray[0]};
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    justify-content: space-between;
`;

const Modal = ({
    primaryText,
    secondaryText,
    title,
    iconType,
    onClick,
    children,
    onClose,
    onSetting,
    type,
}) => {
    return (
        <>
            {type !== 'marker' && type !== 'movement' && type !== 'overlap' && (
                <ModalOverlay />
            )}

            <ModalLayout type={type}>
                <ModalTitle>
                    {title}
                    {iconType && (
                        <button onClick={onSetting ? onSetting : onClose}>
                            {iconType === 'setting' ? (
                                <Setting />
                            ) : (
                                <Close
                                    color={
                                        type === 'marker' ||
                                        type === 'movement' ||
                                        type === 'overlap'
                                            ? 'white'
                                            : undefined
                                    }
                                    opacity={
                                        type === 'marker' ||
                                        type === 'movement' ||
                                        type === 'overlap'
                                            ? '1'
                                            : undefined
                                    }
                                />
                            )}
                        </button>
                    )}
                </ModalTitle>
                {children}
                {type !== 'movement' && type !== 'overlap' && (
                    <InnerButtonContainer
                        height='40px'
                        marginTop={
                            (type === '' || type === undefined) && '60px'
                        }
                        backgroundColor='transparent'
                    >
                        <RectangularButton
                            backgroundColor={palette.primary.gray}
                            width='50%'
                            marginLeft='auto'
                            justifyContent='start'
                            color={palette.blue[2]}
                            onClick={onClose}
                        >
                            {secondaryText}
                        </RectangularButton>
                        <RectangularButton
                            backgroundColor={palette.primary.blue}
                            width='50%'
                            marginLeft='auto'
                            justifyContent='start'
                            onClick={onClick}
                        >
                            {primaryText}
                        </RectangularButton>
                    </InnerButtonContainer>
                )}
            </ModalLayout>
        </>
    );
};

export default Modal;
