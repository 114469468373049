import React, { useState } from 'react';
import { palette } from 'modules/defines/styles';
import styled from 'styled-components';
import { InnerButtonContainer, InnerContainers } from '../ui/containers';
import { RectangularButton, SquareButton } from '../ui/webButtons';
import StatusIcon from '../icons/StatusIcon';
import FileUpload from './FileUpload';
import { GridCell } from './BoardBody';
import Slider from './Slider';
import SliderBack from '../icons/SliderBack';
import SliderPlay from '../icons/SliderPlay';
import SliderFoward from '../icons/SliderFoward';
import { paths } from 'modules/defines/paths';
import PatientInfo from './PatientInfo';

const PreviewBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90%;
    border-left: 1px solid ${palette.gray[2]};
`;

const PatientInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 131px; */
    background-color: ${palette.gray[4]};
    padding: 12px 30px;
`;

// TODO: Data 유무에 따라서 컬러 바꾸기 // palette.gray[5], palette.primary.blue

const Preview = ({ windowWidth, windowHeight }) => {
    const teethStatus = ['Occlusion', 'Maxillary', 'Mandibular'];
    const [teethStatusTest, setTeethStatusTest] = useState('');

    return (
        <PreviewBodyContainer className='previewBodyContainer'>
            {/* width 72% */}
            {windowWidth &&
                windowWidth <= 425 &&
                windowHeight &&
                windowHeight <= 900 && (
                    <PatientInfoWrapper>
                        <PatientInfo type='mobile' />
                    </PatientInfoWrapper>
                )}

            <InnerButtonContainer
                className='innerButtonContainer'
                width='72%'
                height='10%'
                justifyContent='space-between'
                marginTop='0'
                marginBottom='12px'
            >
                {teethStatus.map((el, idx) => (
                    <RectangularButton
                        key={idx}
                        width='100%'
                        height='100%'
                        backgroundColor='inherit'
                        fontSize='12px'
                        borderTop={
                            teethStatusTest === el
                                ? `2px solid ${palette.blue[0]}`
                                : '2px solid rgba(141, 141, 141, 0.5)'
                        }
                        color='rgba(244, 244, 244, 0.25)'
                        onClick={() => setTeethStatusTest(el)}
                    >
                        {el}
                        <div style={{ marginLeft: '8px' }}>
                            <StatusIcon />
                        </div>
                    </RectangularButton>
                ))}
            </InnerButtonContainer>
            <FileUpload>Click here to add new patience</FileUpload>
            <InnerContainers
                className='previewSliderContainer'
                width='72%'
                height='10%'
                marginTop='17px'
            >
                <GridCell
                    style={{
                        height: '50%',
                        fontSize: '12px',
                        color: palette.gray[6],
                    }}
                    paddingLeft='0'
                >
                    Steps
                </GridCell>
                <GridCell style={{ height: '50%' }} paddingLeft='0'>
                    {/* 컬러 내려줘서 Slider에서 받아서 써야함. */}
                    <Slider width='100%' />
                </GridCell>
            </InnerContainers>
            <InnerContainers
                className='sliderControllersContainer'
                width='45%'
                style={{ flexDirection: 'row' }}
                height='11%'
            >
                <SquareButton height='100%' backgroundColor='inherit'>
                    <SliderBack color={palette.primary.blue} />
                </SquareButton>
                <SquareButton height='100%' backgroundColor='inherit'>
                    <SliderPlay color={palette.primary.blue} />
                </SquareButton>
                <SquareButton height='100%' backgroundColor='inherit'>
                    <SliderFoward color={palette.primary.blue} />
                </SquareButton>
            </InnerContainers>
        </PreviewBodyContainer>
    );
};

export default Preview;
