export const paths = {
    home: '/',
    auth: '/auth',
    login: '/auth/login',
    patient: '/patient',
    patientList: '/patient/list',
    chartLink: '/patient/chartId',
    viewer: '/viewer',

    dev: '/dev',
    workspaces: '/dev/ws',
    meshTrimming: '/dev/ws/mesh-trimming',
    babylonTest: '/dev/ws/babylon-test',
};

export const APIServer = 'http://localhost:1337'; // hard-coded local Strapi server for now

export const apiPaths = {
    listMeshes: '/api/upload/files',
    uploadMesh: '',
};
