import React from 'react';
import styled from 'styled-components/macro';

import { useSceneManager, useSceneManagerMethods } from 'modules//stores/scene-manager';

import { LabeledBox } from 'components/dev/ui/panels';
import { ButtonSet } from 'components/dev/ui/buttons';

const SceneManager = styled(LabeledBox)`
    padding: 20px;
    &:before { content: 'Scene manager'; }
`;

const SceneManagerPanel = ({ }) => {
    const { showInspector, ssaoEnabled } = useSceneManager();
    const { resetScene, toggleInspector, enableSSAO } = useSceneManagerMethods();

    return (
        <SceneManager>
            <LabeledBox>
                <ButtonSet width={125}>
                    <button onClick={resetScene}>Reset scene</button>
                    <button onClick={toggleInspector}>{`${showInspector ? 'Dis' : 'En'}able Inspector`}</button>
                    <button disabled={ssaoEnabled} onClick={enableSSAO}>Enable AO</button>
                </ButtonSet>
            </LabeledBox>
        </SceneManager>
    );
};

export default SceneManagerPanel;