import React from 'react';

const BrushSmooth = () => (
    <svg
        width='22'
        height='16'
        viewBox='0 0 22 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <circle cx='11.501' cy='8.00562' r='7' stroke='white' />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M13.001 15.3556C16.4243 14.6607 19.001 11.6341 19.001 8.00562C19.001 4.37718 16.4243 1.35055 13.001 0.655642C13.4856 0.557261 13.9873 0.505615 14.501 0.505615C18.6431 0.505615 22.001 3.86348 22.001 8.00562C22.001 12.1478 18.6431 15.5056 14.501 15.5056C13.9873 15.5056 13.4856 15.454 13.001 15.3556Z'
            fill='#F4F4F4'
            fillOpacity='0.25'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9.99805 0.644412C6.57469 1.33932 3.99805 4.36595 3.99805 7.99438C3.99805 11.6228 6.57469 14.6495 9.99805 15.3444C9.51338 15.4427 9.01175 15.4944 8.49805 15.4944C4.35591 15.4944 0.998047 12.1365 0.998048 7.99438C0.998048 3.85225 4.35591 0.494384 8.49805 0.494385C9.01175 0.494385 9.51338 0.54603 9.99805 0.644412Z'
            fill='#F4F4F4'
            fillOpacity='0.25'
        />
        <circle cx='11.498' cy='7.99438' r='1.5' fill='white' />
    </svg>
);

export default BrushSmooth;
