import React from 'react';

const SliderPlay = ({ color }) => (
    <svg
        width='10'
        height='12'
        viewBox='0 0 10 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path d='M0 12V0L9.31538 6L0 12Z' fill={color || '#525252'} />
    </svg>
);

export default SliderPlay;
