import React from 'react';

const Tooth15 = ({ color }) => (
    <svg
        style={{
            position: 'absolute',
            top: '54px',
            left: '13px',
        }}
        width='34'
        height='17'
        viewBox='0 0 34 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M27.2607 16.5392C26.6547 16.5392 26.0433 16.5067 25.3886 16.4311C23.3758 16.2149 21.5416 15.0964 20.6056 13.5132C19.7777 12.1136 19.7236 10.4764 20.4378 8.77424C22.4073 4.14338 24.3714 3.60303 25.6808 3.60303C26.8279 3.60303 28.1535 4.07854 29.8579 5.09441C31.0212 5.78607 31.9356 6.52095 32.5849 7.27745C32.5849 7.27745 32.5849 7.28285 32.5903 7.28826C32.7039 7.42334 32.8067 7.55303 32.9149 7.69893L32.942 7.73675C33.1259 8.00153 33.2828 8.2717 33.4127 8.54188L33.4289 8.5743C33.4884 8.70399 33.5425 8.83367 33.5858 8.96336C33.6345 9.11466 33.6724 9.24434 33.7049 9.36863C33.7049 9.36863 33.7049 9.37403 33.7049 9.37944C33.7319 9.50912 33.7536 9.6334 33.7752 9.75768V9.81172C33.7969 9.936 33.8077 10.0603 33.8131 10.1846C33.8131 10.19 33.8131 10.217 33.8131 10.2224C33.8131 10.3197 33.8131 10.4385 33.8131 10.552C33.8131 10.552 33.8131 10.5844 33.8131 10.5898C33.8077 10.7303 33.7969 10.8492 33.7806 10.9627L33.7698 11.0437C33.7536 11.1464 33.7373 11.2491 33.7157 11.3463C33.7157 11.3463 33.7103 11.3842 33.7103 11.3896C33.6832 11.5192 33.6562 11.6273 33.6291 11.7354L33.5967 11.8543C33.5696 11.9569 33.5371 12.0596 33.4993 12.1623L33.456 12.2865C33.4289 12.3622 33.4019 12.4378 33.3748 12.5135L33.3261 12.6432C33.2882 12.7405 33.245 12.8431 33.2017 12.9404L33.1476 13.0593C33.0989 13.1673 33.0448 13.2754 32.9961 13.3835C32.9311 13.5132 32.8662 13.6428 32.7959 13.7671L32.7093 13.913C32.666 13.9887 32.6227 14.0589 32.5794 14.1292C32.547 14.1778 32.5145 14.2264 32.482 14.2751C32.4334 14.3507 32.3901 14.4047 32.3522 14.4588C32.3089 14.5182 32.2656 14.5777 32.2169 14.6317C32.1791 14.6749 32.1412 14.7182 32.1087 14.7668C32.06 14.8262 32.0059 14.8857 31.9572 14.9397L31.8598 15.037C31.5352 15.3612 31.1781 15.626 30.7723 15.8421L30.7344 15.8637C30.637 15.9123 30.5234 15.9664 30.4152 16.015L30.3827 16.0312C30.2474 16.0853 30.1284 16.1339 30.0039 16.1717L29.9661 16.1825C29.2464 16.4149 28.3753 16.5392 27.3743 16.5446H27.2878L27.2607 16.5392Z'
            fill={color || 'white'}
        />
        <path
            d='M1.0292 5.76758V5.17558H2.5892V0.679578H2.5332L1.1252 1.99158L0.733195 1.56758L2.2132 0.183578H3.2612V5.17558H4.7012V5.76758H1.0292ZM9.22222 0.775578H6.77422L6.61422 2.90358H6.67022C6.80356 2.71691 6.95289 2.56758 7.11822 2.45558C7.28889 2.34358 7.52089 2.28758 7.81422 2.28758C8.05422 2.28758 8.27556 2.32758 8.47822 2.40758C8.68089 2.48758 8.85689 2.60224 9.00622 2.75158C9.15556 2.89558 9.27022 3.07424 9.35022 3.28758C9.43556 3.50091 9.47822 3.74358 9.47822 4.01558C9.47822 4.28758 9.43556 4.53824 9.35022 4.76758C9.26489 4.99158 9.13956 5.18624 8.97422 5.35158C8.81422 5.51158 8.61689 5.63691 8.38222 5.72758C8.15289 5.81824 7.89156 5.86358 7.59822 5.86358C7.36356 5.86358 7.15556 5.83691 6.97422 5.78358C6.79822 5.73024 6.64089 5.65824 6.50222 5.56758C6.36356 5.47691 6.24089 5.37558 6.13422 5.26358C6.03289 5.15158 5.93956 5.03424 5.85422 4.91158L6.35822 4.52758C6.42756 4.63958 6.49956 4.74091 6.57422 4.83158C6.65422 4.92224 6.73956 4.99958 6.83022 5.06358C6.92622 5.12758 7.03556 5.17824 7.15822 5.21558C7.28089 5.24758 7.42756 5.26358 7.59822 5.26358C7.98222 5.26358 8.27289 5.16224 8.47022 4.95958C8.66756 4.75691 8.76622 4.47158 8.76622 4.10358V4.02358C8.76622 3.65558 8.66756 3.37024 8.47022 3.16758C8.27289 2.96491 7.98222 2.86358 7.59822 2.86358C7.34222 2.86358 7.13956 2.91158 6.99022 3.00758C6.84622 3.09824 6.71556 3.20758 6.59822 3.33558L6.03022 3.25558L6.23022 0.183578H9.22222V0.775578Z'
            fill={color || '#F4F4F4'}
        />
    </svg>
);

export default Tooth15;
