import Slider from 'components/board/Slider';
import Modal from 'components/common/Modal';
import Check from 'components/icons/Check';
import DropClose from 'components/icons/DropClose';
import DropDown from 'components/icons/DropDown';
import OverlapOneViewer from 'components/icons/OverlapOneViewer';
import OverlapSplitViewer from 'components/icons/OverlapSplitViewer';
import DetailModal from 'components/ui/DetailModal';
import {
    InnerButtonContainer,
    InnerContainers,
} from 'components/ui/containers';
import { RectangularButton } from 'components/ui/webButtons';
import { palette, zIndex } from 'modules/defines/styles';
import React, { useState } from 'react';
import { Rectangle } from 'react-babylonjs';
import Draggable from 'react-draggable';
import styled from 'styled-components';

const MovingBarWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 23px;
`;

const MovingBar = styled.div`
    width: 70px;
    height: 3px;
    background-color: ${palette.gray[3]};
    cursor: pointer;
`;

const OverlapModalWrapper = styled.div`
    position: fixed;
    right: 30px;
    top: 420px;
    background-color: ${palette.primary.gray};
    z-index: ${zIndex.modal};
`;

const TreatmentHistory = styled.div`
    width: 100%;
    min-height: 32px;
    display: flex;
    font-size: 14px;
    justify-content: ${(props) =>
        props.className === 'title' ? 'flex-start' : 'space-between'};
    color: ${(props) => props.color};
    align-items: center;
    padding: 7px 15px;
    :hover {
        background-color: ${palette.gray[4]};
    }
    ${(props) =>
        props.selected &&
        `
        background-color: ${palette.gray[2]};
        `}
`;

const OverlapModal = ({ handleModal, handleViewer, currentViewer, test }) => {
    const [list, setList] = useState(false);
    const [selected, setSelected] = useState();

    const handleSelected = (idx) => {
        if (selected === idx) {
            setSelected(null);
        } else {
            setSelected(idx);
        }
    };

    return (
        <Draggable
            handle='#movingPoint'
            // position={deltaPosition}
            bounds='body'
        >
            <OverlapModalWrapper>
                <MovingBarWrapper>
                    <MovingBar id='movingPoint' />
                </MovingBarWrapper>
                <Modal
                    title='Overlap'
                    type='overlap'
                    iconType='close'
                    // primaryText='Delete'
                    // secondaryText='Delete All'
                    onClose={() => handleModal('')}
                >
                    <InnerContainers
                        height='210px'
                        justifyContent='flex-start'
                        padding='0 16px 20px'
                    >
                        <DetailModal
                            height='28px'
                            padding='0'
                            justifyContent='flex-start'
                        >
                            Select step to compare
                        </DetailModal>
                        <DetailModal
                            height='32px'
                            justifyContent='space-between'
                            flexDirection='row'
                            padding='0'
                            alignItems='center'
                        >
                            Original
                            <button onClick={() => setList(!list)}>
                                {!list ? (
                                    <DropDown />
                                ) : (
                                    <DropClose color='white' />
                                )}
                            </button>
                        </DetailModal>
                        <DetailModal
                            height='55px'
                            padding='0'
                            flexDirection='row'
                            alignItems='center'
                        >
                            <Slider
                                type='overlap'
                                width='90%'
                                margin='0'
                                barColor={palette.primary.blue}
                                backColor={palette.gray[1]}
                                thumbColor={palette.primary.blue}
                                step='1'
                            />
                        </DetailModal>
                        <InnerButtonContainer
                            justifyContent='space-between'
                            height='40px'
                        >
                            <RectangularButton
                                width='130px'
                                backgroundColor={
                                    !currentViewer
                                        ? palette.gray[1]
                                        : palette.gray[2]
                                }
                                onClick={() => handleViewer(false)}
                            >
                                <OverlapOneViewer />
                            </RectangularButton>

                            <RectangularButton
                                width='130px'
                                backgroundColor={
                                    currentViewer
                                        ? palette.gray[1]
                                        : palette.gray[2]
                                }
                                onClick={() => handleViewer(true)}
                            >
                                <OverlapSplitViewer />
                            </RectangularButton>
                        </InnerButtonContainer>
                    </InnerContainers>
                </Modal>
                {/* Overlap list */}
                {list && (
                    <InnerContainers
                        height='200px'
                        type='treatmentList'
                        style={{
                            justifyContent: 'flex-start',
                            position: 'absolute',
                            top: '140px',
                            left: '0',
                            zIndex: zIndex.modal,
                        }}
                    >
                        {test.map((el, idx) => (
                            <TreatmentHistory
                                key={idx}
                                onClick={() => handleSelected(idx)}
                                selected={selected === idx}
                            >
                                {el}
                                <button
                                    style={{
                                        padding: 0,
                                        height: '20px',
                                    }}
                                >
                                    {selected === idx && <Check />}
                                </button>
                            </TreatmentHistory>
                        ))}
                    </InnerContainers>
                )}
            </OverlapModalWrapper>
        </Draggable>
    );
};

export default OverlapModal;
