import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal, { ModalText } from 'components/common/Modal';
import { fonts, palette, zIndex } from 'modules/defines/styles';
import { useProcess } from 'modules/stores/treatment-process';
import DetailModal from 'components/ui/DetailModal';
import ViewerLayout from 'layouts/ViewerLayout';
import UpperTeethWithGum from 'components/icons/UpperTeethWithGum';
import Hide from 'components/icons/Hide';
import Slider from 'components/board/Slider';
import LowerTeeth from 'components/icons/LowerTeeth';
import LowerTeethWithGum from 'components/icons/LowerTeethWithGum';
import useDataExportStore, {
    useDataExportStoreMethods,
} from 'modules/stores/dataExport';
import Gums from 'components/icons/Gums';
import SelectedTeeth from 'components/icons/SelectedTeeth';
import OnTheProcess from 'components/icons/OnTheProcess';
import AnalysisMenu from './menu/AnalysisMenu';
import PreparationMenu, { SliderContainer } from './menu/PreparationMenu';
import SegmentationMenu from './menu/SegmentationMenu';
import TreatmentMenu, { BoardHead } from './menu/TreatmentMenu';
import PatientInfo from 'components/board/PatientInfo';
import StepBoard from 'components/board/StepBoard';
import ExportBoard from 'components/board/ExportBoard';
import MainViewer from './MainViewer';
import Show from 'components/icons/Show';

const ViewerCenterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    background-color: inherit;
`;

const MainViewerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex: 1;
`;

const ProcessBar = styled.div`
    display: flex;
    width: 71%;
    height: 44px;
`;

const DetailProcessBar = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: inherit;
    height: 100%;
    width: 100%;
    background-color: inherit;
    border: none;
    outline: none;
    border-top: ${(props) =>
        props.active
            ? `2px solid ${palette.blue[0]}`
            : `2px solid ${palette.gray[2]}`};
    padding: 0;
    padding-top: 8px;
    svg {
        margin-right: 10px;
    }
    cursor: pointer;
`;

const ProcessTextBox = styled.div`
    display: flex;
    font-size: ${(props) => props.fontSize};
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '')};
    color: ${(props) => (props.color ? props.color : '')};
`;
export const MenuWrapper = styled.div`
    /* height: 690px; */
    width: 208px;
    & > div:first-child {
        margin-bottom: 10px;
    }
    background-color: ${palette.gray.primary};
    display: flex;
    flex-direction: column;
`;
const ExportHeader = [
    { width: '10%', label: null, check: false },
    { width: '34%', label: 'Select All', check: false },
    { width: '28%', label: 'Maxillary', check: false },
    { width: '28%', label: 'Mandibular', check: false },
];

const tempFile = [
    {
        select: false,
        name: '88_8888-88-88-88:88:88',
        maxillary: false,
        mandibular: false,
    },
    {
        select: false,
        name: '88_8888-88-88-88:88:88',
        maxillary: false,
        mandibular: false,
    },
    {
        select: false,
        name: '88_8888-88-88-88:88:88',
        maxillary: false,
        mandibular: false,
    },
    {
        select: false,
        name: '88_8888-88-88-88:88:88',
        maxillary: false,
        mandibular: false,
    },
    {
        select: false,
        name: '88_8888-88-88-88:88:88',
        maxillary: false,
        mandibular: false,
    },
    {
        select: false,
        name: '88_8888-88-88-88:88:88',
        maxillary: false,
        mandibular: false,
    },
    {
        select: false,
        name: '88_8888-88-88-88:88:88',
        maxillary: false,
        mandibular: false,
    },
    {
        select: false,
        name: '88_8888-88-88-88:88:88',
        maxillary: false,
        mandibular: false,
    },
    {
        select: false,
        name: '88_8888-88-88-88:88:88',
        maxillary: false,
        mandibular: false,
    },
    {
        select: false,
        name: '88_8888-88-88-88:88:88',
        maxillary: false,
        mandibular: false,
    },
    {
        select: false,
        name: '88_8888-88-88-88:88:88',
        maxillary: false,
        mandibular: false,
    },
    {
        select: false,
        name: '88_8888-88-88-88:88:88',
        maxillary: false,
        mandibular: false,
    },
    {
        select: false,
        name: '88_8888-88-88-88:88:88',
        maxillary: false,
        mandibular: false,
    },
    {
        select: false,
        name: '88_8888-88-88-88:88:88',
        maxillary: false,
        mandibular: false,
    },
];
// 디자인 부분
const ViewerComponents = ({ isSave, setIsSave, onSave }) => {
    const exportState = useDataExportStore((state) => state.export);
    const { setExport } = useDataExportStoreMethods();
    const { treatmentPhase, treatmentProcess } = useProcess();
    const [viewer, setViewer] = useState(false);
    const [show, setShow] = useState([2, 2, 2, 2, 2]);
    const [headerList, setHeaderList] = useState(ExportHeader);
    const [fileList, setFileList] = useState(tempFile);
    const processBarName = [
        'Preparation',
        'Segmentation',
        'Analysis',
        'Treatment',
    ];

    const teethViewerItems = [
        { component: <UpperTeethWithGum width='20px' height='20px' /> },
        { component: <LowerTeethWithGum width='20px' height='20px' /> },
        { component: <Gums width='20px' height='20px' /> },
        { component: <LowerTeeth width='20px' height='20px' /> },
        { component: <SelectedTeeth width='20px' height='20px' /> },
    ];

    // 단계별로 클릭 시 화면 띄우기 Zustand 가장 기본적인 함수
    const handleTreatmentProcess = (state) => {
        treatmentPhase(state);
    };

    const handleModal = () => {
        setExport(false);
    };

    const handleAllCheck = (e, i) => {
        const arr = [];
        headerList.map((d, idx) => {
            if (i === idx) {
                d.check = !d.check;
                arr[idx] = d;
            } else arr[idx] = d;
        });
        setHeaderList(arr);

        // TODO: fileList check function
    };

    const handleCheck = (e, i, type) => {
        console.log(i);
        const arr = [];
        fileList.map((d, idx) => {
            if (i === idx) {
                d[type] = !d[type];
                arr[idx] = d;
            } else arr[idx] = d;
        });
        setFileList(arr);
    };

    return (
        <ViewerLayout>
            <MenuWrapper>
                <PatientInfo />
                {treatmentProcess === 'Preparation' ? (
                    <PreparationMenu />
                ) : treatmentProcess === 'Segmentation' ? (
                    <SegmentationMenu />
                ) : treatmentProcess === 'Analysis' ? (
                    <AnalysisMenu />
                ) : (
                    <TreatmentMenu
                        handleViewer={setViewer}
                        onSave={onSave}
                        currentViewer={viewer}
                    />
                )}
            </MenuWrapper>
            <ViewerCenterContainer>
                {isSave && (
                    <Modal
                        title='Success'
                        primaryText='Confirm'
                        onClick={() => setIsSave(false)}
                    >
                        <ModalText>저장이 성공적으로 완료되었습니다.</ModalText>
                    </Modal>
                )}
                {exportState && (
                    <Modal
                        title='Export'
                        primaryText='Yes'
                        secondaryText='Cancel'
                        onClick={handleModal}
                        onClose={handleModal}
                        iconType='close'
                        type='export'
                    >
                        <ExportBoard
                            headerList={headerList}
                            fileData={fileList}
                            onCheckAll={handleAllCheck}
                            onCheck={handleCheck}
                        />
                    </Modal>
                )}

                <ProcessBar>
                    {processBarName.map((el, idx) => (
                        <DetailProcessBar
                            onClick={() => handleTreatmentProcess(el)}
                            key={idx}
                            active={treatmentProcess === el}
                        >
                            {/* //TODO: 데이터 받는 것에 따라 분기를 어떻게 칠 지 생각해봐야 할 듯 */}
                            <ProcessTextBox>
                                <OnTheProcess />
                                {el}
                            </ProcessTextBox>
                            <ProcessTextBox
                                marginLeft='24px'
                                color={palette.gray[6]}
                            >
                                On the process
                            </ProcessTextBox>
                        </DetailProcessBar>
                    ))}
                </ProcessBar>
                <MainViewerContainer id='mainViewerContainer'>
                    {!viewer ? (
                        <MainViewer>abc</MainViewer>
                    ) : (
                        <>
                            <MainViewer>abc</MainViewer>
                            <MainViewer>abc</MainViewer>
                        </>
                    )}
                </MainViewerContainer>
            </ViewerCenterContainer>
            <MenuWrapper>
                <DetailModal
                    id='rightSlide'
                    marginTop='84px'
                    height='234px'
                    backgroundColor={palette.primary.gray}
                >
                    {teethViewerItems.map((item, idx) => (
                        <SliderContainer key={idx} type='hide'>
                            {item.component}
                            <Slider
                                type='hide'
                                handleShow={setShow}
                                show={show}
                                idx={idx}
                            />
                            {show[idx] === 1 ? (
                                <Hide width='20px' height='20px' />
                            ) : (
                                <Show />
                            )}
                        </SliderContainer>
                    ))}
                </DetailModal>
            </MenuWrapper>
        </ViewerLayout>
    );
};

export default ViewerComponents;
