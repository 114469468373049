import Modal from 'components/common/Modal';
import React from 'react';

const MarkerModal = ({ handleModal }) => {
    return (
        <Modal
            title='Marker'
            type='marker'
            iconType='close'
            primaryText='Delete'
            secondaryText='Delete All'
            onClose={() => handleModal('')}
        >
            <div style={{ padding: '12px' }}>
                Set a point on the insial margin of the tooth that you want to
                measure the moving distance.
            </div>
        </Modal>
    );
};

export default MarkerModal;
