import React from 'react';
import styled from 'styled-components';

const MainViewerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex: 1;
`;

const MainViewer = (props) => {
    return <MainViewerWrapper {...props}>{props.children}</MainViewerWrapper>;
};

export default MainViewer;
