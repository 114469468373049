import React from 'react';

const Tooth46 = ({ color }) => (
    <svg
        style={{
            position: 'absolute',
            bottom: '69px',
            left: '11px',
        }}
        width='40'
        height='18'
        viewBox='0 0 40 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M32.2589 16.8067C30.7385 16.8067 28.8935 16.4824 26.7725 15.8448C22.9146 14.6831 21.2968 12.1218 20.6259 10.1819C19.6682 7.42068 20.1768 4.81075 21.0588 3.55172C21.0588 3.55172 21.0696 3.54091 21.0696 3.53551C21.1724 3.38961 21.286 3.24372 21.3996 3.09782C21.4321 3.0546 21.4646 3.01677 21.497 2.97894C21.5944 2.86007 21.6918 2.7412 21.7892 2.63313L21.8596 2.55747C21.9732 2.43319 22.0922 2.31431 22.2113 2.20083L22.3303 2.09277C22.4169 2.01172 22.5034 1.93607 22.5954 1.86042L22.7145 1.76314C22.8335 1.67128 22.9525 1.57942 23.077 1.49297L23.2176 1.40111C23.3042 1.34167 23.3908 1.28763 23.4774 1.239C23.5477 1.19577 23.5964 1.16875 23.6451 1.14174C23.7587 1.0823 23.8723 1.01746 23.9914 0.963427C24.0401 0.941813 24.0942 0.914792 24.1429 0.893178L24.1916 0.871559C24.2727 0.833734 24.3593 0.795903 24.4459 0.763481C24.5162 0.736463 24.5866 0.709449 24.6569 0.682431C24.7435 0.65001 24.83 0.623001 24.922 0.59058C25.0356 0.552755 25.1493 0.520332 25.2575 0.487911C25.3495 0.460893 25.4414 0.439271 25.5334 0.412253C25.6146 0.390639 25.7012 0.374436 25.7877 0.352822C25.8743 0.336611 25.9555 0.320402 26.042 0.304192C26.7995 0.163699 27.6382 0.0934448 28.5959 0.0934448C28.9476 0.0934448 29.3209 0.104262 29.7105 0.120473C33.1571 0.287983 34.7262 0.369024 37.3396 4.21096C39.8664 7.92861 39.2441 12.8026 37.7291 14.3967C37.3504 14.7965 36.8959 15.2558 36.3548 15.6449C36.2466 15.7205 36.1384 15.7962 36.0194 15.8664C35.9761 15.8935 35.9328 15.9205 35.8841 15.9475C35.6839 16.0664 35.4837 16.169 35.2727 16.2609L35.132 16.3203C34.8994 16.4122 34.6775 16.4878 34.4503 16.5527C34.4503 16.5527 34.4394 16.5527 34.434 16.5527C34.2338 16.6067 34.0282 16.65 33.8172 16.6878L33.7198 16.704C33.2816 16.7742 32.8054 16.8121 32.313 16.8121H32.2752L32.2589 16.8067Z'
            fill={color || 'white'}
        />
        <path
            // opacity='0.8'
            d='M2.93555 17.8945V16.7985H0.239547V16.2065L2.57555 12.3105H3.57555V16.2385H4.38355V16.7985H3.57555V17.8945H2.93555ZM0.871547 16.2385H2.93555V12.8305H2.89555L0.871547 16.2385ZM7.25658 17.9905C6.96858 17.9905 6.70724 17.9425 6.47258 17.8465C6.24324 17.7452 6.04591 17.6039 5.88058 17.4225C5.72058 17.2359 5.59791 17.0092 5.51258 16.7425C5.42724 16.4759 5.38458 16.1772 5.38458 15.8465C5.38458 15.4305 5.44591 15.0412 5.56858 14.6785C5.69124 14.3105 5.84858 13.9772 6.04058 13.6785C6.23791 13.3745 6.45391 13.1079 6.68858 12.8785C6.92324 12.6439 7.15258 12.4545 7.37658 12.3105H8.24058C7.94191 12.5292 7.67524 12.7425 7.44058 12.9505C7.20591 13.1585 7.00058 13.3772 6.82458 13.6065C6.64858 13.8305 6.49924 14.0732 6.37658 14.3345C6.25391 14.5905 6.15524 14.8812 6.08058 15.2065L6.12058 15.2225C6.25391 14.9932 6.42458 14.8039 6.63258 14.6545C6.84591 14.4999 7.12324 14.4225 7.46458 14.4225C7.70458 14.4225 7.92591 14.4625 8.12858 14.5425C8.33124 14.6225 8.50458 14.7372 8.64858 14.8865C8.79258 15.0359 8.90458 15.2172 8.98458 15.4305C9.06991 15.6439 9.11258 15.8812 9.11258 16.1425C9.11258 16.4145 9.06724 16.6652 8.97658 16.8945C8.89124 17.1185 8.76591 17.3132 8.60058 17.4785C8.44058 17.6385 8.24591 17.7639 8.01658 17.8545C7.78724 17.9452 7.53391 17.9905 7.25658 17.9905ZM7.24858 17.4065C7.61658 17.4065 7.90191 17.3052 8.10458 17.1025C8.31258 16.8999 8.41658 16.6092 8.41658 16.2305V16.1665C8.41658 15.7879 8.31258 15.4972 8.10458 15.2945C7.90191 15.0919 7.61658 14.9905 7.24858 14.9905C6.88058 14.9905 6.59258 15.0919 6.38458 15.2945C6.18191 15.4972 6.08058 15.7879 6.08058 16.1665V16.2305C6.08058 16.6092 6.18191 16.8999 6.38458 17.1025C6.59258 17.3052 6.88058 17.4065 7.24858 17.4065Z'
            fill={color || '#F4F4F4'}
        />
    </svg>
);

export default Tooth46;
