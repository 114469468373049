import DropClose from 'components/icons/DropClose';
import DropDown from 'components/icons/DropDown';
import DetailModal from 'components/ui/DetailModal';
import { InnerContainers } from 'components/ui/containers';
import { palette } from 'modules/defines/styles';
import React, { useState } from 'react';

const PatientInfo = ({ type }) => {
    const [handleDropDown, setHandleDropDown] = useState(false);
    const test = ['test1', 'test2', 'test3', 'test4', 'test5'];
    const mobileTest = ['test1', 'test2', 'test3', 'test4'];

    return (
        <>
            {!handleDropDown && !type ? (
                <DetailModal
                    height='44px'
                    backgroundColor={palette.gray[4]}
                    flexDirection='row'
                    justifyContent='space-between'
                    alignItems='center'
                    // style={{ position: position && 'absolute' }}
                >
                    Patient Info
                    <button
                        style={{ padding: '0' }}
                        onClick={() => setHandleDropDown(!handleDropDown)}
                    >
                        <DropDown color={palette.primary.blue} />
                    </button>
                </DetailModal>
            ) : handleDropDown && !type ? (
                <>
                    <DetailModal
                        height='44px'
                        backgroundColor={palette.gray[4]}
                        flexDirection='row'
                        justifyContent='space-between'
                        alignItems='center'
                        style={{ marginBottom: '0' }}
                    >
                        Patient Info
                    </DetailModal>
                    <InnerContainers
                        height='112px'
                        backgroundColor={palette.gray[4]}
                        alignItems='center'
                        justifyContent='space-between'
                        style={{
                            padding: '0 12px 12px',
                            marginBottom: '10px',
                            color: palette.gray[6],
                            position: 'relative',
                        }}
                    >
                        {test.map((el, idx) => (
                            <DetailModal key={idx} height='20px' padding='0'>
                                {el}
                            </DetailModal>
                        ))}
                        <button
                            style={{
                                position: 'absolute',
                                left: '186px',
                                top: '81px',
                                padding: '0',
                            }}
                            onClick={() => setHandleDropDown(!handleDropDown)}
                        >
                            <DropClose />
                        </button>
                    </InnerContainers>
                </>
            ) : (
                <>
                    <DetailModal
                        height='31px'
                        backgroundColor={palette.gray[4]}
                        flexDirection='row'
                        justifyContent='flex-start'
                        alignItems='center'
                        padding='0 0 10px 0'
                    >
                        Patient Info
                    </DetailModal>
                    <InnerContainers
                        height='72px'
                        backgroundColor={palette.gray[4]}
                        // alignItems='center'
                        style={{
                            padding: '0',
                            fontSize: '10px',
                            marginBottom: '0',
                            color: palette.gray[6],
                        }}
                    >
                        {mobileTest.map((el, idx) => (
                            <DetailModal key={idx} height='20px' padding='0'>
                                {el}
                            </DetailModal>
                        ))}
                    </InnerContainers>
                </>
            )}
        </>
    );
};

export default PatientInfo;
