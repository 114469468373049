import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { palette, size } from 'modules/defines/styles';
import BoardBody from 'components/board/BoardBody';
import BoardFooter from 'components/board/BoardFooter';
import BoardHeader from 'components/board/BoardHeader';
import { RectangularButton } from 'components/ui/webButtons';
import Search from 'components/icons/Search';
import Close from 'components/icons/Close';
import { InnerButtonContainer } from 'components/ui/containers';
import Plus from 'components/icons/Plus';

const PatientSearchContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 7%;
    background-color: ${palette.primary.gray};
    color: white;
`;

const PatientSearchInner = styled.div`
    display: flex;
    flex-direction: start;
    width: 36%;
    border-bottom: 1px solid ${palette.gray[1]};
    outline: ${(props) => props.settingDisabled && '2px solid white'};
    outline-offset: -2px;
`;

const SearchIconBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: ${(props) => props.width && props.width};
    aspect-ratio: 1;
    &#deleteSearchInput > div {
        cursor: pointer;
    }

    button {
        cursor: pointer;
        width: 40%;
        height: 40%;
        background-color: transparent;
    }
`;

const SearchInput = styled.input`
    display: flex;
    justify-content: center;
    width: 56%;
    height: 100%;
    font-size: 14px;
    border: none;
    outline: none;
    background: transparent;
    color: white;
    ::placeholder {
        color: ${palette.disabled.textColor};
    }
`;

const PatientListAndFileContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 83%;
`;

const PatientListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const PatientFileContainer = styled.div`
    display: flex;
    width: 37%;
    height: 100%;
    background-color: pink;
`;

const boardHeaderList = [
    { width: '10%', label: 'PatientID' },
    { width: '6%', label: 'M/F' },
    { width: '11%', label: 'Birth Date' },
    { width: '14%', label: 'Name' },
    { width: '22%', label: 'File name' },
    { width: '37%', label: 'Preview' },
];

const patientList = [
    {
        patientID: '1',
        gender: 'F',
        birthDate: '123',
        name: 'aewfawef1',
        Edited: 'awefawefawef',
        Uploaded: 'awefawefawef',
    },
    {
        patientID: '2',
        gender: 'F',
        birthDate: '123',
        name: 'aewfawef2',
        Edited: 'awefawefawef',
        Uploaded: 'awefawefawef',
    },
    {
        patientID: '3',
        gender: 'F',
        birthDate: '123',
        name: 'aewfawef3',
        Edited: 'awefawefawef',
        Uploaded: 'awefawefawef',
    },
    {
        patientID: '4',
        gender: 'F',
        birthDate: '123',
        name: 'aewfawef4',
        Edited: 'awefawefawef',
        Uploaded: 'awefawefawef',
    },
    {
        patientID: '4',
        gender: 'F',
        birthDate: '123',
        name: 'aewfawef4',
        Edited: 'awefawefawef',
        Uploaded: 'awefawefawef',
    },
    {
        patientID: '4',
        gender: 'F',
        birthDate: '123',
        name: 'aewfawef4',
        Edited: 'awefawefawef',
        Uploaded: 'awefawefawef',
    },
    {
        patientID: '4',
        gender: 'F',
        birthDate: '123',
        name: 'aewfawef4',
        Edited: 'awefawefawef',
        Uploaded: 'awefawefawef',
    },
    {
        patientID: '4',
        gender: 'F',
        birthDate: '123',
        name: 'aewfawef4',
        Edited: 'awefawefawef',
        Uploaded: 'awefawefawef',
    },
    {
        patientID: '4',
        gender: 'F',
        birthDate: '123',
        name: 'aewfawef4',
        Edited: 'awefawefawef',
        Uploaded: 'awefawefawef',
    },
    {
        patientID: '4',
        gender: 'F',
        birthDate: '123',
        name: 'aewfawef4',
        Edited: 'awefawefawef',
        Uploaded: 'awefawefawef',
    },
    {
        patientID: '4',
        gender: 'F',
        birthDate: '123',
        name: 'aewfawef4',
        Edited: 'awefawefawef',
        Uploaded: 'awefawefawef',
    },
    {
        patientID: '4',
        gender: 'F',
        birthDate: '123',
        name: 'aewfawef4',
        Edited: 'awefawefawef',
        Uploaded: 'awefawefawef',
    },
    {
        patientID: '4',
        gender: 'F',
        birthDate: '123',
        name: 'aewfawef4',
        Edited: 'awefawefawef',
        Uploaded: 'awefawefawef',
    },
    {
        patientID: '4',
        gender: 'F',
        birthDate: '123',
        name: 'aewfawef4',
        Edited: 'awefawefawef',
        Uploaded: 'awefawefawef',
    },
    {
        patientID: '4',
        gender: 'F',
        birthDate: '123',
        name: 'aewfawef4',
        Edited: 'awefawefawef',
        Uploaded: 'awefawefawef',
    },
];
const itemsPerPage = 10;
const PatientListComponent = ({
    settingDisabled,
    setSearchInput,
    searchInput,
    setResultInput,
    onModal,
    onShare,
}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(patientList.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, patientList.length);

    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    const test = [];
    for (let i = startIndex; i < endIndex; i++) {
        test.push(patientList[i]);
    }

    const handlePageClick = (e) => {
        if (e === -1) {
            if (currentPage > 1) {
                setCurrentPage(currentPage - 1);
            }
        } else if (e === 1) {
            if (currentPage < totalPages) {
                setCurrentPage(currentPage + 1);
            }
        }
    };

    return (
        <>
            <PatientSearchContainer>
                <PatientSearchInner
                    settingDisabled={settingDisabled && searchInput}
                >
                    <SearchIconBox width='85px'>
                        <Search
                            width='35%'
                            height='35%'
                            opacity={searchInput && '1'}
                        />
                    </SearchIconBox>
                    <SearchInput
                        type='text'
                        placeholder='Typing'
                        onChange={(e) => setSearchInput(e.target.value)}
                        value={searchInput}
                    />
                    <SearchIconBox id='deleteSearchInput' width='85px'>
                        <button onClick={() => setSearchInput('')}>
                            <Close
                                width='80%'
                                height='80%'
                                opacity={searchInput && '1'}
                            />
                        </button>
                    </SearchIconBox>
                    <InnerButtonContainer
                        width='20%'
                        height='100%'
                        alignItems='center'
                        marginTop='0'
                        style={{ paddingRight: '16px' }}
                    >
                        <RectangularButton
                            backgroundColor={palette.primary.blue}
                            height='50%'
                            disabled={searchInput === '' || !settingDisabled}
                            onClick={() => setResultInput(searchInput)}
                        >
                            Search
                        </RectangularButton>
                    </InnerButtonContainer>
                </PatientSearchInner>
                <RectangularButton
                    height='100%'
                    width='12%'
                    backgroundColor={palette.primary.blue}
                    alignItems='center'
                    onClick={onModal}
                >
                    <Plus />{' '}
                    <span style={{ marginLeft: '4px' }}>Add Patient</span>
                </RectangularButton>
            </PatientSearchContainer>
            <PatientListAndFileContainer>
                <PatientListContainer>
                    <BoardHeader
                        boardHeaderList={boardHeaderList}
                    ></BoardHeader>
                    <BoardBody
                        onShare={onShare}
                        onModal={onModal}
                        patientList={test}
                    ></BoardBody>
                    <BoardFooter
                        patientList={patientList}
                        handlePageClick={handlePageClick}
                        currentPage={currentPage}
                        totalPages={totalPages}
                    ></BoardFooter>
                </PatientListContainer>
                {/* <PatientFileContainer>
                <BoardHeader listTitleTest={fileTitleTest}></BoardHeader>
                </PatientFileContainer>  */}
            </PatientListAndFileContainer>
        </>
    );
};

export default PatientListComponent;
