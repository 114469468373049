import React from 'react';

const Information = () => (
    <svg
        width='16'
        height='17'
        viewBox='0 0 16 20'
        fill='none'
        // xmlns='http://www.w3.org/2000/svg'
    >
        <rect
            width='16'
            height='16'
            transform='translate(0 0.5)'
            // fill='white'
            // style='mix-blend-mode:multiply'
        />
        <rect x='4' y='3.5' width='8' height='10' fill='white' />
        <rect
            width='16'
            height='16'
            transform='translate(0 0.5)'
            // fill='white'
            // style='mix-blend-mode:multiply'
        />
        <path
            d='M8 1.5C6.61553 1.5 5.26216 1.91054 4.11101 2.67971C2.95987 3.44888 2.06266 4.54213 1.53285 5.82122C1.00303 7.1003 0.86441 8.50776 1.13451 9.86563C1.4046 11.2235 2.07129 12.4708 3.05026 13.4497C4.02922 14.4287 5.2765 15.0954 6.63437 15.3655C7.99224 15.6356 9.3997 15.497 10.6788 14.9672C11.9579 14.4373 13.0511 13.5401 13.8203 12.389C14.5895 11.2378 15 9.88447 15 8.5C15 6.64348 14.2625 4.86301 12.9497 3.55025C11.637 2.2375 9.85652 1.5 8 1.5ZM8 4.5C8.14834 4.5 8.29334 4.54399 8.41668 4.6264C8.54002 4.70881 8.63615 4.82594 8.69291 4.96299C8.74968 5.10003 8.76453 5.25083 8.73559 5.39632C8.70665 5.5418 8.63522 5.67544 8.53033 5.78033C8.42544 5.88522 8.29181 5.95665 8.14632 5.98559C8.00083 6.01453 7.85004 5.99968 7.71299 5.94291C7.57595 5.88614 7.45881 5.79001 7.3764 5.66668C7.29399 5.54334 7.25 5.39834 7.25 5.25C7.25 5.05109 7.32902 4.86032 7.46967 4.71967C7.61033 4.57902 7.80109 4.5 8 4.5ZM10 12.5625H6V11.4375H7.4375V8.5625H6.5V7.4375H8.5625V11.4375H10V12.5625Z'
            fill='#4589FF'
        />
    </svg>
);

export default Information;
