import React from 'react';
import styled from 'styled-components';
import { fonts, palette } from 'modules/defines/styles';

const deviation = [
    'Mesial/Distal',
    'Lingual/Buccai',
    'Int/Ext',
    'Rotation',
    'Torque',
    'Tip',
];

const SecondColumnData = [
    '-0.100',
    '-0.100',
    '-0.100',
    '-0.100',
    '-0.100',
    '-0.100',
];
const ThirdColumnData = [
    'data7',
    'data8',
    'data9',
    'data10',
    'data11',
    'data12',
];

const BoardWrap = styled.div`
    display: flex;
    flex-direction: column;
`;

const Headerwrap = styled.div`
    display: flex;
    background-color: ${palette.gray[5]};
    height: 32px;

    div {
        flex: 1;
        text-align: center;
        padding: 8px;
        font-size: 13px;
        line-height: 18px;
        font-weight: 600;
        color: ${palette.gray[0]};
    }
`;
const BodyWrap = styled.div`
    display: flex;
    flex-direction: column;
`;

const Row = styled.div`
    display: flex;
    background-color: ${palette.gray[4]};

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;
        flex: 1;
        padding: 8px;
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
    }
`;
const StepBoard = ({ headerList }) => {
    return (
        <BoardWrap>
            <Headerwrap>
                {headerList.map((header, index) => (
                    <div key={index}>{header}</div>
                ))}
            </Headerwrap>
            <BodyWrap>
                {deviation.map((column, index) => (
                    <Row key={index}>
                        <div>{column}</div>
                        <div>{SecondColumnData[index]}</div>
                        <div>{ThirdColumnData[index]}</div>
                    </Row>
                ))}
            </BodyWrap>
        </BoardWrap>
    );
};

export default StepBoard;
