import React, { useEffect } from 'react';
import ViewerComponents from 'components/viewer/ViewerComponents';
import { useDataExportStoreMethods } from 'modules/stores/dataExport';
import { useState } from 'react';

// 데이터를 받아오는 곳.
const ViewerContainer = () => {
    const { setPathname } = useDataExportStoreMethods();
    const [isSave, setIsSave] = useState(false);
    // setIsSave(true)는 데이터 저장이 완료된 다음
    // SetIsSave(false)는 모달창의 confirm을 눌렀을 때.

    useEffect(() => {
        setPathname(window.location.pathname);
    }, []);

    const handleSave = () => {
        console.log('savesavesave');
        setIsSave(true);
    };

    return (
        <ViewerComponents
            onSave={handleSave}
            isSave={isSave}
            setIsSave={setIsSave}
        />
    );
};

export default ViewerContainer;
