import React from 'react';
import styled from 'styled-components/macro';
import { palette, size } from 'modules/defines/styles';
import { Outlet } from 'react-router-dom';

const PatientWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 73%;
    height: 86%;
    min-width: ${size.minWidth}px;
    min-height: ${size.minHeight}px;
    margin-top: 30px;
    color: white;
`;

const PatientListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const PatientLayout = ({}) => {
    return (
        <PatientWrapper className='PatientWrapper'>
            <PatientListWrapper>
                {/* <PatientTitle>Patient table</PatientTitle> */}
                <Outlet />
            </PatientListWrapper>
        </PatientWrapper>
    );
};

export default PatientLayout;
