import React from 'react';

const Tooth41 = ({ color }) => (
    <svg
        style={{
            position: 'absolute',
            bottom: '0',
            left: '83px',
        }}
        width='10'
        height='36'
        viewBox='0 0 10 36'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M6.31929 10.2726C5.90808 10.2726 5.43193 10.2294 4.90168 10.1375C3.44079 9.88894 2.41817 9.34317 1.87169 8.51102C1.29816 7.64105 1.20617 6.40904 1.59574 4.84741C2.72117 0.373263 3.77626 0.0382405 5.25338 0.0328369C6.94693 0.0328369 8.68377 0.729894 8.77034 5.9065C8.80821 7.79235 8.70541 9.08921 8.07777 9.72683C7.72066 10.0889 7.14713 10.2672 6.31929 10.2672V10.2726Z'
            fill={color || 'white'}
        />
        <path
            // opacity='0.8'
            d='M3.62402 35.1973V34.1013H0.928023V33.5093L3.26402 29.6133H4.26402V33.5413H5.07202V34.1013H4.26402V35.1973H3.62402ZM1.56002 33.5413H3.62402V30.1333H3.58402L1.56002 33.5413ZM6.24105 35.1973V34.6053H7.80105V30.1093H7.74505L6.33705 31.4213L5.94505 30.9973L7.42505 29.6133H8.47305V34.6053H9.91305V35.1973H6.24105Z'
            fill={color || '#F4F4F4'}
        />
    </svg>
);

export default Tooth41;
