import { palette } from 'modules/defines/styles';
import React from 'react';
import styled from 'styled-components';

const InnerModalContainer = styled.div`
    width: ${(props) => (props.width ? props.width : '100%')};
    height: ${(props) => (props.height ? props.height : '63px')};
    display: flex;
    overflow: auto;
    word-wrap: break-word;
    flex-direction: column;
    /* align-items: center; */
    justify-content: ${(props) =>
        props.justifyContent ? props.justifyContent : 'space-between'};
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : 'inherit'};
    font-size: ${(props) => (props.fontSize ? props.fontSize : '10px')};
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '')};
    color: ${(props) => (props.color ? props.color : palette.white)};
    padding: ${(props) => (props.padding ? props.padding : '')};

    ${(props) =>
        props.type === 'treatmentList' &&
        `
    ::-webkit-scrollbar-track {
        background-color: inherit;
        border-radius: 10px;
    }

    /* thumb 스타일 */
    ::-webkit-scrollbar-thumb {
        background-color: ${palette.gray[7]};
        border-radius: 10px;
        height: 50px;
    }

    /* track 크기 동적 지정 */
    ::-webkit-scrollbar {
        width: 8px;
        height: 100%;
    }
    `}
`;

export const InnerContainers = (props) => {
    return (
        <InnerModalContainer {...props}>{props.children}</InnerModalContainer>
    );
};

const InnerModalButtonContainer = styled.div`
    display: flex;
    width: ${(props) => (props.width ? props.width : '100%')};
    align-items: ${(props) => (props.alignItems ? props.alignItems : '')};
    justify-content: ${(props) =>
        props.justifyContent ? props.justifyContent : 'start'};
    height: ${(props) => (props.height ? props.height : '32px')};
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '12px')};
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '')};
    padding: ${(props) => (props.padding ? props.padding : '')};
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : ''};
    button + button {
        margin-left: ${(props) =>
            props.type === 'menuButton' ? '6px' : '3px'};
    }
`;

export const InnerButtonContainer = (props) => {
    return (
        <InnerModalButtonContainer {...props}>
            {props.children}
        </InnerModalButtonContainer>
    );
};
