import React from 'react';

const UpperTeethWithGum = ({ width, height }) => (
    <svg
        width={width || '16'}
        height={height || '16'}
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M11.9929 7.59874C12.316 7.59605 12.6196 7.57987 12.8235 7.5664C13.1382 7.54618 13.4391 7.72679 13.5792 8.04083C13.6915 8.29422 13.776 8.67566 13.7622 9.24714C13.7622 9.32666 13.7511 11.0573 12.7375 11.9751C12.4519 12.2339 12.0844 12.3552 11.7156 12.3552L11.6768 12.3552C11.3079 12.3552 10.9405 12.2339 10.6548 11.9751C9.64125 11.0573 9.63016 9.32531 9.63016 9.24714C9.61491 8.72822 9.67314 8.36701 9.75911 8.11631C9.8839 7.75779 10.2125 7.54079 10.5564 7.55966C10.788 7.57313 11.136 7.59066 11.3994 7.5974L11.9929 7.5974L11.9929 7.59874Z'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M7.77024 7.59874C8.09332 7.59605 8.39698 7.57987 8.60081 7.5664C8.91556 7.54618 9.21645 7.72679 9.3565 8.04083C9.46881 8.29422 9.55339 8.67566 9.53953 9.24714C9.53953 9.32666 9.52843 11.0573 8.51484 11.9751C8.2292 12.2339 7.86176 12.3552 7.49293 12.3552L7.4541 12.3552C7.08527 12.3552 6.71783 12.2339 6.43219 11.9751C5.4186 11.0573 5.4075 9.32531 5.4075 9.24714C5.39225 8.72822 5.45049 8.36701 5.53646 8.11631C5.66125 7.75779 5.98987 7.54079 6.33374 7.55966C6.5653 7.57313 6.91333 7.59066 7.17678 7.5974L7.77024 7.5974L7.77024 7.59874Z'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M3.64817 7.59874C3.97125 7.59605 4.27491 7.57987 4.47874 7.5664C4.79349 7.54618 5.09438 7.72679 5.23443 8.04083C5.34674 8.29422 5.43132 8.67566 5.41746 9.24714C5.41746 9.32666 5.40636 11.0573 4.39277 11.9751C4.10713 12.2339 3.73969 12.3552 3.37086 12.3552L3.33203 12.3552C2.9632 12.3552 2.59576 12.2339 2.31012 11.9751C1.29653 11.0573 1.28543 9.32531 1.28543 9.24714C1.27018 8.72822 1.32842 8.36701 1.41438 8.11631C1.53918 7.75779 1.8678 7.54079 2.21167 7.55966C2.44323 7.57313 2.79126 7.59066 3.05471 7.5974L3.64817 7.5974L3.64817 7.59874Z'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M13.7634 9.64601C14.2626 9.64601 14.6383 10.0881 14.544 10.5625L14.2459 12.091C13.9672 13.5224 12.6791 14.5588 11.1802 14.5588L3.93805 14.5588C2.43915 14.5588 1.15102 13.5224 0.870927 12.0923L0.572812 10.5639C0.482684 10.1029 0.832104 9.67296 1.30909 9.6487'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M1.3538 5.47167C0.854626 5.47167 0.478862 5.02958 0.573149 4.55514L0.871265 3.02671C1.14997 1.59532 2.43811 0.558838 3.937 0.558838H11.1791C12.678 0.558838 13.9662 1.59532 14.2463 3.02536L14.5444 4.5538C14.6345 5.01475 14.2851 5.44471 13.8081 5.46897'
            stroke='#0F62FE'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M3.12429 7.51893C2.80121 7.52163 2.49755 7.5378 2.29372 7.55128C1.97897 7.5715 1.67808 7.39089 1.53804 7.07684C1.42572 6.82345 1.34114 6.44202 1.35501 5.87054C1.35501 5.79102 1.3661 4.06041 2.37969 3.14254C2.66533 2.88376 3.03277 2.76245 3.40161 2.76245H3.44043C3.80926 2.76245 4.17671 2.88376 4.46234 3.14254C5.47594 4.06041 5.48703 5.79237 5.48703 5.87054C5.50228 6.38945 5.44404 6.75067 5.35808 7.00137C5.23328 7.35989 4.90466 7.57689 4.56079 7.55802C4.32923 7.54454 3.9812 7.52702 3.71775 7.52028H3.12429V7.51893Z'
            stroke='#0F62FE'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M7.34694 7.51893C7.02387 7.52163 6.72021 7.5378 6.51638 7.55128C6.20163 7.5715 5.90074 7.39089 5.76069 7.07684C5.64838 6.82345 5.5638 6.44202 5.57766 5.87054C5.57766 5.79102 5.58875 4.06041 6.60235 3.14254C6.88798 2.88376 7.25543 2.76245 7.62426 2.76245H7.66309C8.03192 2.76245 8.39936 2.88376 8.685 3.14254C9.69859 4.06041 9.70969 5.79237 9.70969 5.87054C9.72494 6.38945 9.6667 6.75067 9.58073 7.00137C9.45594 7.35989 9.12732 7.57689 8.78345 7.55802C8.55189 7.54454 8.20385 7.52702 7.9404 7.52028H7.34694V7.51893Z'
            stroke='#0F62FE'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M11.469 7.51893C11.1459 7.52163 10.8423 7.5378 10.6384 7.55128C10.3237 7.5715 10.0228 7.39089 9.88276 7.07684C9.77045 6.82345 9.68587 6.44202 9.69973 5.87054C9.69973 5.79102 9.71083 4.06041 10.7244 3.14254C11.0101 2.88376 11.3775 2.76245 11.7463 2.76245H11.7852C12.154 2.76245 12.5214 2.88376 12.8071 3.14254C13.8207 4.06041 13.8318 5.79237 13.8318 5.87054C13.847 6.38945 13.7888 6.75067 13.7028 7.00137C13.578 7.35989 13.2494 7.57689 12.9055 7.55802C12.674 7.54454 12.3259 7.52702 12.0625 7.52028H11.469V7.51893Z'
            stroke='#0F62FE'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export default UpperTeethWithGum;
