import React from 'react';

const StatusIcon = () => (
    <svg
        width='14'
        height='14'
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M7.001 2.08571H6.999L1.32435 12.9983L1.3253 13H12.6747L12.6757 12.9983L7.001 2.08571ZM6.4375 5.00001H7.5625V9.50001H6.4375V5.00001ZM7 12C6.85167 12 6.70666 11.956 6.58333 11.8736C6.45999 11.7912 6.36386 11.6741 6.30709 11.537C6.25033 11.4 6.23548 11.2492 6.26441 11.1037C6.29335 10.9582 6.36478 10.8246 6.46967 10.7197C6.57456 10.6148 6.7082 10.5434 6.85369 10.5144C6.99917 10.4855 7.14997 10.5003 7.28702 10.5571C7.42406 10.6139 7.54119 10.71 7.62361 10.8333C7.70602 10.9567 7.75 11.1017 7.75 11.25C7.75 11.4489 7.67099 11.6397 7.53033 11.7803C7.38968 11.921 7.19892 12 7 12Z'
            fill='#F1C21B'
        />
        <path
            d='M13.5 14H0.500004C0.414101 14 0.329646 13.9779 0.254784 13.9357C0.179921 13.8936 0.117175 13.8329 0.0725961 13.7595C0.0280171 13.686 0.00310877 13.6024 0.000272631 13.5165C-0.00256351 13.4307 0.0167682 13.3455 0.0564039 13.2693L6.5564 0.769307C6.59862 0.688124 6.66231 0.620084 6.74053 0.572597C6.81875 0.525111 6.9085 0.5 7 0.5C7.09151 0.5 7.18126 0.525111 7.25948 0.572597C7.3377 0.620084 7.40138 0.688124 7.4436 0.769307L13.9436 13.2693C13.9832 13.3455 14.0026 13.4307 13.9997 13.5165C13.9969 13.6024 13.972 13.686 13.9274 13.7595C13.8828 13.8329 13.8201 13.8936 13.7452 13.9357C13.6704 13.9779 13.5859 14 13.5 14ZM1.3253 13H12.6747L12.6757 12.9983L7.001 2.08571H6.999L1.32435 12.9983L1.3253 13Z'
            fill='#F1C21B'
        />
    </svg>
);

export default StatusIcon;
