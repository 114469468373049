import React, { useEffect, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components/macro';

// refs:
// https://github.com/pmndrs/use-asset

import { ActionManager, MeshBuilder } from '@babylonjs/core';

import Workspace3D from 'containers/dev/Workspace3D';
import { ButtonSet, CheckButton } from 'components/dev/ui/buttons';
import { LabeledBox } from 'components/dev/ui/panels';

import { useMeshStoreAndMethods } from 'modules//stores/mesh-manager';
import {
    useSceneManager,
    useSceneManagerMethods,
    useSceneManagerAndMethods,
} from 'modules//stores/scene-manager';
import { useDisplayMethods } from 'modules//stores/display';
import { useMeshTrimStoreAndMethods } from 'modules/stores/mesh-trimming';
import {
    createSplineOnMesh,
    updateSplineOnMesh,
} from 'modules/libraries/curveOnMesh';

const MeshTrimControls = styled(LabeledBox)`
    padding: 20px;
    &:before {
        content: 'Mesh trimmer';
    }
`;

// a control panel specifically for the MeshTrimming test workspace,
//   gets loaded via display.setControlPanels() when a mesh is loaded
const MeshTrimControlPanel = ({}) => {
    const [testMode, { setTestMode }] = useMeshTrimStoreAndMethods(
        (state) => state.testMode
    );

    const enablePointClick = (state) => {
        setTestMode(state === 'checked' ? 'draw-spline' : 'none');
    };

    const enablePointsEdit = (state) => {
        setTestMode(state === 'checked' ? 'cp-edit' : 'none');
    };

    const enablePointsDraw = (state) => {
        setTestMode(state === 'checked' ? 'points-draw' : 'none');
    };

    return (
        <MeshTrimControls>
            <LabeledBox>
                <ButtonSet width={125}>
                    <CheckButton
                        initState={
                            testMode === 'draw-spline' ? 'checked' : 'unchecked'
                        }
                        onClick={enablePointClick}
                    >
                        Draw spline
                    </CheckButton>
                    <CheckButton
                        initState={
                            testMode === 'cp-edit' ? 'checked' : 'unchecked'
                        }
                        onClick={enablePointsEdit}
                    >
                        Edit control-points
                    </CheckButton>
                    <CheckButton
                        initState={
                            testMode === 'points-draw' ? 'checked' : 'unchecked'
                        }
                        onClick={enablePointsDraw}
                    >
                        Draw points
                    </CheckButton>
                </ButtonSet>
            </LabeledBox>
        </MeshTrimControls>
    );
};

const MeshTrimming = () => {
    const [loadedMesh, { downloadMesh }] = useMeshStoreAndMethods(
        (state) => state.loadedMesh
    );
    const [testMode, { setTestMode }] = useMeshTrimStoreAndMethods(
        (state) => state.testMode
    );
    const { setControlPanels } = useDisplayMethods();
    const {
        onMeshMouseDown,
        onMeshPick,
        onDrawOnMesh,
        onMeshHover,
        onDragOnMesh,
        clearHandlers,
        pauseHandlers,
        resumeHandlers,
        onClickOff,
        onKeyDown,
    } = useSceneManagerMethods();
    const materials = useSceneManager((state) => state.materials);
    const scene = useSceneManager((state) => state.scene);

    const curve = useRef(null);
    const cpSpheres = useRef([]);
    const cpPositions = useRef([]);
    const selectedCP = useRef(null);

    useLayoutEffect(() => {
        //for now, always load same test mesh on opening
        if (scene && !loadedMesh)
            downloadMesh(
                '/uploads/',
                'ex2_U_depressions_24c8cea21b.stl',
                materials.skinMaterial,
                scene
            );
    }, [scene]);

    useEffect(() => {
        if (loadedMesh) {
            // set my control panels when mesh loads
            setControlPanels([MeshTrimControlPanel]);
            // currently, it is app's job to condition pickability, not scene-manager event handler system
            loadedMesh.isPickable = true;
            loadedMesh.actionManager = new ActionManager(scene); // need both to make hoverable
        }
        // reset some state
        cpSpheres.current = [];
        cpPositions.current = [];
        curve.current = null;
        selectedCP.current = null;
        clearHandlers();
        setTestMode('none');
    }, [loadedMesh, scene]); // reset things if either loadedMesh or the 3D scene object refreshes

    // ------ mesh interaction tests -------
    useEffect(() => {
        if (loadedMesh) {
            if (testMode === 'draw-spline') {
                // track single pick points on the loaded mesh as placement cpPositions for curve control-points

                // register sphere array with highlighting hover handlers
                const omhHandlerID = onMeshHover(
                    cpSpheres.current,
                    ({ mesh }) => (mesh.material = materials.yellowMaterial), // enter
                    ({ mesh }) => (mesh.material = materials.redMaterial) // leave
                );
                const omcHandlerId = onMeshPick(
                    loadedMesh,
                    ({ pickInfo, scene }) => {
                        // add control-point
                        const pos = pickInfo.pickedPoint;
                        const sphere = MeshBuilder.CreateSphere(
                            `cp-${cpSpheres.current.length}`,
                            { segments: 16, diameter: 1 },
                            scene
                        );
                        sphere.position = pos;
                        sphere.material = materials.redMaterial;
                        sphere.isPickable = true;
                        sphere.actionManager = new ActionManager(scene); // need both to make hoverable
                        sphere.delayHover = true;
                        // save spheres & positions
                        cpSpheres.current.push(sphere);
                        cpPositions.current.push(pos);
                        // build spline curve on mesh
                        if (cpPositions.current.length === 2) {
                            curve.current = createSplineOnMesh(
                                loadedMesh,
                                cpPositions.current,
                                materials.blueMaterial,
                                0.1,
                                40,
                                scene
                            );
                        } else if (cpPositions.current.length > 2) {
                            updateSplineOnMesh(curve.current, false);
                        }
                    }
                );
                const ocpcHandlerID = onMeshPick(
                    cpSpheres.current,
                    ({ mesh }) => {
                        // mouse down on existing CP sphere
                        if (mesh === cpSpheres.current[0]) {
                            // click on first CP -> close curve, drop out of adding CP mode
                            updateSplineOnMesh(curve.current, true);
                            mesh.material = materials.redMaterial; // restore from any hover-state
                            setTestMode('cp-edit');
                        } // for now, can't pick or edit other CPs while drawing initial closed spline
                    }
                );

                return () => {
                    clearHandlers([omhHandlerID, omcHandlerId, ocpcHandlerID]);
                };
            }
            if (testMode === 'cp-edit') {
                // register sphere array with highlighting hover handlers
                const omhHandlerID = onMeshHover(
                    cpSpheres.current,
                    ({ mesh }) => {
                        console.log(
                            'hover enter',
                            mesh.id,
                            mesh !== selectedCP.current
                        );
                        if (mesh !== selectedCP.current)
                            mesh.material = materials.yellowMaterial;
                    }, // enter
                    ({ mesh }) => {
                        console.log(
                            'hover leave',
                            mesh.id,
                            mesh !== selectedCP
                        );
                        if (mesh !== selectedCP.current)
                            mesh.material = materials.redMaterial;
                    } // leave
                );

                // handle selecting a CP sphere
                const cppHandlerID = onMeshPick(
                    cpSpheres.current,
                    ({ mesh }) => {
                        console.log('selecting', mesh.id);
                        if (selectedCP.current)
                            selectedCP.current.material = materials.redMaterial;
                        selectedCP.current = mesh;
                        mesh.material = materials.greenMaterial;
                    }
                );

                // click-off deselects
                const cpcoHandlerID = onClickOff(cpSpheres.current, () => {
                    if (selectedCP.current)
                        selectedCP.current.material = materials.redMaterial;
                    selectedCP.current = null;
                });

                // keydown checking for selected CP deletes
                const kdHandlerID = onKeyDown(({ kbInfo }) => {
                    if (
                        (kbInfo.event.key === 'Delete' ||
                            kbInfo.event.key === 'Backspace') &&
                        selectedCP.current &&
                        cpSpheres.current.length > 3
                    ) {
                        // CP array surgery & curve rebuild
                        const cpIndex = cpSpheres.current.indexOf(
                            selectedCP.current
                        );
                        if (cpIndex >= 0) {
                            cpSpheres.current.splice(cpIndex, 1);
                            cpPositions.current.splice(cpIndex, 1);
                            updateSplineOnMesh(curve.current, true);
                            selectedCP.current.dispose();
                            selectedCP.currrent = null;
                        }
                    }
                });

                // setup dragging of any of the CP spheres on the loadedMesh surface
                const dcpHandlerID = onDragOnMesh(
                    cpSpheres.current,
                    loadedMesh,
                    0.75,
                    ({ mesh, index, pickInfo }) => {
                        console.log(
                            'dragging mesh',
                            mesh.id,
                            index,
                            'to',
                            pickInfo.pickedPoint
                        );
                        cpPositions.current[index] = pickInfo.pickedPoint;
                        updateSplineOnMesh(curve.current, true);
                        pauseHandlers([omhHandlerID, cppHandlerID]);
                    },
                    () => {
                        // drag-end
                        console.log('end drag on mesh');
                        resumeHandlers([omhHandlerID, cppHandlerID]);
                    }
                );

                return () => {
                    console.log('exit cp edit');
                    clearHandlers([
                        dcpHandlerID,
                        omhHandlerID,
                        cppHandlerID,
                        cpcoHandlerID,
                        kdHandlerID,
                    ]);
                };
                // // handle mousedown and drag on CP over the loaded-mesh
                // let omdHandlerID;
                // const ecpHandlerID = onMeshMouseDown(cpSpheres.current, ({ mesh, index }) => {
                //     console.log('onMeshMouseDown', mesh.id);
                //     omdHandlerID = onDragOnMesh(loadedMesh, 0.75, ({ pickInfo }) => {
                //         // console.log('dragging mesh', mesh.id, 'to', pickInfo.pickedPoint);
                //         cpPositions.current[index] = pickInfo.pickedPoint;
                //         mesh.position = pickInfo.pickedPoint;
                //         updateSplineOnMesh(curve.current, true);
                //         pauseHandlers([omhHandlerID, cppHandlerID]);
                //     }, () => { // drag-end
                //         console.log('end drag on mesh');
                //         resumeHandlers([omhHandlerID, cppHandlerID]);
                //         if (omdHandlerID)
                //             clearHandlers(omdHandlerID);
                //     });
                // });
                // return () => {
                //     console.log('exit cp edit');
                //     clearHandlers([ecpHandlerID, omhHandlerID, cppHandlerID]);
                // };
            }
            if (testMode === 'points-draw') {
                // track mouse-down move's over the loadedMesh's surface, dropping spheres every 1.0 units of movement,  dropping out on mouse-up.
                const omdHandlerID = onDrawOnMesh(
                    loadedMesh,
                    1.0,
                    ({ pickInfo, scene }) => {
                        const sphere = MeshBuilder.CreateSphere(
                            'sphere',
                            { segments: 16, diameter: 1 },
                            scene
                        );
                        sphere.position = pickInfo.pickedPoint;
                        sphere.material = materials.yellowMaterial;
                    }
                );

                return () => {
                    clearHandlers(omdHandlerID);
                };
            }
        }
    }, [testMode]);

    return (
        <Workspace3D
            title='Mesh trimming'
            cameraSettings={{ radius: 200 }}
        ></Workspace3D>
    );
};

export default MeshTrimming;
