import React from 'react';

const Tooth42 = ({ color }) => (
    <svg
        style={{
            position: 'absolute',
            bottom: '8px',
            left: '66px',
        }}
        width='15'
        height='30'
        viewBox='0 0 15 30'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M11.8753 10.0364C11.226 10.0364 10.4252 9.87966 9.48917 9.57166C7.97958 9.06913 7.02189 8.34506 6.64314 7.41564C6.24816 6.45381 6.44294 5.20018 7.21667 3.69258C7.21667 3.68718 7.23291 3.66558 7.23291 3.65477C7.31407 3.48726 7.39523 3.33055 7.48721 3.17925C7.48721 3.17385 7.50344 3.15223 7.50885 3.14143C9.01303 0.455856 10.2088 0.0830078 11.2747 0.0830078C11.5074 0.0830078 11.7508 0.104631 12.0268 0.142456C13.558 0.374809 14.9053 0.9854 14.5644 5.8378C14.3263 9.32309 13.3578 10.0256 11.8699 10.0256L11.8753 10.0364Z'
            fill={color || 'white'}
        />
        <path
            // opacity='0.8'
            d='M2.89453 29.834V28.738H0.198531V28.146L2.53453 24.25H3.53453V28.178H4.34253V28.738H3.53453V29.834H2.89453ZM0.830531 28.178H2.89453V24.77H2.85453L0.830531 28.178ZM9.07156 29.834H5.40756V29.146L7.23156 27.466C7.49289 27.226 7.71156 26.9727 7.88756 26.706C8.06356 26.434 8.15156 26.162 8.15156 25.89V25.794C8.15156 25.4847 8.06623 25.234 7.89556 25.042C7.72489 24.85 7.46623 24.754 7.11956 24.754C6.94889 24.754 6.79956 24.778 6.67156 24.826C6.54356 24.874 6.43156 24.9407 6.33556 25.026C6.24489 25.1113 6.16756 25.2127 6.10356 25.33C6.04489 25.442 5.99689 25.5647 5.95956 25.698L5.35956 25.466C5.41289 25.3007 5.48489 25.1407 5.57556 24.986C5.67156 24.826 5.78889 24.6847 5.92756 24.562C6.07156 24.4393 6.24223 24.3407 6.43956 24.266C6.64223 24.1913 6.87956 24.154 7.15156 24.154C7.42356 24.154 7.66623 24.194 7.87956 24.274C8.09289 24.354 8.27156 24.466 8.41556 24.61C8.55956 24.754 8.66889 24.9247 8.74356 25.122C8.82356 25.3193 8.86356 25.538 8.86356 25.778C8.86356 25.9967 8.83156 26.1993 8.76756 26.386C8.70889 26.5727 8.62623 26.7513 8.51956 26.922C8.41289 27.0873 8.28223 27.25 8.12756 27.41C7.97823 27.57 7.81556 27.73 7.63956 27.89L6.13556 29.242H9.07156V29.834Z'
            fill={color || '#F4F4F4'}
        />
    </svg>
);

export default Tooth42;
