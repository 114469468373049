import React from 'react';
import { Link, Outlet, useLocation } from "react-router-dom";
import styled from 'styled-components/macro';

import PopupDialogs from 'containers/dev/PopupDialogs';
import { HomeButton } from 'components/dev/ui/buttons';
import { paths } from 'modules//defines/paths';

const AppWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    span {
        font-size: 24px;
        padding: 10px 0px;
    }
`;

export const NoMatch = ({ }) => {
    return (
        <div>
            <h2>Nothing to see here!</h2>
            <p>
                <Link to={paths.dev}>Go to dev the home page</Link>
            </p>
        </div>
    );
};

const AppLayout = ({ }) => {
    const { pathname } = useLocation();

    return (
        <AppWrapper>
            { pathname !== paths.dev && <HomeButton /> }
            <span>Ashley 2 Development</span>
            <Outlet />  { /* this renders the child route that is currently active in the <App> routes for "/"  */ }
            <PopupDialogs />
        </AppWrapper>
    );
};

export default AppLayout;