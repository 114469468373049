import { palette } from 'modules/defines/styles';
import React from 'react';
import styled from 'styled-components';

const ImageWrapper = styled.div`
    position: relative;
    width: 72%;
    height: 45%;
    /* height: 57%; */
    border: 1px dashed ${palette.gray[2]};
    background: url(${(props) => props.src}) no-repeat 50% 50% / cover;
    overflow: hidden;
    padding: ${(props) => !props.src && '13px 18px'};
    color: ${(props) => !props.src && palette.primary.linkBlue};
`;

const FileUpload = (props) => {
    return (
        <ImageWrapper className='imageWrapper' {...props}>
            {props.children}
        </ImageWrapper>
    );
};

export default FileUpload;
