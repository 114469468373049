import React from 'react';

const CurveTeeth = () => (
    <svg
        width='70'
        height='20'
        viewBox='0 0 70 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <circle cx='16' cy='9.5' r='2' fill='white' stroke='white' />
        <circle cx='55' cy='9.5' r='2' fill='white' stroke='white' />
        <path
            d='M18 8.5L19.9777 7.104C23.7926 4.41113 28.9633 4.71695 32.4341 7.84074L36.3397 11.3558C39.4427 14.1484 43.9539 14.7197 47.655 12.7887L53 10'
            stroke='white'
        />
        <circle cx='34' cy='9.5' r='2' fill='white' stroke='white' />
    </svg>
);

export default CurveTeeth;
