import React from 'react';

const DropDown = ({ width, height, color }) => (
    <svg
        width={width || '10'}
        height={height || '6'}
        viewBox='0 0 10 6'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M5 6.00002L0 1.00002L0.7 0.300018L5 4.60002L9.3 0.300018L10 1.00002L5 6.00002Z'
            fill={color || '#F4F4F4'}
        />
    </svg>
);

export default DropDown;
