import React from 'react';

const BrushDepthAdjustment = () => (
    <svg
        style={{
            marginRight: '6px',
        }}
        width='13'
        height='12'
        viewBox='0 0 13 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M3.79775 11.3633H0.719727L1.77054 10.3125H3.79775H4.99739H11.2278L12.2787 11.3633H4.99739H3.79775Z'
            fill='#C6C6C6'
        />
        <path
            d='M5.97458 5.25264L5.97458 0.855469L7.02539 0.855469L7.02539 5.25264L7.02539 6.96641L7.02539 11.3636L5.97458 11.3636L5.97458 6.96641L5.97458 5.25264Z'
            fill='#C6C6C6'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7.51944 1.84677C7.25412 0.785491 5.74588 0.785497 5.48056 1.84676L4.01811 7.69659C3.54624 9.58407 2.19668 11.1301 0.390262 11.8527L0 10.8771C1.49313 10.2798 2.60864 9.00187 2.99867 7.44173L4.46113 1.5919C4.99176 -0.530642 8.00824 -0.530627 8.53887 1.59191L10.0013 7.44173C10.3914 9.00187 11.5069 10.2798 13 10.8771L12.6097 11.8527C10.8033 11.1301 9.45376 9.58407 8.98189 7.69659L7.51944 1.84677Z'
            fill='#C6C6C6'
        />
    </svg>
);

export default BrushDepthAdjustment;
