import React from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components/macro';
import { fonts, palette, size } from 'modules/defines/styles';
import AshelyLogo from 'assets/logo-ashely.svg';

const HeaderWrapper = styled.div`
    position: relative;
    min-width: ${size.minWidth}px;
    width: 100%;
    height: ${size.header}px;
    display: flex;
    background-color: ${palette.primary.gray};
    color: ${palette.white};
    justify-content: space-between;
    padding-right: 40px;
`;

const LogoWrapper = styled.img`
    height: 20px;
    // beta logo height: 30px
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(0, -50%);
`;
const Title = styled.span`
    color: ${palette.white};
    position: absolute;
    left: 65px;
    font-family: ${fonts.logo};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    top: 50%;
    transform: translate(0, -50%);
`;
const Header = (props) => {
    return (
        <HeaderWrapper className='headerWrapper'>
            <Link to={props.logoLink}>
                <LogoWrapper src={AshelyLogo} alt='ddhaim ashely' />
                <Title> ASHLEY </Title>
            </Link>

            {props.children}
        </HeaderWrapper>
    );
};

export default Header;
