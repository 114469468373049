import React from 'react';

const Tooth44 = ({ color }) => (
    <svg
        width='29'
        height='22'
        viewBox='0 0 29 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        style={{
            position: 'absolute',
            bottom: '32px',
            left: '34px',
        }}
    >
        <path
            d='M22.6907 11.324C22.5879 11.324 22.4851 11.3132 22.3823 11.3023H22.3282C22.2308 11.2861 22.1334 11.2699 22.036 11.2537C21.6248 11.1727 21.2677 11.043 20.8997 10.8592L20.8078 10.8106C20.7428 10.7728 20.6779 10.7404 20.613 10.7026L20.4831 10.6269C20.4236 10.5891 20.3695 10.5567 20.31 10.5188L20.1152 10.3891L20.0016 10.3081C19.9366 10.2595 19.6823 10.0703 19.6823 10.0703C19.5795 9.98928 19.4713 9.90282 19.3631 9.81637C19.3523 9.81096 19.3415 9.80017 19.3306 9.79476C19.2495 9.72992 19.1467 9.63805 19.0439 9.5516L19.0114 9.51918C18.914 9.43272 18.822 9.34627 18.7301 9.25441L18.6814 9.20576C18.5894 9.1193 18.5028 9.02745 18.4216 8.93019L18.3729 8.87615C18.2918 8.78429 18.2106 8.68703 18.1295 8.58976L18.097 8.54653C18.0158 8.44386 17.9401 8.3412 17.8643 8.23313C17.7832 8.11425 17.7183 8.00617 17.6479 7.8927L17.6263 7.86028C17.5668 7.75761 17.5072 7.64954 17.4585 7.53606L17.4423 7.50364C17.3828 7.37936 17.3395 7.26589 17.2962 7.15782L17.2746 7.10378C17.2367 6.99571 17.1988 6.88224 17.1718 6.76877L17.1609 6.72014C17.1339 6.60666 17.1068 6.48778 17.0906 6.3635C17.069 6.22301 17.0581 6.09872 17.0527 5.96903C17.0527 5.95823 17.0527 5.93662 17.0527 5.92581C17.0527 5.82314 17.0527 5.69886 17.0636 5.56918V5.52594C17.0798 5.40166 17.096 5.27198 17.1177 5.1477L17.1285 5.08286C17.1555 4.95858 17.188 4.82888 17.2313 4.69919L17.2475 4.65056C17.2962 4.49926 17.3503 4.35877 17.4098 4.22909C17.491 4.04537 17.5722 3.89407 17.6533 3.74818C17.6533 3.74818 17.6533 3.74276 17.6533 3.73736C17.8156 3.45638 18.0104 3.16998 18.2323 2.889C19.4389 1.39761 20.5859 0.700562 21.8304 0.700562C23.2318 0.700562 24.8387 1.56514 26.7487 3.34291C28.0365 4.70461 28.6208 6.2176 28.399 7.60631C28.215 8.76267 27.4792 9.77314 26.3808 10.3729C25.8505 10.6161 25.3528 10.8106 24.855 10.9673C24.0812 11.2105 23.4157 11.3294 22.8097 11.3294H22.6799L22.6907 11.324Z'
            fill={color || 'white'}
        />
        <path
            // opacity='0.8'
            d='M3.63672 21.9355V20.8395H0.940719V20.2475L3.27672 16.3515H4.27672V20.2795H5.08472V20.8395H4.27672V21.9355H3.63672ZM1.57272 20.2795H3.63672V16.8715H3.59672L1.57272 20.2795ZM8.54975 21.9355V20.8395H5.85375V20.2475L8.18975 16.3515H9.18975V20.2795H9.99775V20.8395H9.18975V21.9355H8.54975ZM6.48575 20.2795H8.54975V16.8715H8.50975L6.48575 20.2795Z'
            fill={color || '#F4F4F4'}
        />
    </svg>
);

export default Tooth44;
