import React from 'react';

const DeleteAllLayers = () => (
    <svg
        width='18'
        height='18'
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M10.2793 4.51562L11.1028 5.3391L5.33845 11.1034L4.51497 10.28L10.2793 4.51562Z'
            fill='#F4F4F4'
            fillOpacity='0.25'
        />
        <path
            d='M11.1025 10.2793L10.2791 11.1028L4.51474 5.33845L5.33821 4.51497L11.1025 10.2793Z'
            fill='#F4F4F4'
            fillOpacity='0.25'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M13.75 1.75H1.75V13.75H13.75V1.75ZM0.75 0.75V14.75H14.75V0.75H0.75Z'
            fill='#F4F4F4'
            fillOpacity='0.25'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M16.25 16.25V2.75H17.25V17.25H2.75V16.25H16.25Z'
            fill='#F4F4F4'
            fillOpacity='0.25'
        />
    </svg>
);

export default DeleteAllLayers;
