import React from 'react';

const OnTheProcess = () => (
    <svg
        width='14'
        height='14'
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M10.8821 2.42965L11.5246 1.6639C10.904 1.13853 10.1963 0.725534 9.4336 0.4435L9.0918 1.3823C9.74496 1.62459 10.3508 1.97902 10.8821 2.42965Z'
            fill='#4589FF'
        />
        <path
            d='M12.905 6L13.8889 5.7936C13.7506 4.99468 13.4734 4.22616 13.07 3.52285L12.2044 4C12.5499 4.622 12.7868 5.29836 12.905 6Z'
            fill='#4589FF'
        />
        <path
            d='M9.0918 12.6177L9.4336 13.5565C10.1963 13.2745 10.904 12.8615 11.5246 12.3361L10.8821 11.5703C10.3508 12.021 9.74496 12.3754 9.0918 12.6177Z'
            fill='#4589FF'
        />
        <path
            d='M12.2044 10L13.07 10.5C13.4737 9.7886 13.7508 9.01256 13.8891 8.2064L12.905 8.03295C12.7867 8.72415 12.5497 9.38966 12.2044 10Z'
            fill='#4589FF'
        />
        <path
            d='M7 14V0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 8.85652 0.737498 10.637 2.05025 11.9497C3.36301 13.2625 5.14348 14 7 14Z'
            fill='#4589FF'
        />
    </svg>
);

export default OnTheProcess;
