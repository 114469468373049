import React from 'react';

const Link = () => (
    <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g clipPath='url(#clip0_442_150321)'>
            <g clipPath='url(#clip1_442_150321)'>
                <rect
                    width='16'
                    height='16'
                    fill='transparent'
                    style={{ mixBlendMode: 'multiply' }}
                />
                <path
                    d='M4.57108 7.41699L0.902508 11.0513C0.79539 11.1575 0.710368 11.2839 0.652347 11.4232C0.594325 11.5625 0.564453 11.7118 0.564453 11.8627C0.564453 12.0136 0.594325 12.163 0.652347 12.3022C0.710368 12.4415 0.79539 12.5679 0.902508 12.6741L3.32537 15.097C3.43161 15.2041 3.55801 15.2891 3.69728 15.3472C3.83655 15.4052 3.98592 15.435 4.13679 15.435C4.28766 15.435 4.43704 15.4052 4.57631 15.3472C4.71558 15.2891 4.84198 15.2041 4.94822 15.097L8.58251 11.4284'
                    fill='#0F62FE'
                />
                <path
                    d='M4.57108 7.41699L0.902508 11.0513C0.79539 11.1575 0.710368 11.2839 0.652347 11.4232C0.594325 11.5625 0.564453 11.7118 0.564453 11.8627C0.564453 12.0136 0.594325 12.163 0.652347 12.3022C0.710368 12.4415 0.79539 12.5679 0.902508 12.6741L3.32537 15.097C3.43161 15.2041 3.55801 15.2891 3.69728 15.3472C3.83655 15.4052 3.98592 15.435 4.13679 15.435C4.28766 15.435 4.43704 15.4052 4.57631 15.3472C4.71558 15.2891 4.84198 15.2041 4.94822 15.097L8.58251 11.4284'
                    stroke='white'
                    strokeWidth='1.71429'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M11.4284 8.58275L15.0627 4.94847C15.1698 4.84222 15.2548 4.71582 15.3129 4.57655C15.3709 4.43729 15.4008 4.28791 15.4008 4.13704C15.4008 3.98617 15.3709 3.83679 15.3129 3.69752C15.2548 3.55825 15.1698 3.43185 15.0627 3.32561L12.6741 0.902752C12.5679 0.795634 12.4415 0.710612 12.3022 0.652591C12.163 0.59457 12.0136 0.564697 11.8627 0.564697C11.7118 0.564697 11.5625 0.59457 11.4232 0.652591C11.2839 0.710612 11.1575 0.795634 11.0513 0.902752L7.41699 4.57132'
                    stroke='white'
                    strokeWidth='1.71429'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M10.2853 5.71411L5.71387 10.2855'
                    stroke='white'
                    strokeWidth='1.71429'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
        </g>
        <defs>
            <clipPath id='clip0_442_150321'>
                <rect width='16' height='16' fill='white' />
            </clipPath>
            <clipPath id='clip1_442_150321'>
                <rect width='16' height='16' fill='white' />
            </clipPath>
        </defs>
    </svg>
);

export default Link;
