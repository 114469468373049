import { palette } from 'modules/defines/styles';
import React from 'react';
import styled from 'styled-components';

const ViewerLayoutWrapper = styled.div`
    display: flex;
    height: 90%;
    width: 100%;
    padding: 32px 30px;
`;

// 디자인의 큰 틀.(header, footer 이런 곳) (재사용성을 위해서.)
const ViewerLayout = (props) => {
    return (
        <ViewerLayoutWrapper id='viewerLayoutWrapper'>
            {props.children}
        </ViewerLayoutWrapper>
    );
};

export default ViewerLayout;
