import React from 'react';

const Tooth17 = ({ color }) => (
    <svg
        style={{
            position: 'absolute',
            top: '96px',
            left: '0',
        }}
        width='37'
        height='18'
        viewBox='0 0 37 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M27.2029 17.5616C25.3904 17.5616 23.686 17.3509 23.0421 17.259C22.7391 17.2158 22.4469 17.1185 22.1818 16.9726C19.563 15.5244 18.1562 12.7578 18.1021 8.98074C18.0643 5.51165 18.719 3.1503 20.0987 1.76158C21.1375 0.713289 22.6309 0.199951 24.6599 0.199951C25.4228 0.199951 26.2831 0.275595 27.2192 0.421491C29.984 0.853776 32.0509 1.62649 33.5389 2.78825C33.8365 2.99899 34.0799 3.18812 34.2964 3.37724C35.5841 4.6849 36.2442 6.3438 36.4985 8.91589C36.5256 9.19688 36.5472 9.48327 36.5526 9.76965C36.6176 11.9959 35.9899 13.6494 34.5723 14.9787C33.7445 15.7568 32.6191 16.4377 31.2231 16.9996C31.1095 17.0429 30.9958 17.0807 30.8768 17.1185C29.9678 17.4103 28.7342 17.5616 27.2084 17.5616H27.2029Z'
            fill={color || 'white'}
        />
        <path
            d='M0.797641 9.58398V9.06598H2.16264V5.13198H2.11364L0.881641 6.27998L0.538641 5.90898L1.83364 4.69798H2.75064V9.06598H4.01064V9.58398H0.797641ZM5.72006 9.58398L7.58906 5.20198H5.50306V6.11198H4.97806V4.69798H8.18406V5.21598L6.35006 9.58398H5.72006Z'
            fill={color || 'white'}
            // fillOpacity='0.25'
        />
    </svg>
);

export default Tooth17;
