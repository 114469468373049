import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

// state store for general display management, including popup dialogs, control panels, etc.

export const useDisplay = create((set, get) => ({
    controlPanels: [],
    meshListDialog: {
        show: false,
        contex: {}
    },

    methods: {
        showMeshListDialog: (show, context={}) => set({ meshListDialog: { show, context } }),
        setControlPanels: (controlPanels) => set({ controlPanels }),
    }
}));

//     const meshListDialog = useDisplay(state => state.meshListDialog);
//     const { showMeshListDialog } = useDisplayMethods();
//     const { meshListDialog, showMeshListDialog } = useDisplay(state => ({ meshListDialog: state.meshListDialog, ...state.methods }), shallow);

export const useDisplayMethods = () => useDisplay(state => state.methods, shallow);
export const useDisplayAndMethods = (stateGetter, flags) => [
    useDisplay(stateGetter, flags),
    useDisplay(state => state.methods, shallow),
];
