import React from 'react';
import FormItem, { FormWrapper, FormRow } from './FormItem';
import { AdditionalWrapper } from './PatientInfoForm';
import { RectangularButton } from 'components/ui/webButtons';
import { palette } from 'modules/defines/styles';
import Link from 'components/icons/Link';
import styled from 'styled-components';

const LinkWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 32px;
`;
const ShareFileForm = () => {
    return (
        <FormWrapper>
            <FormRow>
                <FormItem label='Add an email' placeholder='Typing' />
            </FormRow>
            <FormRow>
                <FormItem label='File name' placeholder='Typing' />
            </FormRow>
            <LinkWrapper>
                <AdditionalWrapper>
                    Share Link
                    <span> https://www.ddhinc.net/</span>
                </AdditionalWrapper>
                <RectangularButton
                    backgroundColor={palette.primary.blue}
                    height='32px'
                    width='107px'
                >
                    Copy link
                    <Link />
                </RectangularButton>
            </LinkWrapper>
        </FormWrapper>
    );
};

export default ShareFileForm;
