import React from 'react';

const Tooth27 = ({ color }) => (
    <svg
        style={{
            position: 'absolute',
            top: '96px',
            right: '0',
        }}
        width='38'
        height='18'
        viewBox='0 0 38 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M10.1582 17.564C8.63239 17.564 7.39334 17.4181 6.48975 17.1263C6.37072 17.0885 6.25709 17.0507 6.14888 17.0074C4.74751 16.4401 3.62208 15.7592 2.79424 14.9811C1.38205 13.6572 0.748999 12.0091 0.808517 9.79368C0.813927 9.50729 0.835574 9.21009 0.862627 8.9183C0.895091 8.57247 0.943782 8.22664 0.992479 7.89702L1.07364 7.44313L1.11692 7.23779L1.1548 7.06488L1.19267 6.90277C1.2089 6.82712 1.23055 6.76228 1.24678 6.69203L1.28466 6.55694C1.3063 6.47589 1.33335 6.40024 1.35499 6.31918L1.38205 6.22733C1.41451 6.13006 1.44698 6.0382 1.47945 5.94634L1.50109 5.8869C1.53896 5.77883 1.58224 5.67076 1.62553 5.56269C2.35597 3.80653 4.01165 1.38033 9.95802 0.450918C10.8995 0.299618 11.7706 0.22937 12.5497 0.22937C14.6383 0.22937 16.1749 0.753521 17.2462 1.83964C18.6151 3.22295 19.2915 5.62753 19.259 8.98855C19.2049 12.7602 17.7981 15.5269 15.1848 16.975C14.9196 17.1263 14.6275 17.2182 14.3245 17.2614C13.6752 17.3533 11.9708 17.564 10.1582 17.564Z'
            fill={color || 'white'}
        />
        <path
            d='M33.0824 9.58398H29.4184V8.89598L31.2424 7.21598C31.5037 6.97598 31.7224 6.72265 31.8984 6.45598C32.0744 6.18398 32.1624 5.91198 32.1624 5.63998V5.54398C32.1624 5.23465 32.077 4.98398 31.9064 4.79198C31.7357 4.59998 31.477 4.50398 31.1304 4.50398C30.9597 4.50398 30.8104 4.52798 30.6824 4.57598C30.5544 4.62398 30.4424 4.69065 30.3464 4.77598C30.2557 4.86132 30.1784 4.96265 30.1144 5.07998C30.0557 5.19198 30.0077 5.31465 29.9704 5.44798L29.3704 5.21598C29.4237 5.05065 29.4957 4.89065 29.5864 4.73598C29.6824 4.57598 29.7997 4.43465 29.9384 4.31198C30.0824 4.18932 30.253 4.09065 30.4504 4.01598C30.653 3.94132 30.8904 3.90398 31.1624 3.90398C31.4344 3.90398 31.677 3.94398 31.8904 4.02398C32.1037 4.10398 32.2824 4.21598 32.4264 4.35998C32.5704 4.50398 32.6797 4.67465 32.7544 4.87198C32.8344 5.06932 32.8744 5.28798 32.8744 5.52798C32.8744 5.74665 32.8424 5.94932 32.7784 6.13598C32.7197 6.32265 32.637 6.50132 32.5304 6.67198C32.4237 6.83732 32.293 6.99998 32.1384 7.15998C31.989 7.31998 31.8264 7.47998 31.6504 7.63998L30.1464 8.99198H33.0824V9.58398ZM35.1314 9.58398L37.2674 4.57598H34.8834V5.61598H34.2834V3.99998H37.9474V4.59198L35.8514 9.58398H35.1314Z'
            fill={color || '#F4F4F4'}
            // fillOpacity='0.25'
        />
    </svg>
);

export default Tooth27;
