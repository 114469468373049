import React from 'react';
import styled from 'styled-components/macro';

const LoginWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 30px;
`;

const Login = ({ }) => {
    return (
        <LoginWrapper>
            <h2>Login page</h2>
        </LoginWrapper>
    );
};

export default Login;