import React from 'react';

const Gums = ({ width, height }) => (
    <svg
        width={width || '16'}
        height={height || '16'}
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M1.3538 6.14086C0.854626 6.14086 0.478862 5.69877 0.573149 5.22433L0.871265 3.6959C1.14997 2.26451 2.43811 1.22803 3.937 1.22803H11.1791C12.678 1.22803 13.9662 2.26451 14.2463 3.69455L14.5444 5.22299C14.6345 5.68394 14.2851 6.1139 13.8081 6.13816'
            fill='#0F62FE'
        />
        <path
            d='M1.3538 6.14086C0.854626 6.14086 0.478862 5.69877 0.573149 5.22433L0.871265 3.6959C1.14997 2.26451 2.43811 1.22803 3.937 1.22803H11.1791C12.678 1.22803 13.9662 2.26451 14.2463 3.69455L14.5444 5.22299C14.6345 5.68394 14.2851 6.1139 13.8081 6.13816'
            stroke='#0F62FE'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M3.12429 8.18812C2.80121 8.19082 2.49755 8.20699 2.29372 8.22047C1.97897 8.24069 1.67808 8.06008 1.53804 7.74603C1.42572 7.49264 1.34114 7.11121 1.35501 6.53973C1.35501 6.46021 1.3661 4.7296 2.37969 3.81173C2.66533 3.55294 3.03277 3.43164 3.40161 3.43164H3.44043C3.80926 3.43164 4.17671 3.55294 4.46234 3.81173C5.47594 4.7296 5.48703 6.46156 5.48703 6.53973C5.50228 7.05864 5.44404 7.41986 5.35808 7.67056C5.23328 8.02908 4.90466 8.24608 4.56079 8.22721C4.32923 8.21373 3.9812 8.19621 3.71775 8.18947H3.12429V8.18812Z'
            fill='#262626'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M7.34694 8.18812C7.02387 8.19082 6.72021 8.20699 6.51638 8.22047C6.20163 8.24069 5.90074 8.06008 5.76069 7.74603C5.64838 7.49264 5.5638 7.11121 5.57766 6.53973C5.57766 6.46021 5.58875 4.7296 6.60235 3.81173C6.88798 3.55295 7.25543 3.43164 7.62426 3.43164H7.66309C8.03192 3.43164 8.39936 3.55295 8.685 3.81173C9.69859 4.7296 9.70969 6.46155 9.70969 6.53973C9.72494 7.05864 9.6667 7.41986 9.58073 7.67056C9.45594 8.02908 9.12732 8.24608 8.78345 8.22721C8.55189 8.21373 8.20385 8.19621 7.9404 8.18947H7.34694V8.18812Z'
            fill='#262626'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M11.469 8.18812C11.1459 8.19082 10.8423 8.20699 10.6384 8.22047C10.3237 8.24069 10.0228 8.06008 9.88276 7.74603C9.77045 7.49264 9.68587 7.11121 9.69973 6.53973C9.69973 6.46021 9.71083 4.7296 10.7244 3.81173C11.0101 3.55294 11.3775 3.43164 11.7463 3.43164H11.7852C12.154 3.43164 12.5214 3.55294 12.8071 3.81173C13.8207 4.7296 13.8318 6.46156 13.8318 6.53973C13.847 7.05864 13.7888 7.41986 13.7028 7.67056C13.578 8.02908 13.2494 8.24608 12.9055 8.22721C12.674 8.21373 12.3259 8.19621 12.0625 8.18947H11.469V8.18812Z'
            fill='#262626'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M13.7634 10.3152C14.2626 10.3152 14.6383 10.7573 14.544 11.2317L14.2459 12.7602C13.9672 14.1915 12.6791 15.228 11.1802 15.228L3.93805 15.228C2.43915 15.228 1.15102 14.1915 0.870927 12.7615L0.572811 11.2331C0.482684 10.7721 0.832104 10.3422 1.30909 10.3179'
            fill='#0F62FE'
        />
        <path
            d='M13.7634 10.3152C14.2626 10.3152 14.6383 10.7573 14.544 11.2317L14.2459 12.7602C13.9672 14.1915 12.6791 15.228 11.1802 15.228L3.93805 15.228C2.43915 15.228 1.15102 14.1915 0.870927 12.7615L0.572812 11.2331C0.482684 10.7721 0.832104 10.3422 1.30909 10.3179'
            stroke='#0F62FE'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M11.9929 8.26793C12.316 8.26524 12.6196 8.24906 12.8235 8.23559C13.1382 8.21537 13.4391 8.39598 13.5792 8.71002C13.6915 8.96341 13.776 9.34485 13.7622 9.91633C13.7622 9.99585 13.7511 11.7265 12.7375 12.6443C12.4519 12.9031 12.0844 13.0244 11.7156 13.0244L11.6768 13.0244C11.3079 13.0244 10.9405 12.9031 10.6548 12.6443C9.64125 11.7265 9.63016 9.9945 9.63016 9.91633C9.61491 9.39741 9.67314 9.03619 9.75911 8.7855C9.8839 8.42698 10.2125 8.20998 10.5564 8.22885C10.788 8.24232 11.136 8.25985 11.3994 8.26659L11.9929 8.26659L11.9929 8.26793Z'
            fill='#262626'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M7.77024 8.26793C8.09332 8.26524 8.39698 8.24906 8.60081 8.23559C8.91556 8.21537 9.21645 8.39598 9.3565 8.71002C9.46881 8.96341 9.55339 9.34485 9.53953 9.91633C9.53953 9.99585 9.52843 11.7265 8.51484 12.6443C8.2292 12.9031 7.86176 13.0244 7.49293 13.0244L7.4541 13.0244C7.08527 13.0244 6.71782 12.9031 6.43219 12.6443C5.41859 11.7265 5.4075 9.9945 5.4075 9.91633C5.39225 9.39741 5.45049 9.03619 5.53646 8.7855C5.66125 8.42698 5.98987 8.20998 6.33374 8.22885C6.5653 8.24232 6.91333 8.25985 7.17678 8.26659L7.77024 8.26659L7.77024 8.26793Z'
            fill='#262626'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M3.64817 8.26793C3.97125 8.26524 4.27491 8.24906 4.47874 8.23559C4.79349 8.21537 5.09438 8.39598 5.23443 8.71002C5.34674 8.96341 5.43132 9.34485 5.41746 9.91633C5.41746 9.99585 5.40636 11.7265 4.39277 12.6443C4.10713 12.9031 3.73969 13.0244 3.37086 13.0244L3.33203 13.0244C2.9632 13.0244 2.59576 12.9031 2.31012 12.6443C1.29653 11.7265 1.28543 9.9945 1.28543 9.91633C1.27018 9.39741 1.32842 9.03619 1.41438 8.7855C1.53918 8.42698 1.8678 8.20998 2.21167 8.22885C2.44323 8.24232 2.79126 8.25985 3.05471 8.26659L3.64817 8.26659L3.64817 8.26793Z'
            fill='#262626'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export default Gums;
