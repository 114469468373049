import React from 'react';

const OrientationMove = () => (
    <svg
        width='17'
        height='15'
        viewBox='0 0 17 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M5.80619 2.5359L8.23051 0.111576C8.37928 -0.0371921 8.62048 -0.0371921 8.76925 0.111576L11.1936 2.5359C11.3423 2.68467 11.3423 2.92587 11.1936 3.07464C11.0448 3.2234 10.8036 3.2234 10.6548 3.07464L8.88082 1.30063V9.28685L15.4666 12.5798L14.6733 10.1997C14.6067 10.0001 14.7146 9.78434 14.9142 9.71781C15.1138 9.65128 15.3295 9.75915 15.3961 9.95874L16.4803 13.2113C16.5468 13.4109 16.4389 13.6266 16.2393 13.6932L12.9868 14.7774C12.7872 14.8439 12.5714 14.736 12.5049 14.5364C12.4384 14.3368 12.5462 14.1211 12.7458 14.0546L15.1259 13.2612L8.50084 9.94867L1.874 13.2621L4.25408 14.0555C4.45368 14.122 4.56154 14.3377 4.49501 14.5373C4.42848 14.7369 4.21274 14.8448 4.01315 14.7782L0.760583 13.6941C0.56099 13.6275 0.453121 13.4118 0.519652 13.2122L1.60384 9.95963C1.67037 9.76003 1.88611 9.65216 2.0857 9.7187C2.2853 9.78523 2.39317 10.001 2.32663 10.2006L1.53327 12.5806L8.11893 9.28781L8.11893 1.30063L6.34493 3.07464C6.19616 3.2234 5.95496 3.2234 5.80619 3.07464C5.65742 2.92587 5.65742 2.68467 5.80619 2.5359Z'
            fill='white'
        />
    </svg>
);

export default OrientationMove;
