import React, { useEffect, useState } from 'react';
import Header from 'components/common/Header';
import Export from 'components/icons/Export';
import { paths } from 'modules/defines/paths';
import useDataExportStore, {
    useDataExportStoreMethods,
} from 'modules/stores/dataExport';

const HeaderContainer = () => {
    const { pathname } = useDataExportStore();
    const { setExport } = useDataExportStoreMethods();

    const handleExport = () => {
        console.log('export');
        setExport(true);
    };

    return (
        <Header logoLink={paths.home}>
            {pathname === paths.viewer && (
                <button onClick={handleExport}>
                    <Export />
                </button>
            )}
        </Header>
    );
};

export default HeaderContainer;
