import React from 'react';

const BrushFineControl1 = () => (
    <svg
        style={{
            marginRight: '6px',
        }}
        width='13'
        height='14'
        viewBox='0 0 13 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M8.51073 7.81836H6V6.81836H8.51073H9.48927H12V7.81836H9.48927H8.51073Z'
            fill='#C6C6C6'
        />
        <path
            d='M6 6.42136L6 1.81836L7 1.81836L7 6.42136L7 8.21536L7 12.8184L6 12.8184L6 8.21536L6 6.42136Z'
            fill='#C6C6C6'
        />
        <path
            d='M12.5 7.31836C12.5 10.6321 9.81371 13.3184 6.5 13.3184C3.18629 13.3184 0.5 10.6321 0.5 7.31836C0.5 4.00465 3.18629 1.31836 6.5 1.31836C9.81371 1.31836 12.5 4.00465 12.5 7.31836Z'
            stroke='#C6C6C6'
        />
    </svg>
);

export default BrushFineControl1;
