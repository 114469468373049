import React from 'react';
import styled from 'styled-components/macro';

import { zIndex, palette } from 'modules//defines/styles';
import { CloseButton } from 'components/dev/ui/buttons';

const PopupDialogWrapper = styled.div`
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: ${zIndex.popupDialog};
    transform: translate(-50%, -50%);
    min-height: 90px;
    padding: 15px 43px;
    border-radius: 4px;
    // box-shadow: 4px 2px 16px 0 ${palette.shadow}, -4px -2px 16px 0 ${palette.white};
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border: solid 1px ${palette.gray.light};
    background-color: ${palette.white};
    color: ${palette.text};;
    font-size: 14px;
    text-align: center;
    width: ${props => (props.geom?.width ? props.geom.width : 'max-content')};
    max-width: ${props => (props.geom?.maxWidth ? props.geom.maxWidth : '80vw')};

    & > button {
        position: absolute;
        right: 5px;
        top: 5px;
    }
`;

const ModalTitle = styled.div`
    font-weight: 700;
`;

const ModalBody = styled.div`
    padding-top: 13px;
`;

const PopupDialog = ({  title, onClose, children, geom  }) => (
    <PopupDialogWrapper geom={geom}>
        <ModalTitle>{title ? title : 'Message'}</ModalTitle>
        <CloseButton onClick={e => onClose && onClose(e)} />
        <ModalBody>{children}</ModalBody>
    </PopupDialogWrapper>
);

export default PopupDialog;