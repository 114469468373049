import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import styled, { css } from 'styled-components/macro';

import { paths } from 'modules//defines/paths';

export const buttonStyle = css`
  color: #595959;
  font-size: 11px;
  font-weight: 500;
  border: thin solid lightgray;
  background-color: white;
  border-radius: 8px;
  padding: 4px 6px;
  // margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  cursor: pointer;
  width: max-content;

  :disabled {
      color: #c8c8c8;
      cursor: unset;
  }

  &:hover:not(:disabled) {
    background-color: #eeeeee;
  }
`;

export const ButtonSet = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    button {
        ${buttonStyle};
        ${props => props.width && `width: ${props.width}px;`}
    }
    
    button + button {
        margin-top: 8px;
    }
`;

export const MtlIcon = ({ children }) => <span className="material-icons-outlined">{children}</span>

export const NavBtn = styled(Link)`
`;

export const IconBtn = styled.div`
    cursor: pointer;
`;

export const CheckButtonBtn = styled.button`
    background-color: ${props => {
        return (props.state === 'checked' ? '#90bbd7' : 'white')
    }} !important;
    color: ${props => (props.state === 'checked' ? 'white' : '#595959')} !important;
`;

export const HomeButton = ({ style={ position: 'absolute', top: 10, left: 10, color: 'gray' } }) => (
    <NavBtn style={style} to={paths.dev}><MtlIcon>home</MtlIcon></NavBtn>
);

export const CloseButton = ({ onClick, style={ position: 'absolute', top: -3, right: 3, color: 'gray' } }) => (
    <IconBtn onClick={onClick} style={style}><MtlIcon>close</MtlIcon></IconBtn>
);

export const CheckButton = ({ initState='unchecked', onClick, children }) => {
    const [state, setState] = useState('unchecked');

    useEffect(() => setState(initState), [initState]);

    const clicked = () => {
        const newState = state === 'checked' ? 'unchecked' : 'checked';
        setState(newState);
        onClick(newState);
    };

    return (
        <CheckButtonBtn state={state} onClick={clicked}>{children}</CheckButtonBtn>
    );
};

