import { palette } from 'modules/defines/styles';
import React from 'react';
import styled from 'styled-components';

const SquareButtonCss = styled.button`
    display: flex;
    aspect-ratio: 1;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : 'inherit'};
    color: ${(props) => (props.color ? props.color : palette.white)};
    height: ${(props) => (props.height ? props.height : '100%')};
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '')};
    font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
    border: none;
    outline: none;
    cursor: pointer;
    :hover:not(:disabled) {
        background-color: ${(props) =>
            props.hoverColor ? props.hoverColor : ''};
    }
    :disabled {
        /* background-color: ${palette.disabled.backgroundColor}; */
        /* color: ${palette.disabled.textColor}; */
        pointer-events: none;
    }
`;

const RectangularButtonCss = styled.button`
    display: flex;
    justify-content: ${(props) =>
        props.justifyContent ? props.justifyContent : 'center'};
    align-items: center;
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : 'none'};
    color: ${(props) => (props.color ? props.color : palette.white)};
    height: ${(props) => (props.height ? props.height : '100%')};
    width: ${(props) => (props.width ? props.width : '100%')};
    padding-left: ${(props) => (props.justifyContent ? '16px' : '')};
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '')};
    font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
    border: none;
    border-top: ${(props) => (props.borderTop ? props.borderTop : 'none')};
    outline: none;
    cursor: pointer;
    :hover:not(:disabled) {
        background-color: ${(props) =>
            props.hoverColor ? props.hoverColor : ''};
    }
    :active:not(:disabled) {
        background-color: ${(props) =>
            props.activeColor ? props.activeColor : ''};
    }
    :disabled {
        background-color: ${palette.disabled.backgroundColor};
        color: ${palette.disabled.textColor};
        pointer-events: none;
    }
`;

export const SquareButton = (props) => (
    <SquareButtonCss {...props}>{props.children}</SquareButtonCss>
);

export const RectangularButton = (props) => (
    <RectangularButtonCss {...props}>{props.children}</RectangularButtonCss>
);
