import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { RectangularButton } from 'components/ui/webButtons';
import { palette } from 'modules/defines/styles';
import styled from 'styled-components/macro';
import FormItem, { FormWrapper, FormRow } from './FormItem';
import Close from 'components/icons/Close';
import Error from 'components/icons/Error';

export const AdditionalWrapper = styled.div`
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    color: ${palette.gray[0]};
    height: 18px;
    display: flex;
    flex-direction: column;

    span {
        font-weight: 400;
        color: ${palette.gray[6]};
        margin-top: 5px;
    }
`;

const UploadButtonWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    & + & {
        margin-left: 32px;
    }
`;

const FileInput = styled.input`
    height: 42px;
    width: 100%;
    border: 1px solid red;
    position: absolute;
    display: none;
`;

const ErrorBox = styled.div`
    ${(props) => props.sizeErr && 'border: 1px solid red'};
    display: flex;
    width: 100%;
    margin-top: 15px;
    flex-direction: column;
`;
const FileName = styled.div`
    width: 100%;
    background-color: ${(props) => (props.sizeErr ? null : palette.gray[2])};
    color: ${palette.gray[0]};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 16px;
    font-size: 14px;
    border-bottom: 1px solid ${palette.gray[2]};
    svg {
        margin-left: 5px;
    }
`;

const ErrorMsg = styled.div`
    color: red;
    padding: 15px 16px;
    font-size: 12px;
    letter-spacing: 0.32px;
`;

const FormDivide = styled.div`
    width: 100%;
    border-bottom: 1px solid ${palette.gray[1]};
    padding-bottom: 32px;
    margin-bottom: 32px;
`;
// Using add new paitent / edit patient
// need to add loading
const PatientInfoForm = ({
    onChange,
    onDelete,
    mandibularFile,
    maxillaryFile,
    setMaxillaryFile,
    setMandibularFile,
}) => {
    const mandibularRef = useRef(null);
    const maxillaryRef = useRef(null);
    const [sizeErr, setSizeErr] = useState({ max: false, man: false });
    const [formValue, setFormValue] = useState({
        firstName: '',
        lastName: '',
        birthDate: '',
        gender: '',
        fileName: '',
    });

    const handleChange = (e) => {
        if (e.target.name === 'birthDate') {
            // 정규표현식
            const newObj = {};
            newObj[e.target.name] = e.target.value.replace(
                /(\d{2})(\d{2})(\d{4})/,
                '$1/$2/$3'
            );
            setFormValue({ ...formValue, ...newObj });
        } else {
            const newObj = {};
            newObj[e.target.name] = e.target.value;
            setFormValue({ ...formValue, ...newObj });
        }
        // const errorObj = {};
        // errorObj[e.target.name] = '';
        // setError((prev) => ({ ...prev, ...errorObj }));
    };

    const handleRadioChange = (e) => {
        const newObj = {};
        if (e.target.getAttribute('name') === 'gender') {
            newObj[e.target.getAttribute('name')] = Number(
                e.target.getAttribute('value')
            );
        } else {
            newObj[e.target.getAttribute('name')] =
                e.target.getAttribute('value');
        }
        setFormValue({ ...formValue, ...newObj });
    };

    const handleClick = () => {
        maxillaryRef.current.click();
    };

    const handleClick2 = () => {
        mandibularRef.current.click();
    };

    const handleDelete = (e, type) => {
        if (type === 'man') {
            mandibularRef.current.value = null;
            setMandibularFile(undefined);
        } else {
            maxillaryRef.current.value = null;
            setMaxillaryFile(undefined);
        }
    };

    useEffect(() => {
        if (mandibularFile) {
            if (mandibularFile.size > 500000) {
                setSizeErr({ ...sizeErr, man: true });
            } else {
                setSizeErr({ ...sizeErr, man: false });
            }
        }
    }, [mandibularFile]);

    useEffect(() => {
        if (maxillaryFile) {
            if (maxillaryFile.size > 500000) {
                setSizeErr({ ...sizeErr, max: true });
            } else {
                setSizeErr({ ...sizeErr, max: false });
            }
        }
    }, [maxillaryFile]);

    return (
        <FormWrapper>
            <FormDivide>
                <FormRow>
                    <FormItem
                        label='First name'
                        placeholder='Input text'
                        value={formValue.firstName}
                        onChange={handleChange}
                        name='firstName'
                    />
                    <FormItem
                        label='Last name'
                        placeholder='Input text'
                        value={formValue.lastName}
                        onChange={handleChange}
                        name='lastName'
                    />
                </FormRow>
                <FormRow>
                    <FormItem
                        type='date'
                        label='Birth date'
                        placeholder='mm/dd/yyyy'
                        value={formValue.birthDate}
                        onChange={handleChange}
                        name='birthDate'
                    />
                    <FormItem
                        type='gender'
                        label='Gender'
                        name='gender'
                        value={formValue.gender}
                        onSelect={handleRadioChange}
                    />
                </FormRow>
            </FormDivide>
            <FormRow>
                <FormItem
                    label='File name'
                    placeholder='Typing'
                    value={formValue.fileName}
                    onChange={handleChange}
                    name='fileName'
                />
            </FormRow>

            <FormRow>
                <AdditionalWrapper>
                    Upload Files
                    <span>
                        Max file size is 500kb. Supported file types are .jpg
                        and .png
                    </span>
                </AdditionalWrapper>
            </FormRow>

            <FormRow>
                <UploadButtonWrap>
                    <RectangularButton
                        backgroundColor={palette.primary.blue}
                        height='42px'
                        width='164px'
                        onClick={handleClick}
                        color={palette.gray[0]}
                        disabled={maxillaryFile && !sizeErr.max}
                    >
                        Upload Maxillary
                    </RectangularButton>
                    <FileInput
                        type='file'
                        ref={maxillaryRef}
                        accept='image/png, image/jpeg, image/jpg'
                        onChange={(e) => onChange(e, 'max')}
                    />
                    {maxillaryFile && (
                        <ErrorBox sizeErr={sizeErr.max}>
                            <FileName sizeErr={sizeErr.max}>
                                {maxillaryFile && maxillaryFile.name}
                                <button onClick={(e) => handleDelete(e, 'max')}>
                                    {sizeErr.max && <Error />}
                                    <Close />
                                </button>
                            </FileName>
                            {sizeErr.max && (
                                <ErrorMsg> File exceeds size limit</ErrorMsg>
                            )}
                        </ErrorBox>
                    )}
                </UploadButtonWrap>
                <UploadButtonWrap>
                    <RectangularButton
                        backgroundColor={palette.primary.blue}
                        height='42px'
                        width='164px'
                        onClick={handleClick2}
                        color={palette.gray[0]}
                        disabled={mandibularFile && !sizeErr.man}
                    >
                        Upload Mandibular
                    </RectangularButton>
                    <FileInput
                        type='file'
                        ref={mandibularRef}
                        accept='image/png, image/jpeg, image/jpg'
                        onChange={(e) => onChange(e, 'man')}
                    />
                    {mandibularFile && (
                        <ErrorBox sizeErr={sizeErr.man}>
                            <FileName sizeErr={sizeErr.man}>
                                {mandibularFile && mandibularFile.name}
                                <button onClick={(e) => handleDelete(e, 'man')}>
                                    {sizeErr.man && <Error />}
                                    <Close />
                                </button>
                            </FileName>
                            {sizeErr.man && (
                                <ErrorMsg> File exceeds size limit</ErrorMsg>
                            )}
                        </ErrorBox>
                    )}
                </UploadButtonWrap>
            </FormRow>
        </FormWrapper>
    );
};

export default PatientInfoForm;
