import React from 'react';
import styled from 'styled-components';
import IconCheckBox from 'components/icons/CheckBox';
import CheckCircle from 'components/icons/CheckCircle';
import { palette } from 'modules/defines/styles';

const BoardWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 16px;
`;

const GridCell = styled.div`
    width: ${(props) => props.width};
    height: 32px;
    padding-left: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    :active {
        background-color: ${palette.blue[3]};
    }
`;

const GridRow = styled.div`
    display: flex;

    :hover {
        background-color: ${palette.blue[0]};
    }
`;

const BoardBody = styled.div`
    height: 373px;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        display: none;
    }
`;

const ExportBoard = ({ headerList, fileData, onCheckAll, onCheck }) => {
    return (
        <BoardWrap>
            <GridRow>
                {headerList.map((d, i) => (
                    <GridCell
                        style={{ fontSize: '15px', fontWeight: 600 }}
                        width={d.width}
                    >
                        {d.label === null ? (
                            <button onClick={(e) => onCheckAll(e, i)}>
                                <IconCheckBox checked={d.check} />
                            </button>
                        ) : d.label === 'Select All' ? (
                            d.label
                        ) : (
                            <>
                                {d.label}
                                <button onClick={(e) => onCheckAll(e, i)}>
                                    <CheckCircle checked={d.check} />
                                </button>
                            </>
                        )}
                    </GridCell>
                ))}
            </GridRow>
            <BoardBody>
                {fileData.map((d, idx) => (
                    <GridRow>
                        <GridCell style={{ width: headerList[0].width }}>
                            <button onClick={(e) => onCheck(e, idx, 'select')}>
                                <IconCheckBox checked={d.select} />
                            </button>
                        </GridCell>
                        <GridCell style={{ width: headerList[1].width }}>
                            {d.name}
                        </GridCell>
                        <GridCell style={{ width: headerList[2].width }}>
                            Maxillary
                            <button
                                onClick={(e) => onCheck(e, idx, 'maxillary')}
                            >
                                <CheckCircle checked={d.maxillary} />
                            </button>
                        </GridCell>
                        <GridCell style={{ width: headerList[3].width }}>
                            Mandibular
                            <button
                                onClick={(e) => onCheck(e, idx, 'mandibular')}
                            >
                                <CheckCircle checked={d.mandibular} />
                            </button>
                        </GridCell>
                    </GridRow>
                ))}
            </BoardBody>
        </BoardWrap>
    );
};

export default ExportBoard;
