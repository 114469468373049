import React, { useState } from 'react';
import Modal from 'components/common/Modal';
import { InnerContainers } from 'components/ui/containers';
import DetailModal from 'components/ui/DetailModal';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';
import Draggable from 'react-draggable';

const ListContainer = styled.div`
    height: 18px;
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

const InputTitle = styled.div`
    width: 90px;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: ${palette.gray[6]};
`;

const InputContainer = styled.div`
    width: 95px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: ${palette.gray[6]};
    border-bottom: 1px solid ${palette.gray[1]};
`;

const InnerInputContainer = styled.div`
    display: flex;
    width: 100%;
    margin-right: 16px;
    height: 100%;
    justify-content: space-between;
    /* background-color: yellow; */
`;

const Input = styled.input`
    text-align: center;
    width: 100%;
    color: white;
`;

const Unit = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const MovementModal = ({ handleModal }) => {
    const [teethInput, setTeethInput] = useState({
        Move: 0.1,
        Degree: 0.3,
        'Mesial / Distal': 0,
        'Lingual / Buccal': 0,
        'Int / Ext': 0,
        Rotation: -10.75,
        Torque: 0,
        Tip: 0,
    });
    const handleTeethInput = (e) => {
        const regex = /^[-.0-9\b]+$/; // 숫자, '-', '.', 백스페이스만 입력 받음
        const input = e.target.value;
        if (regex.test(input) || input === '') {
            e.preventDefault();
            setTeethInput({
                ...teethInput,
                [e.target.name]: e.target.value,
            });
        }
    };

    return (
        <Modal
            title='Movement'
            type='movement'
            iconType='close'
            // primaryText='Delete'
            // secondaryText='Delete All'
            onClose={() => handleModal('')}
        >
            <InnerContainers
                justifyContent='flex-start'
                height='310px'
                padding='0 16px 48px'
            >
                <DetailModal height='20px' padding='0'>
                    Range Setting
                </DetailModal>
                <DetailModal
                    height='44px'
                    padding='0'
                    marginTop='10px'
                    justifyContent='space-between'
                >
                    <ListContainer>
                        <InputTitle>Move</InputTitle>
                        <InputContainer>
                            <InnerInputContainer>
                                <Input
                                    type='text'
                                    value={teethInput['Move']}
                                    name='Move'
                                    onChange={(e) => {
                                        handleTeethInput(e);
                                    }}
                                    placeholder={
                                        teethInput['Move'] === '' && '0'
                                    }
                                    autoComplete='off'
                                />
                                <Unit>mm</Unit>
                            </InnerInputContainer>
                        </InputContainer>
                    </ListContainer>
                    <ListContainer style={{ marginTop: 0 }}>
                        <InputTitle>Degree</InputTitle>
                        <InputContainer>
                            <InnerInputContainer>
                                <Input
                                    type='text'
                                    value={teethInput['Degree']}
                                    name='Degree'
                                    onChange={(e) => {
                                        handleTeethInput(e);
                                    }}
                                    placeholder={
                                        teethInput['Degree'] === '' && '0'
                                    }
                                    autoComplete='off'
                                />
                                <Unit>deg</Unit>
                            </InnerInputContainer>
                        </InputContainer>
                    </ListContainer>
                </DetailModal>
                <DetailModal height='20px' padding='0' marginTop='18px'>
                    Tooth number 12
                </DetailModal>
                <DetailModal
                    height='138px'
                    padding='0'
                    marginTop='10px'
                    justifyContent='space-between'
                >
                    <ListContainer>
                        <InputTitle>Mesial / Distal</InputTitle>
                        <InputContainer>
                            <InnerInputContainer>
                                <Input
                                    type='text'
                                    value={teethInput['Mesial / Distal']}
                                    name='Mesial / Distal'
                                    onChange={(e) => {
                                        handleTeethInput(e);
                                    }}
                                    placeholder={
                                        teethInput['Mesial / Distal'] === '' &&
                                        '0'
                                    }
                                    autoComplete='off'
                                />
                                <Unit>mm</Unit>
                            </InnerInputContainer>
                        </InputContainer>
                    </ListContainer>
                    <ListContainer style={{ marginTop: 5 }}>
                        <InputTitle>Lingual / Buccal</InputTitle>
                        <InputContainer>
                            <InnerInputContainer>
                                <Input
                                    type='text'
                                    value={teethInput['Lingual / Buccal']}
                                    name='Lingual / Buccal'
                                    onChange={(e) => {
                                        handleTeethInput(e);
                                    }}
                                    placeholder={
                                        teethInput['Lingual / Buccal'] === '' &&
                                        '0'
                                    }
                                    autoComplete='off'
                                />
                                <Unit>mm</Unit>
                            </InnerInputContainer>
                        </InputContainer>
                    </ListContainer>
                    <ListContainer style={{ marginTop: 5 }}>
                        <InputTitle>Int / Ext</InputTitle>
                        <InputContainer>
                            <InnerInputContainer>
                                <Input
                                    type='text'
                                    value={teethInput['Int / Ext']}
                                    name='Int / Ext'
                                    onChange={(e) => {
                                        handleTeethInput(e);
                                    }}
                                    placeholder={
                                        teethInput['Int / Ext'] === '' && '0'
                                    }
                                    autoComplete='off'
                                />
                                <Unit>mm</Unit>
                            </InnerInputContainer>
                        </InputContainer>
                    </ListContainer>
                    <ListContainer style={{ marginTop: 5 }}>
                        <InputTitle>Rotation</InputTitle>
                        <InputContainer>
                            <InnerInputContainer>
                                <Input
                                    type='text'
                                    value={teethInput['Rotation']}
                                    name='Rotation'
                                    onChange={(e) => {
                                        handleTeethInput(e);
                                    }}
                                    placeholder={
                                        teethInput['Rotation'] === '' && '0'
                                    }
                                    autoComplete='off'
                                />
                                <Unit>deg</Unit>
                            </InnerInputContainer>
                        </InputContainer>
                    </ListContainer>
                    <ListContainer style={{ marginTop: 5 }}>
                        <InputTitle>Torque</InputTitle>
                        <InputContainer>
                            <InnerInputContainer>
                                <Input
                                    type='text'
                                    value={teethInput['Torque']}
                                    name='Torque'
                                    onChange={(e) => {
                                        handleTeethInput(e);
                                    }}
                                    placeholder={
                                        teethInput['Torque'] === '' && '0'
                                    }
                                    autoComplete='off'
                                />
                                <Unit>deg</Unit>
                            </InnerInputContainer>
                        </InputContainer>
                    </ListContainer>
                    <ListContainer style={{ marginTop: 5 }}>
                        <InputTitle>Tip</InputTitle>
                        <InputContainer>
                            <InnerInputContainer>
                                <Input
                                    type='text'
                                    value={teethInput['Tip']}
                                    name='Tip'
                                    onChange={(e) => {
                                        handleTeethInput(e);
                                    }}
                                    placeholder={
                                        teethInput['Tip'] === '' && '0'
                                    }
                                    autoComplete='off'
                                />
                                <Unit>deg</Unit>
                            </InnerInputContainer>
                        </InputContainer>
                    </ListContainer>
                </DetailModal>
            </InnerContainers>
        </Modal>
    );
};

export default MovementModal;
