import React from 'react';

const BrushPlus = () => (
    <svg
        width='19'
        height='16'
        viewBox='0 0 19 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <circle cx='8' cy='8' r='7' stroke='white' />
        <path
            d='M7.26609 12.5V8.62963H3.5V7.37037H7.26609V3.5H8.73391V7.37037H12.5V8.62963H8.73391V12.5H7.26609Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9.5 15.35C12.9234 14.6551 15.5 11.6284 15.5 8C15.5 4.37156 12.9234 1.34493 9.5 0.650027C9.98466 0.551645 10.4863 0.5 11 0.5C15.1421 0.5 18.5 3.85786 18.5 8C18.5 12.1421 15.1421 15.5 11 15.5C10.4863 15.5 9.98467 15.4484 9.5 15.35Z'
            fill='#F4F4F4'
            fillOpacity='0.25'
        />
    </svg>
);

export default BrushPlus;
