import React from 'react';

const AddLayer = () => (
    <svg
        width='20'
        height='18'
        viewBox='0 0 20 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <rect x='9.5' y='4.18341' width='1' height='7' fill='white' />
        <rect
            x='13.5'
            y='7.18341'
            width='1'
            height='7'
            transform='rotate(90 13.5 7.18341)'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10 1.75977L2.43041 7.68244L10 13.6051L17.5696 7.68244L10 1.75977ZM10 0.490051L0.807617 7.68244L10 14.8748L19.1924 7.68244L10 0.490051Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9.99658 16.2402L1.1123 9.28888L1.11524 10.631L9.99658 17.5099L18.8809 10.631V9.28888L9.99658 16.2402Z'
            fill='white'
        />
    </svg>
);

export default AddLayer;
