import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import { makeMaterialLibrary, setupSSAO2 } from 'modules//libraries/materials';
import { PointerEventTypes, KeyboardEventTypes, Vector3 } from "@babylonjs/core";

let handlerIDCounter = 1;

// state store for the current 3D scene and its management
export const useSceneManager = create((set, get) => ({

    scene: null,            // active Babylon JS scene object & related
    canvas: null,

    materials: { },         // table of handy materials

    showInspector: false,   // UI controls
    ssaoEnabled: false,

    eventRegistry: { },     // the global event-handler registry, abstracts over scene.onPointerObservable, etc.
    handlerRegistry: { },   // stores handlers by handleID for easy releasing

    enteredMesh: null,      // tracking the mesh last entered (with mouse)
    shiftKey: false,        // tracks shift key up/down state
    selection: null,
    cameraControlsDisabled: false,

    methods: {

        // called on scene ready, adds related state & methods
        setScene: ({ scene, resetScene }) => set(state => {
            // register master pointer-observable handler
            scene.onPointerObservable.add(pointerInfo => handlePointerEvent(pointerInfo, scene, get, set));
            scene.onKeyboardObservable.add(kbInfo => handleKeyboardEvent(kbInfo, scene, get, set));
            // state update...
            return {
                scene,
                canvas: scene.getEngine().getRenderingCanvas(),
                materials: makeMaterialLibrary(scene),
                methods: {
                    ...state.methods,
                        resetScene: () => {
                        set({ ssaoEnabled: false });
                        resetScene();
                    }
                },
            };
        }),

        setSelection: (selection) => set({ selection }),

        // toggles Babylon JS scene inspector
        toggleInspector: () => set(state => {
            if (state.showInspector)
                state.scene.debugLayer.hide();
            else
                state.scene.debugLayer.show();
            return { showInspector: !state.showInspector };
        }),

        disableCameraControls: () => {
            if (!get().cameraControlsDisabled) {
                console.log('disabling camera controls')
                get().scene.cameras[0].detachControl(get().canvas);
                set({ cameraControlsDisabled: true });
            }
        },

        enableCameraControls: () => {
            if (get().cameraControlsDisabled) {
                get().scene.cameras[0].attachControl(get().canvas, true)
                set({ cameraControlsDisabled: false });
            }
        },

        // disables mouse camera controls until a mouse-up event triggers
        captureMouseWhileDown: () => {
            const scene = get().scene;
            get().methods.disableCameraControls();
            const saveOnMouseUp = scene.onPointerUp;
            scene.onPointerUp = (e, pickInfo, type) => {
                get().methods.enableCameraControls();
                scene.onPointerUp = saveOnMouseUp;
            }
        },

        // registers handler for mouse down on target mesh(es), returns handlerID to be cleared, activates on mouse down!
        //   useful for handling starts of draws or drags on a mesh
        onMeshMouseDown: (meshes, handler) => get().methods.registerHandlers({ eventType: 'pointerDown', meshes, handler }),

        // onMeshPick: (meshes, handler) => get().methods.registerHandlers({ eventType: 'pointerPick', meshes, handler }),

        onMeshPick: (meshes, handler) => {
            const m = get().methods;
            let upHandlerID;
            return m.registerHandlers(
                { eventType: 'pointerDown', meshes, handler: (args) => {
                    const downAt = args.pickInfo.pickedPoint;
                    upHandlerID = m.registerHandlers({
                        eventType: 'pointerUp', meshes: args.mesh,
                        handler: (args) => {
                            if (Vector3.Distance(downAt, args.pickInfo.pickedPoint) < 0.5) { // what's a good distance for non-dragging?
                                m.clearHandlers(upHandlerID);
                                handler(args);
                            }
                        }
                    });
                }},
            );
        },

        // registers handler for mesh "drawing" - clicking and dragging the cursor on a mesh,
        // after pointer-down, calls handler when mouse moves more than specified delta on the surface
        // returns action to be unregistered
        onDrawOnMesh: (meshes, delta, drawHandler, drawEndHandler) => {
            const m = get().methods;
            let lastPos = new Vector3();
            let moveHandlerID;
            return m.registerHandlers([
                { eventType: 'pointerDown', meshes, handler: (args) => {
                    drawHandler(args); //  call on first down and then register a move handler & call on all moves
                    moveHandlerID = m.registerHandlers({
                        eventType: 'pointerMove', meshes: args.mesh,
                        handler: (args) => {
                            if (Vector3.Distance(lastPos, args.pickInfo.pickedPoint) > delta) {
                                drawHandler(args);
                                lastPos = args.pickInfo.pickedPoint.clone();
                            }
                        }
                    });
                }},
                { eventType: 'pointerUp', handler: (args) => {
                    m.clearHandlers(moveHandlerID);
                    if (drawEndHandler) drawEndHandler(args);
                }},
            ]);
        },

        onDragOnMesh: (_draggedMeshes, _surfaceMeshes, delta, dragHandler, dragEndHandler) => {
            const draggedMeshes = listify(_draggedMeshes);
            const surfaceMeshes = listify(_surfaceMeshes);
            const m = get().methods;
            let moveHandlerID;
            let lastPos = new Vector3();
            if (draggedMeshes.length > 0) {
                return m.registerHandlers([
                    { eventType: 'pointerDown', meshes: draggedMeshes, handler: ( { mesh, index }) => {
                        console.log('onDragOnMesh.pointerDown', mesh.id, index);
                        moveHandlerID = m.registerHandlers({
                            eventType: 'pointerMove', meshes: surfaceMeshes,
                            handler: (args) => {
                                if (Vector3.Distance(lastPos, args.pickInfo.pickedPoint) > delta) {
                                    console.log('onDragOnMesh.pointerMove', mesh.id, index, args.pickInfo.pickedPoint);
                                    mesh.position = args.pickInfo.pickedPoint;
                                    dragHandler({ ...args, mesh, index });  // override moving callback to report original mouse-down mesh
                                    lastPos = args.pickInfo.pickedPoint.clone();
                                }
                            }
                        });
                    }},
                    { eventType: 'pointerUp', handler: (args) => {
                        m.clearHandlers(moveHandlerID);
                        if (dragEndHandler) dragEndHandler(args);
                    }},
                ]);
            } else {
                return m.registerHandlers([
                    {
                        eventType: 'pointerMove', surfaceMeshes,
                        handler: (args) => {
                            if (Vector3.Distance(lastPos, args.pickInfo.pickedPoint) > delta) {
                                dragHandler(args);
                                lastPos = args.pickInfo.pickedPoint.clone();
                            }
                        }
                    },
                    { eventType: 'pointerUp', handler: (args) => {
                        if (dragEndHandler) dragEndHandler(args);
                    }},
                ]);
            }
        },

        // register hovering handler
        onMeshHover: (meshes, enterHandler, leaveHandler) => {
            return get().methods.registerHandlers([
                { eventType: 'pointerEnter', meshes, handler: enterHandler },
                { eventType: 'pointerLeave', meshes, handler: leaveHandler },
            ]);
        },

        // register click off, callback on any clicks not on given meshes or anywhere else (except when shiftkey down)
        onClickOff: (meshes, handler) => {
            return get().methods.registerHandlers(
                { eventType: 'pointerDown', handler: (args) => {
                    const { mesh, pickInfo } = args;
                    if (!pickInfo.hit || meshes.indexOf(mesh) < 0) {
                        handler(args)
                    }
                }}
            );
        },

        // register keydown callback
        onKeyDown: (handler) => get().methods.registerHandlers({ eventType: 'keyDown', handler }),

        // enables ambient-occlusion testing
        enableSSAO: () => {
            setupSSAO2(get().scene);
            set({ ssaoEnabled: true });
        },

        // register handlers [{ eventType, meshes, handler }], returns ID for each registration
        registerHandlers: (handlerSpecs) => {
            const handlerID = handlerIDCounter; handlerIDCounter += 1;
            set(state => {
                const eventRegistry = state.eventRegistry;
                const handlerRegistry = state.handlerRegistry;
                const handlerEntries = listify(handlerSpecs).map(hs => {
                    const { eventType, meshes, handler } = hs;
                    const typeRegistry = eventRegistry[eventType] || {};
                    const handlerEntry = { eventType, handler, handlerID };
                    if (meshes) handlerEntry.meshes = listify(meshes);
                    typeRegistry[handlerID] = handlerEntry;
                    eventRegistry[eventType] = typeRegistry;
                    return handlerEntry;
                });
                handlerRegistry[handlerID] = { handlerEntries, meshStates: {}, keyState: {} };
                // update registries in state
                return { eventRegistry, handlerRegistry, shiftKey: false };
            });
            return handlerID;
        },

        // clear IDed handlers from event & handler registries, effectively deregisters callbacks
        clearHandlers: (handlerIDs) => set(state => {
            const { handlerRegistry, eventRegistry } = state;
            const idsToClear = handlerIDs ? listify(handlerIDs) : Object.getOwnPropertyNames(handlerRegistry); // no arg => all handlers
            idsToClear.forEach(id => {
                const registryEntry = handlerRegistry[id];
                registryEntry?.handlerEntries.forEach(he => {
                    delete eventRegistry[he.eventType][id];
                });
                delete handlerRegistry[id];
            });
            return { handlerRegistry, eventRegistry }; // update registries in state
        }),

        // mark given handlers as paused
        _setHandlersPause: (handlerIDs, pause) => set(state => {
            const { handlerRegistry } = state;
            const idsToSet = handlerIDs ? listify(handlerIDs) : Object.getOwnPropertyNames(handlerRegistry); // no arg => all handlers
            idsToSet.forEach(id => {
                const registryEntry = handlerRegistry[id];
                registryEntry?.handlerEntries.forEach(he => he.paused = pause);
            });
            return { handlerRegistry }; // update registries in state
        }),

        pauseHandlers: (handlerIDs) => get().methods._setHandlersPause(handlerIDs, true),

        resumeHandlers: (handlerIDs) => get().methods._setHandlersPause(handlerIDs, false),

        clear: () => set({ scene: null, showInspector: false, saoEnabled: false }, true),
    }
}));

// central mouse event handler, manages all callbacks registered for various events & mesh sets
const handlePointerEvent = (pointerInfo, scene, get, set) => {

    const pickedMesh = pointerInfo.pickInfo.pickedMesh;
    const {
        handlerRegistry, eventRegistry, enteredMesh, shiftKey,
        methods: { disableCameraControls, enableCameraControls }
    } = get();

    // for now, shiftKey down disables all pointer tracking
    if (shiftKey)
        return;

    switch (pointerInfo.type) {
        case PointerEventTypes.POINTERDOWN:
            // mesh pick handlers (one here so we can capture mouse on mouse-down, POINTERPICK too late
            Object.values(eventRegistry.pointerDown || {}).forEach(mh => {
                const mi = mh.meshes?.indexOf(pickedMesh);
                if (mi >= 0 || !mh.meshes && !mh.paused) {
                    console.log('please sdisable camera controls')
                    disableCameraControls();
                    const miState = handlerRegistry[mh.handlerID].meshStates?.[mi] || { };
                    handlerRegistry[mh.handlerID].meshStates[mi] = miState;
                    mh.handler({ mesh: pickedMesh, index: mi, pickInfo: pointerInfo.pickInfo, state: miState, scene });
                }
            });
            break;

        case PointerEventTypes.POINTERUP:
            Object.values(eventRegistry.pointerUp || {}).forEach(mh => {
                const mi = mh.meshes?.indexOf(pickedMesh);
                console.log('POINTERUP', mi, mh.meshes, mh)
                if ((mi >= 0 || !mh.meshes) && !mh.paused) {
                    const miState = (handlerRegistry[mh.handlerID].meshStates?.[mi]) || { };
                    handlerRegistry[mh.handlerID].meshStates[mi] = miState;
                    mh.handler({ mesh: pickedMesh, index: mi, pickInfo: pointerInfo.pickInfo, state: miState, scene });
                }
            });
            enableCameraControls();
            break;

        case PointerEventTypes.POINTERMOVE:
            // first hover enters & leaves
            {
                const meshJustLeft = pickedMesh !== enteredMesh && enteredMesh;
                let justEnteredMesh;
                if (pointerInfo.pickInfo.hit) {
                    // if any handlers target hit pickedMesh and not yet entered, run their enter handler
                    justEnteredMesh = pickedMesh;
                    Object.values(eventRegistry.pointerEnter || {}).forEach(mh => {
                        const mi = mh.meshes.indexOf(pickedMesh);
                        const miState = handlerRegistry[mh.handlerID].meshStates[mi] || { };
                        if (mi >= 0 && !miState.entered && !mh.paused) {
                            miState.entered = true;
                                handlerRegistry[mh.handlerID].meshStates[mi] = miState;
                            if (pickedMesh.delayHover) // delay hover first time in, prevents hover action for just-created meshes on mouse-clicks
                                pickedMesh.delayHover = false;
                            else
                                mh.handler({ mesh: pickedMesh, index: mi, pickInfo: pointerInfo.pickInfo, state: miState, scene });
                        }
                    });
                }
                if (meshJustLeft) {
                    Object.values(eventRegistry.pointerLeave || {}).forEach(mh => {
                        const mi = mh.meshes.indexOf(meshJustLeft);
                        const miState = handlerRegistry[mh.handlerID].meshStates[mi] || { };
                        if (mi >= 0 && miState.entered && !mh.paused) {
                            miState.entered = false;
                            handlerRegistry[mh.handlerID].meshStates[mi] = miState;
                            mh.handler({ mesh: meshJustLeft, index: mi, pickInfo: pointerInfo.pickInfo, state: miState, scene });
                        }
                    });
                }
                // update relevant state
                set({ enteredMesh: justEnteredMesh, handlerRegistry });

                // now regular pointer-moves
                Object.values(eventRegistry.pointerMove || {}).forEach(mh => {
                    const mi = mh.meshes.indexOf(pickedMesh);
                    if (mi >= 0 && !mh.paused) {
                        const miState = (handlerRegistry[mh.handlerID].meshStates?.[mi]) || { };
                        handlerRegistry[mh.handlerID].meshStates[mi] = miState;
                        mh.handler({ mesh: pickedMesh, index: mi, pickInfo: pointerInfo.pickInfo, state: miState, scene });
                    }
                });
                set({ handlerRegistry });
            }
            break;

        case PointerEventTypes.POINTERWHEEL:
            break;

        case PointerEventTypes.POINTERPICK:
            Object.values(eventRegistry.pointerPick || {}).forEach(mh => {
                const mi = mh.meshes.indexOf(pickedMesh);
                if (mi >= 0 && !mh.paused) {
                    const miState = (handlerRegistry[mh.handlerID].meshStates?.[mi]) || { };
                    handlerRegistry[mh.handlerID].meshStates[mi] = miState;
                    mh.handler({ mesh: pickedMesh, index: mi, pickInfo: pointerInfo.pickInfo, state: miState, scene });
                }
            });
            set({ handlerRegistry });
            break;

        case PointerEventTypes.POINTERTAP:
            break;

        case PointerEventTypes.POINTERDOUBLETAP:
            break;

        default:
            break;
    }
};

// central keyboard event handler
const handleKeyboardEvent = (kbInfo, scene, get, set) => {
    const {
        handlerRegistry, eventRegistry,
    } = get();

    switch (kbInfo.type) {
        case KeyboardEventTypes.KEYDOWN:
            // track shiftKey state
            if (kbInfo.event.key === 'Shift') {
                set({ shiftKey: true });
            }
            Object.values(eventRegistry.keyDown || {}).forEach(mh => {
                if (!mh.paused) {
                    const keyState = (handlerRegistry[mh.handlerID].keyStates) || { };
                    mh.handler({ kbInfo, state: keyState, scene });
                }
            });
            break;
        case KeyboardEventTypes.KEYUP:
            if (kbInfo.event.key === 'Shift') {
                set({ shiftKey: false });
            }
            break;
        default:
            break;
    }
};

const listify = (arg) => Array.isArray(arg) ? arg : [arg];

// const mesh = useSceneManager(state => state.loadedMeshw);
// const { clear, setScene } = useSceneManagerMethods();
// const { scene, setScene } = useSceneManager(state => ({ mesh: state.loadedMesh, ...state.methods }), shallow);

export const useSceneManagerMethods = () => useSceneManager(state => state.methods, shallow);
export const useSceneManagerAndMethods = (stateGetter, flags) => [
    useSceneManager(stateGetter, flags),
    useSceneManager(state => state.methods, shallow),
];
