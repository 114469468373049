import { paths } from 'modules/defines/paths';
import { useDataExportStoreMethods } from 'modules/stores/dataExport';
import React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

const HomeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
`;

const Home = ({}) => {
    const { setPathname } = useDataExportStoreMethods();

    useEffect(() => {
        setPathname(window.location.pathname);
    }, []);

    return (
        <HomeWrapper>
            <h2>Home page</h2>
            <Link to={paths.patientList}> Go to Patient List </Link>
            <Link to={paths.viewer}> Go to Viewer </Link>
        </HomeWrapper>
    );
};

export default Home;
