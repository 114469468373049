import { palette } from 'modules/defines/styles';
import React, { useState } from 'react';
import { getTrackBackground, Range } from 'react-range';

// TODO: hide 표시 클릭 시 value를 바꿔서 게이지를 이동시키면서 viewer를 보이게 할 예정. 하얀색 원도 value에 따라서 컬러 바꾸기
// TODO: Data 유무에 따라서 slider 컬러 바꾸기 // palette.gray[5], palette.primary.blue

const Slider = ({
    type,
    width,
    step,
    margin,
    barColor,
    backColor,
    thumbColor,
    handleShow,
    idx,
    show,
}) => {
    const [values, setValues] = useState([5]);
    const [testValues, setTestValues] = useState([2]);

    return type === 'hide' ? (
        <>
            <Range
                values={testValues}
                step={1}
                min={1}
                max={2}
                onChange={(newValues) => {
                    setTestValues(newValues);
                    handleShow((prev) => {
                        const temp = [...prev];
                        temp[idx] = newValues[0];
                        return temp;
                    });
                    // handleShow((prev) => ({ ...prev, [idx]: newValues[0] }));
                }}
                renderTrack={({ props, children }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            // display: 'flex',
                            // alignSelf: 'center',
                            height: '2px',
                            width: '113px',
                            margin: '0 7px',
                            borderRadius: '3px',
                            background: getTrackBackground({
                                values: [testValues],
                                colors:
                                    testValues[0] === 2
                                        ? [
                                              palette.primary.blue,
                                              palette.gray[2],
                                          ]
                                        : [palette.gray[2], palette.gray[2]],
                                min: 1,
                                max: 2,
                            }),
                        }}
                    >
                        {children}
                    </div>
                )}
                renderThumb={({ props }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            height: '10px',
                            width: '10px',
                            borderRadius: '50%',
                            backgroundColor:
                                testValues[0] === 2
                                    ? palette.primary.blue
                                    : palette.gray[2],
                            outline: 'none',
                        }}
                    />
                )}
            />
            {/* <output style={{ color: palette.gray.border }}>{values}mm</output> */}
        </>
    ) : (
        <>
            <Range
                values={values}
                step={Number(step) || 0.01}
                min={0}
                max={10}
                onChange={(newValues) => {
                    setValues(newValues);
                }}
                renderTrack={({ props, children }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            // display: 'flex',
                            // alignSelf: 'center',
                            height: '2px',
                            width: width || '113px',
                            margin: margin || '0 7px',
                            borderRadius: '3px',
                            background: getTrackBackground({
                                values: [values],
                                colors:
                                    type === 'preparation'
                                        ? ['white', palette.gray[2]]
                                        : [
                                              barColor || palette.gray[5],
                                              backColor || palette.gray[2],
                                          ],
                                min: 0,
                                max: 10,
                            }),
                        }}
                    >
                        {children}
                    </div>
                )}
                renderThumb={({ props }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            height: '10px',
                            width: '10px',
                            borderRadius: '50%',
                            backgroundColor:
                                type === 'preparation'
                                    ? 'white'
                                    : thumbColor || palette.gray[5],
                            outline: 'none',
                        }}
                    />
                )}
            />
            {!type || type === 'preparation' ? (
                <output
                    style={{ color: palette.gray.border, marginLeft: '6px' }}
                >
                    {values}mm
                </output>
            ) : (
                ''
            )}
        </>
    );
};

export default Slider;
