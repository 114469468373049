import { palette } from 'modules/defines/styles';
import React from 'react';
import styled from 'styled-components';

const GridRow = styled.div`
    display: flex;
    width: 100%;
    height: calc(100% / 12);
    background-color: ${palette.gray[2]};
`;

const GridCell = styled.div`
    display: flex;
    padding-left: 16px;
    width: ${(props) => props.w};
    height: 100%;
    font-size: 14px;
    align-items: center;
    color: white;
`;

const BoardHeader = ({ boardHeaderList }) => {
    return (
        <GridRow>
            {boardHeaderList.map((el, i) => (
                <GridCell key={i} w={el.width}>
                    {el.label}
                </GridCell>
            ))}
        </GridRow>
    );
};

export default BoardHeader;
