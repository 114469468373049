import { SquareButton } from 'components/ui/webButtons';
import DropDown from 'components/icons/DropDown';
import Left from 'components/icons/Left';
import Right from 'components/icons/Right';
import { palette, zIndex } from 'modules/defines/styles';
import React, { useState } from 'react';
import styled from 'styled-components';

const GridRow = styled.div`
    display: flex;
    /* flex-direction: column; */
    width: 100%;
    height: calc(100% / 12);
    margin-top: auto;
    align-items: center;
    background-color: ${palette.primary.gray};
    border-top: 1px solid ${palette.gray[2]};
`;

const PagenationDivision = styled.div`
    display: flex;
    aspect-ratio: ${(props) => props.aspectRatio && props.aspectRatio};
    justify-content: space-between;
    align-items: center;
    background-color: inherit;
    height: 100%;
    border: none;
    outline: none;
    white-space: nowrap;
    margin-left: ${(props) => props.marginLeft && props.marginLeft};
    margin-right: ${(props) => props.marginRight && props.marginRight};
    padding: ${(props) => (props.padding ? props.padding : '15px 8px')};
    /* cursor: pointer; */
`;

const PageNationContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: inherit;
    width: 14%;
    height: 100%;
    margin-left: auto;
    /* background-color: white; */
`;

const PageNumbersWrapper = styled.div`
    width: 80%;
    /* height: 192px; */
    position: absolute;
    left: 0;
    top: 64px;
    z-index: ${zIndex.popupDialog};
    /* border: 1px solid ${palette.gray[1]}; */
`;
const PageNumber = styled.button`
    width: 100%;
    height: 20px;
    color: ${palette.gray[0]};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-bottom: 1px solid ${palette.gray[2]};
    background: ${palette.gray[5]};

    :hover {
        background-color: ${palette.gray[4]};
    }
`;

const BoardFooter = ({
    patientList,
    handlePageClick,
    currentPage,
    totalPages,
}) => {
    // const [handleSelectNumber, setHandleSelectNumber] = useState(false);
    // 페이지넘버 만들기
    // const pageNumbers = new Array(totalPages);
    // for (let i = 0; i < pageNumbers.length; i++) {
    //     pageNumbers[i] = i + 1;
    // }

    return (
        <GridRow>
            <PageNationContainer>
                <PagenationDivision
                    aspectRatio='1'
                    marginLeft='8px'
                    style={{ justifyContent: 'flex-end', paddingRight: '0' }}
                >
                    {currentPage}
                    {/* <SquareButton
                        hoverColor='red'
                        disabled={!patientList || patientList.length === 0}
                        onClick={() =>
                            setHandleSelectNumber(!handleSelectNumber)
                        }
                    >
                        <DropDown
                            color={
                                !patientList ||
                                (patientList.length === 0 &&
                                    'rgba(244, 244, 244, 0.25)')
                            }
                        />
                    </SquareButton>
                    <PageNumbersWrapper>
                        {handleSelectNumber &&
                            pageNumbers.map((number) => (
                                <PageNumber>{number}</PageNumber>
                            ))}
                    </PageNumbersWrapper> */}
                </PagenationDivision>
                <PagenationDivision marginRight='16px'>
                    of {totalPages} Pages
                </PagenationDivision>
            </PageNationContainer>
            <SquareButton
                hoverColor='rgba(141, 141, 141, 0.16)'
                disabled={
                    !patientList || patientList.length === 0 || currentPage <= 1
                }
                onClick={() => handlePageClick(-1)}
            >
                <Left
                    color={
                        !patientList ||
                        (patientList.length === 0 &&
                            'rgba(244, 244, 244, 0.25)')
                    }
                />
            </SquareButton>
            <SquareButton
                hoverColor='rgba(141, 141, 141, 0.16)'
                disabled={
                    !patientList ||
                    patientList.length === 0 ||
                    currentPage >= totalPages
                }
                onClick={() => handlePageClick(1)}
            >
                <Right
                    color={
                        !patientList ||
                        (patientList.length === 0 &&
                            'rgba(244, 244, 244, 0.25)')
                    }
                />
            </SquareButton>
        </GridRow>
    );
};

export default BoardFooter;
