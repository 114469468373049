import React from 'react';

const OverlapSplitViewer = () => (
    <svg
        width='19'
        height='17'
        viewBox='0 0 17 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M16 1.45508H1L1 13.4551H16V1.45508ZM1 0.455078C0.447715 0.455078 0 0.902794 0 1.45508V13.4551C0 14.0074 0.447715 14.4551 1 14.4551H16C16.5523 14.4551 17 14.0074 17 13.4551V1.45508C17 0.902793 16.5523 0.455078 16 0.455078H1Z'
            fill='white'
        />
        <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M17 3.45508H1V2.45508H17V3.45508Z'
            fill='white'
        />
        <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M8 14.4551L8 3.45508L9 3.45508L9 14.4551L8 14.4551Z'
            fill='white'
        />
    </svg>
);

export default OverlapSplitViewer;
