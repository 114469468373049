import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { Vector3 } from '@babylonjs/core';
import { Engine, Scene, } from 'react-babylonjs';
import 'babylonjs-inspector';

import { WorkspaceBody } from 'layouts/dev/WorkspaceLayout';

import { useSceneManagerMethods } from 'modules//stores/scene-manager';

const Workspace3D = ({ title, cameraSettings, children }) => {
    const { setScene } = useSceneManagerMethods();
    const [sceneKey, setSceneKey] = useState(1);

    const resetScene = () => setSceneKey(key => key + 1);

    return (
        <WorkspaceBody title={title}>
            <Engine antialias adaptToDeviceRatio canvasId="babylonJS">
                <Scene key={sceneKey} onSceneMount={({ scene }) => setScene({ scene, resetScene })}>
                    <arcRotateCamera
                        name="camera1"
                        target={cameraSettings?.target || Vector3.Zero()}
                        alpha={Math.PI / 2}
                        beta={Math.PI / 4}
                        radius={cameraSettings?.radius || 8}
                        inertia={0.75}
                        angularSensibilityX={400}
                        angularSensibilityY={400}
                        panningInertia={0.75}
                        panningSensibility={40}
                    >
                        <hemisphericLight
                            name="light1"
                            intensity={0.7}
                            direction={Vector3.Up()}
                            attachToMesh={true}
                        />
                    </arcRotateCamera>
                    { children }
                </Scene>
            </Engine>
        </WorkspaceBody>
    )
};

export default Workspace3D;