import React, { useState } from 'react';
import styled from 'styled-components';
import Slider from 'components/board/Slider';
import {
    InnerButtonContainer,
    InnerContainers,
} from 'components/ui/containers';
import { RectangularButton, SquareButton } from 'components/ui/webButtons';
import { palette } from 'modules/defines/styles';
import AddLayer from 'components/icons/AddLayer';
import DeleteAllLayers from 'components/icons/DeleteAllLayers';
import SliderBack from 'components/icons/SliderBack';
import SliderPlay from 'components/icons/SliderPlay';
import SliderFoward from 'components/icons/SliderFoward';
import DetailModal from 'components/ui/DetailModal';
import Modal from 'components/common/Modal';
import StepBoard from 'components/board/StepBoard';
import { SliderContainer } from './PreparationMenu';
import Plus from 'components/icons/Plus';
import Minus from 'components/icons/Minus';
import Close from 'components/icons/Close';
import MarkerModal from '../modal/MarkerModal';
import MovementModal from '../modal/MovementModal';
import OverlapModal from '../modal/OverlapModal';

const InnerModalTitle = styled.div`
    display: flex;
    align-items: center;
    height: 45px;
    background-color: ${palette.primary.gray};
    padding: 12px;
`;

const TreatmentHistory = styled.div`
    position: relative;
    width: 100%;
    min-height: 32px;
    display: flex;
    font-size: 14px;
    justify-content: ${(props) =>
        props.className === 'title' ? 'flex-start' : 'space-between'};
    color: ${(props) => props.color};
    align-items: center;
    padding: 7px 15px;
    :hover:not(.title) {
        background-color: ${palette.gray[7]};
    }

    ${(props) =>
        props.selected &&
        `
        background-color: ${palette.gray[7]};
            :before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 3px;
            background-color: ${palette.primary.blue};
        `}
`;

const BoardHead = styled.div`
    display: flex;
    height: 36px;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    padding: 8px 16px;
    margin-bottom: 12px;
    margin-top: 16px;
`;
const StepBoxWrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;

const StepBox = styled.input`
    width: 80px;
    margin-left: 10px;
    height: 30px;
    border: 2px solid ${palette.primary.blue};
    color: ${palette.gray[0]};
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.32px;
    padding: 0 16px;
`;

const StepMinusButton = styled.button`
    position: absolute;
    right: 40px;
    bottom: 5px;
`;

const StepPlusButton = styled.button`
    position: absolute;
    right: 10px;
`;
const TreatmentHeader = [
    'Total Deviation',
    '(Step 0 > Step 1)',
    '1 Step Deviation',
];
const StepHeader = ['Total Deviation', 'Maxillary', 'Mandibular'];

const TreatmentMenu = ({ handleViewer, currentViewer, onSave }) => {
    const [buttonState, setButtonState] = useState('');
    const [isStepModal, setIsStepModal] = useState(false);
    const [maxillaryStep, setMaxillaryStep] = useState(1);
    const [mandibularStep, setMandibularStep] = useState(1);
    const [selected, setSelected] = useState();
    const firstLineButtonName = ['Marker', 'Overlap'];
    const secondLineButtonName = ['IRP', 'Moved teeth'];
    const test = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 34, 234, 2, 54, 6, 34, 634, 63, 234, 23,
    ];

    const handleSelect = (el) => {
        if (buttonState === el) {
            setButtonState('');
        } else {
            setButtonState(el);
        }
    };

    return (
        <>
            {buttonState === 'Marker' && (
                <MarkerModal handleModal={handleSelect} />
            )}
            {buttonState === 'Overlap' && (
                <OverlapModal
                    handleModal={handleSelect}
                    handleViewer={handleViewer}
                    currentViewer={currentViewer}
                    test={test}
                />
            )}
            {buttonState === 'Moved teeth' && (
                <MovementModal handleModal={handleSelect} />
            )}
            {buttonState === 'IRP' && (
                <Modal
                    title='Normal Steps'
                    iconType='setting'
                    primaryText='Set up'
                    secondaryText='Cancel'
                    onClose={() => setButtonState('')}
                    onSetting={() => setIsStepModal(true)}
                    type='treatment'
                >
                    <BoardHead>
                        Maxillary
                        <StepBoxWrapper>
                            All Steps
                            <StepBox value={maxillaryStep} />
                            <StepMinusButton>
                                <Minus />
                            </StepMinusButton>
                            <StepPlusButton>
                                <Plus />
                            </StepPlusButton>
                        </StepBoxWrapper>
                    </BoardHead>
                    <StepBoard headerList={TreatmentHeader} />

                    <BoardHead>
                        Mandibular
                        <StepBoxWrapper>
                            All Steps
                            <StepBox value={mandibularStep} />
                            <StepMinusButton>
                                <Minus />
                            </StepMinusButton>
                            <StepPlusButton>
                                <Plus />
                            </StepPlusButton>
                        </StepBoxWrapper>
                    </BoardHead>
                    <StepBoard headerList={TreatmentHeader} />
                </Modal>
            )}
            {isStepModal && (
                <Modal
                    iconType='close'
                    title='Step Contraint'
                    primaryText='Set up'
                    secondaryText='Cancel'
                    onClose={() => setIsStepModal(false)}
                    type='step'
                >
                    <StepBoard headerList={StepHeader} />
                </Modal>
            )}
            <DetailModal
                id='treatmentDetailModal'
                height='525px'
                justifyContent='flex-start'
                padding='0'
            >
                <InnerModalTitle>Treatment</InnerModalTitle>
                <InnerContainers height='208px'>
                    <TreatmentHistory
                        className='title'
                        color={palette.gray[6]}
                        style={{
                            border: `1px solid ${palette.gray[2]}`,
                            borderBottom: '',
                        }}
                    >
                        Original
                    </TreatmentHistory>
                    <InnerContainers
                        height='176px'
                        type='treatmentList'
                        style={{
                            border: `1px solid ${palette.gray[2]}`,
                            borderTop: '',
                            justifyContent: 'flex-start',
                        }}
                    >
                        {test.map((el, idx) => (
                            <TreatmentHistory
                                key={idx}
                                onClick={() => setSelected(idx)}
                                selected={selected === idx}
                            >
                                {el}
                                <button style={{ padding: 0, height: '20px' }}>
                                    <Close opacity='1' width='10' height='10' />
                                </button>
                            </TreatmentHistory>
                        ))}
                    </InnerContainers>
                </InnerContainers>
                <InnerContainers height='148px' justifyContent='center'>
                    <InnerButtonContainer
                        height='52px'
                        justifyContent='space-between'
                        backgroundColor={palette.primary.gray}
                        marginTop='0'
                        style={{
                            border: `1px solid ${palette.gray[2]}`,
                            padding: '10px',
                        }}
                    >
                        <SquareButton backgroundColor={palette.gray[1]}>
                            <AddLayer />
                        </SquareButton>
                        <SquareButton backgroundColor={palette.gray[5]}>
                            <DeleteAllLayers />
                        </SquareButton>
                    </InnerButtonContainer>
                    {/* //TODO: 데이터에 따라서 disable 내려서 색이랑 클릭 불가능 설정해야함 */}
                    <SliderContainer
                        id='TreatmentFileSlider'
                        style={{ backgroundColor: `${palette.primary.gray}` }}
                        height='48px'
                    >
                        <Slider width='100%' type='menu'></Slider>
                    </SliderContainer>
                    <InnerButtonContainer
                        id='TreatmentFileButton'
                        height='48px'
                        justifyContent='space-between'
                        backgroundColor={palette.primary.gray}
                        marginTop='0'
                        style={{
                            borderBottom: `1px solid ${palette.gray[2]}`,
                            padding: '0 13px',
                        }}
                    >
                        <SquareButton>
                            <SliderBack />
                        </SquareButton>
                        <SquareButton>
                            <SliderPlay />
                        </SquareButton>
                        <SquareButton>
                            <SliderFoward />
                        </SquareButton>
                    </InnerButtonContainer>
                </InnerContainers>
                <InnerContainers
                    height='124px'
                    backgroundColor={palette.primary.gray}
                    style={{
                        padding: '20px 12px',
                    }}
                >
                    <InnerButtonContainer
                        height='50%'
                        marginTop='0'
                        type='menuButton'
                    >
                        {firstLineButtonName.map((el, idx) => (
                            <RectangularButton
                                key={idx}
                                backgroundColor={
                                    buttonState === el
                                        ? palette.gray[2]
                                        : palette.gray[1]
                                }
                                onClick={() => handleSelect(el)}
                            >
                                {el}
                            </RectangularButton>
                        ))}
                    </InnerButtonContainer>
                    <InnerButtonContainer
                        height='50%'
                        marginTop='20px'
                        type='menuButton'
                        backgroundColor={palette.primary.gray}
                    >
                        {secondLineButtonName.map((el, idx) => (
                            <RectangularButton
                                key={idx}
                                backgroundColor={
                                    buttonState === el
                                        ? palette.gray[2]
                                        : palette.gray[1]
                                }
                                onClick={() => handleSelect(el)}
                            >
                                {el}
                            </RectangularButton>
                        ))}
                    </InnerButtonContainer>
                </InnerContainers>
            </DetailModal>
            <DetailModal
                height='40px'
                padding='0'
                minHeight='32px'
                backgroundColor={palette.primary.gray}
            >
                <InnerButtonContainer marginTop='0' height='100%'>
                    <RectangularButton
                        backgroundColor={palette.primary.blue}
                        width='50%'
                        marginLeft='auto'
                        justifyContent='start'
                        onClick={() => onSave()}
                    >
                        Save
                    </RectangularButton>
                </InnerButtonContainer>
            </DetailModal>
        </>
    );
};

export default TreatmentMenu;
