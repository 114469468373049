import React from 'react';

const Tooth18 = ({ color }) => (
    <svg
        style={{
            position: 'absolute',
            top: '117px',
            // left: '19px',
        }}
        width='38'
        height='20'
        viewBox='0 0 38 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M27.5021 19.5058C24.9645 19.5058 19.0127 18.5818 18.5582 10.0334C18.385 6.33736 19.0181 3.70583 20.4411 2.21444C21.5232 1.0797 23.1032 0.533936 25.262 0.533936C25.9438 0.533936 26.7013 0.58797 27.5075 0.701445C30.6944 1.13913 32.9723 2.07935 34.4819 3.58154C34.4927 3.59235 34.5035 3.60316 34.5198 3.61396C35.9969 4.89461 36.7327 6.44544 37.0574 8.95809C37.263 10.5305 37.2035 11.9463 36.8788 13.1783C36.3215 15.7234 34.5252 17.5497 31.3815 18.798C30.2399 19.2519 28.8656 19.5058 27.5021 19.5058Z'
            fill={color || 'white'}
        />
        <path
            d='M0.885641 12.2871V11.6951H2.44564V7.19911H2.38964L0.981641 8.51111L0.589641 8.08711L2.06964 6.70311H3.11764V11.6951H4.55764V12.2871H0.885641ZM7.49467 12.3831C7.17467 12.3831 6.89467 12.3431 6.65467 12.2631C6.41467 12.1778 6.212 12.0631 6.04667 11.9191C5.88667 11.7751 5.764 11.6044 5.67867 11.4071C5.59867 11.2098 5.55867 10.9964 5.55867 10.7671C5.55867 10.3831 5.66267 10.0791 5.87067 9.85511C6.07867 9.63111 6.35067 9.47378 6.68667 9.38311V9.31911C6.39334 9.21778 6.15867 9.05511 5.98267 8.83111C5.812 8.60178 5.72667 8.32711 5.72667 8.00711C5.72667 7.58044 5.88134 7.24178 6.19067 6.99111C6.5 6.73511 6.93467 6.60711 7.49467 6.60711C8.05467 6.60711 8.48934 6.73511 8.79867 6.99111C9.108 7.24178 9.26267 7.58044 9.26267 8.00711C9.26267 8.32711 9.17467 8.60178 8.99867 8.83111C8.828 9.05511 8.596 9.21778 8.30267 9.31911V9.38311C8.63867 9.47378 8.91067 9.63111 9.11867 9.85511C9.32667 10.0791 9.43067 10.3831 9.43067 10.7671C9.43067 10.9964 9.388 11.2098 9.30267 11.4071C9.22267 11.6044 9.1 11.7751 8.93467 11.9191C8.77467 12.0631 8.57467 12.1778 8.33467 12.2631C8.09467 12.3431 7.81467 12.3831 7.49467 12.3831ZM7.49467 11.7991C7.884 11.7991 8.18534 11.7138 8.39867 11.5431C8.612 11.3671 8.71867 11.1191 8.71867 10.7991V10.6311C8.71867 10.3111 8.612 10.0658 8.39867 9.89511C8.18534 9.71911 7.884 9.63111 7.49467 9.63111C7.10534 9.63111 6.804 9.71911 6.59067 9.89511C6.37734 10.0658 6.27067 10.3111 6.27067 10.6311V10.7991C6.27067 11.1191 6.37734 11.3671 6.59067 11.5431C6.804 11.7138 7.10534 11.7991 7.49467 11.7991ZM7.49467 9.07111C7.84667 9.07111 8.11334 8.99644 8.29467 8.84711C8.476 8.69778 8.56667 8.47911 8.56667 8.19111V8.07111C8.56667 7.78311 8.476 7.56444 8.29467 7.41511C8.11334 7.26578 7.84667 7.19111 7.49467 7.19111C7.14267 7.19111 6.876 7.26578 6.69467 7.41511C6.51334 7.56444 6.42267 7.78311 6.42267 8.07111V8.19111C6.42267 8.47911 6.51334 8.69778 6.69467 8.84711C6.876 8.99644 7.14267 9.07111 7.49467 9.07111Z'
            fill={color || '#F4F4F4'}
        />
    </svg>
);

export default Tooth18;
