import React from 'react';

const Tooth16 = ({ color }) => (
    <svg
        style={{
            position: 'absolute',
            top: '73px',
            left: '5px',
        }}
        width='37'
        height='19'
        viewBox='0 0 37 19'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M28.4161 18.1247C27.8697 18.1247 27.3232 18.0707 26.7929 17.9626C26.3925 17.8815 26.0354 17.8059 25.7324 17.7248C25.2292 17.5951 24.5583 17.379 23.7359 17.0764C22.2317 16.5252 20.9548 15.5094 20.1324 14.2179C18.7851 12.0889 18.3739 9.38714 18.9745 6.6043C19.8564 2.58405 22.459 0.276733 26.1166 0.276733C27.8913 0.276733 29.7688 0.865721 31.3974 1.93563C36.3482 5.16696 36.7378 7.9714 36.5917 9.7978C36.5917 9.80321 36.5917 9.80861 36.5917 9.81402C36.5647 10.1436 36.516 10.4895 36.4456 10.8353L36.4348 10.8893C36.3753 11.1757 36.2995 11.4729 36.2076 11.7809L36.1643 11.9268L36.0669 12.2294L36.0128 12.3861L35.9424 12.5806C35.8883 12.7211 35.8396 12.867 35.7801 13.0075C35.6989 13.2237 35.5961 13.4506 35.4717 13.6992C35.001 14.6502 34.3787 15.4824 33.6158 16.1686C32.7501 16.9521 31.6734 17.5303 30.5047 17.8383C30.4343 17.8545 30.3586 17.8761 30.2882 17.8923C29.9961 17.9572 29.7472 18.0058 29.5091 18.0382C29.152 18.0869 28.7895 18.1085 28.427 18.1085L28.4161 18.1247Z'
            fill='white'
        />
        <path
            d='M0.666891 6.88477V6.29277H2.22689V1.79677H2.17089L0.762891 3.10877L0.370891 2.68477L1.85089 1.30077H2.89889V6.29277H4.33889V6.88477H0.666891ZM7.28392 6.98077C6.99592 6.98077 6.73459 6.93277 6.49992 6.83677C6.27059 6.73543 6.07325 6.5941 5.90792 6.41277C5.74792 6.2261 5.62525 5.99943 5.53992 5.73277C5.45459 5.4661 5.41192 5.16743 5.41192 4.83677C5.41192 4.42077 5.47325 4.03143 5.59592 3.66877C5.71859 3.30077 5.87592 2.96743 6.06792 2.66877C6.26525 2.36477 6.48125 2.0981 6.71592 1.86877C6.95059 1.6341 7.17992 1.44477 7.40392 1.30077H8.26792C7.96925 1.51943 7.70259 1.73277 7.46792 1.94077C7.23325 2.14877 7.02792 2.36743 6.85192 2.59677C6.67592 2.82077 6.52659 3.06343 6.40392 3.32477C6.28125 3.58077 6.18259 3.87143 6.10792 4.19677L6.14792 4.21277C6.28125 3.98343 6.45192 3.7941 6.65992 3.64477C6.87325 3.4901 7.15059 3.41277 7.49192 3.41277C7.73192 3.41277 7.95325 3.45277 8.15592 3.53277C8.35859 3.61277 8.53192 3.72743 8.67592 3.87677C8.81992 4.0261 8.93192 4.20743 9.01192 4.42077C9.09725 4.6341 9.13992 4.87143 9.13992 5.13277C9.13992 5.40477 9.09459 5.65543 9.00392 5.88477C8.91859 6.10877 8.79325 6.30343 8.62792 6.46877C8.46792 6.62877 8.27325 6.7541 8.04392 6.84477C7.81459 6.93543 7.56125 6.98077 7.28392 6.98077ZM7.27592 6.39677C7.64392 6.39677 7.92925 6.29543 8.13192 6.09277C8.33992 5.8901 8.44392 5.59943 8.44392 5.22077V5.15677C8.44392 4.7781 8.33992 4.48743 8.13192 4.28477C7.92925 4.0821 7.64392 3.98077 7.27592 3.98077C6.90792 3.98077 6.61992 4.0821 6.41192 4.28477C6.20925 4.48743 6.10792 4.7781 6.10792 5.15677V5.22077C6.10792 5.59943 6.20925 5.8901 6.41192 6.09277C6.61992 6.29543 6.90792 6.39677 7.27592 6.39677Z'
            fill='#F4F4F4'
        />
    </svg>
);

export default Tooth16;
