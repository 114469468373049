import React from 'react';

const Redo = () => (
    <svg
        width='18'
        height='18'
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M13.5 1.92859L17.3571 5.78573L13.5 9.64287'
            stroke='white'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M17.3569 5.78577H5.78544C4.42147 5.78577 3.11336 6.3276 2.14889 7.29207C1.18441 8.25655 0.642578 9.56465 0.642578 10.9286C0.642578 12.2926 1.18441 13.6007 2.14889 14.5652C3.11336 15.5296 4.42147 16.0715 5.78544 16.0715H12.214'
            stroke='white'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export default Redo;
