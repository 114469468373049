import React, { useState } from 'react';
import {
    InnerButtonContainer,
    InnerContainers,
} from 'components/ui/containers';
import { RectangularButton, SquareButton } from 'components/ui/webButtons';
import styled from 'styled-components';
import Plus from 'components/icons/Plus';
import DetailModal from 'components/ui/DetailModal';
import Slider from 'components/board/Slider';
import { palette } from 'modules/defines/styles';
import OrientationMove from 'components/icons/OrientationMove';
import OrientationTeeth from 'components/icons/OrientationTeeth';
import StraightCutting from 'components/icons/StraightCutting';
import CurveTeeth from 'components/icons/CurveTeeth';
import BrushPlus from 'components/icons/BrushPlus';
import BrushMinus from 'components/icons/BrushMinus';
import BrushSmooth from 'components/icons/BrushSmooth';
import Undo from 'components/icons/Undo';
import Redo from 'components/icons/Redo';
import Reset from 'components/icons/Reset';
import BrushDiameterAdjustment from 'components/icons/BrushDiameterAdjustment';
import BrushDepthAdjustment from 'components/icons/BrushDepthAdjustment';

export const SliderContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: ${(props) => (props.height ? props.height : '100%')};
    margin-top: 0px;
    justify-content: ${(props) =>
        props.type === 'hide' ? 'space-between' : ''};
`;

const PreparationMenu = () => {
    const [sculptStatus, setSculptStatus] = useState('');
    const sculptButtonName = ['Maxillary', 'Mandibular'];

    return (
        <>
            <DetailModal
                height='526px'
                backgroundColor={palette.primary.gray}
                borderBottom={`1px solid ${palette.gray[2]}`}
            >
                <InnerContainers
                    id='PreparationTitle'
                    height='21px'
                    fontSize='14px'
                    justifyContent='center'
                >
                    Preparation
                </InnerContainers>
                <InnerContainers fontSize='14px' color={palette.gray[6]}>
                    Sculpt
                    <InnerButtonContainer>
                        {sculptButtonName.map((el, idx) => (
                            <RectangularButton
                                key={idx}
                                backgroundColor={
                                    sculptStatus === el
                                        ? palette.blue[1]
                                        : palette.primary.blue
                                }
                                onClick={() => setSculptStatus(el)}
                            >
                                {el}
                            </RectangularButton>
                        ))}
                    </InnerButtonContainer>
                </InnerContainers>
                <InnerContainers
                    id='orientationContainer'
                    fontSize='14px'
                    color={palette.gray[6]}
                >
                    Orientation
                    <InnerButtonContainer>
                        <RectangularButton backgroundColor={palette.gray[1]}>
                            <OrientationMove />
                        </RectangularButton>
                        <RectangularButton backgroundColor={palette.gray[1]}>
                            <OrientationTeeth />
                        </RectangularButton>
                    </InnerButtonContainer>
                </InnerContainers>
                <InnerContainers
                    id='cuttingContainer'
                    fontSize='14px'
                    color={palette.gray[6]}
                >
                    Cutting
                    <InnerButtonContainer>
                        <RectangularButton backgroundColor={palette.gray[1]}>
                            <StraightCutting />
                        </RectangularButton>
                        <RectangularButton backgroundColor={palette.gray[1]}>
                            <CurveTeeth />
                        </RectangularButton>
                    </InnerButtonContainer>
                </InnerContainers>
                <InnerContainers
                    id='brushContainer'
                    fontSize='14px'
                    color={palette.gray[6]}
                >
                    Brush
                    <InnerButtonContainer>
                        <RectangularButton backgroundColor={palette.gray[1]}>
                            <BrushPlus />
                        </RectangularButton>
                        <RectangularButton backgroundColor={palette.gray[1]}>
                            <BrushMinus />
                        </RectangularButton>
                        <RectangularButton backgroundColor={palette.gray[1]}>
                            <BrushSmooth />
                        </RectangularButton>
                    </InnerButtonContainer>
                </InnerContainers>
                <InnerContainers
                    id='brushSlide'
                    justifyContent='start'
                    height='42px'
                >
                    <SliderContainer>
                        <BrushDiameterAdjustment />
                        <Slider type='preparation' width='59%' />
                    </SliderContainer>
                    <SliderContainer>
                        <BrushDepthAdjustment />
                        <Slider type='preparation' width='59%' />
                    </SliderContainer>
                </InnerContainers>
                <InnerContainers fontSize='14px' color={palette.gray[6]}>
                    Build Base
                    <InnerButtonContainer>
                        <RectangularButton
                            backgroundColor={palette.primary.blue}
                        >
                            Start
                        </RectangularButton>
                    </InnerButtonContainer>
                </InnerContainers>
            </DetailModal>
            <DetailModal
                height='114px'
                backgroundColor={palette.primary.gray}
                padding='0'
            >
                <InnerButtonContainer
                    height='74px'
                    marginTop='0'
                    padding='20px 12px 12px'
                >
                    <RectangularButton backgroundColor={palette.gray[1]}>
                        <Undo />
                    </RectangularButton>
                    <RectangularButton backgroundColor={palette.gray[1]}>
                        <Redo />
                    </RectangularButton>
                    <RectangularButton backgroundColor={palette.gray[1]}>
                        <Reset />
                    </RectangularButton>
                </InnerButtonContainer>
                <InnerButtonContainer height='40px' style={{ marginTop: '0' }}>
                    <RectangularButton
                        backgroundColor={palette.primary.blue}
                        width='50%'
                        marginLeft='auto'
                        justifyContent='start'
                    >
                        Next step
                    </RectangularButton>
                </InnerButtonContainer>
            </DetailModal>
        </>
    );
};

export default PreparationMenu;
