import DetailModal from 'components/ui/DetailModal';
import {
    InnerButtonContainer,
    InnerContainers,
} from 'components/ui/containers';
import { RectangularButton } from 'components/ui/webButtons';
import { palette } from 'modules/defines/styles';
import React from 'react';
import styled from 'styled-components';

const AnalysisListContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 15px;
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '')};
    font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
    &:not(:nth-child(1)):not(:nth-child(2)) {
        margin-top: 10px;
    }
`;

const Circle = styled.span`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) => props.backgroundColor};
    margin-right: 6px;
`;

const AnalysisMenu = () => {
    const analysisName = [
        {
            name: 'Buccal Cusp (Cusp tip)',
            color: '#5F8DFA',
        },
        {
            name: 'Distal Incisal',
            color: '#E38A28',
        },
        {
            name: 'LingualCusp',
            color: '#74C600',
        },
        {
            name: 'Incisal Edge',
            color: '#8B4AA9',
        },
        {
            name: 'Central Fassa',
            color: '#F1504B',
        },
        {
            name: 'Mesial Incisal',
            color: '#006CC4',
        },
        {
            name: 'Buccal Groove',
            color: '#64696F',
        },
        {
            name: 'FA Point',
            color: '#008A44',
        },
    ];

    return (
        <>
            <DetailModal height='300px' backgroundColor={palette.primary.gray}>
                <InnerContainers
                    id='AnalysisModalTitle'
                    height='7%'
                    fontSize='14px'
                    justifyContent='center'
                >
                    Analysis
                </InnerContainers>

                <InnerContainers height='93%' justifyContent='flex-start'>
                    <AnalysisListContainer marginBottom='20px'>
                        Tooth Landmark
                    </AnalysisListContainer>
                    {analysisName.map((el, idx) => (
                        <AnalysisListContainer key={idx}>
                            <Circle backgroundColor={el.color} />
                            {el.name}
                        </AnalysisListContainer>
                    ))}
                </InnerContainers>
            </DetailModal>
            <DetailModal
                height='40px'
                padding='0'
                backgroundColor={palette.primary.gray}
            >
                <InnerButtonContainer marginTop='0' height='100%'>
                    {/* <div ></ㅇ> */}
                    <RectangularButton
                        backgroundColor={palette.primary.blue}
                        width='50%'
                        marginLeft='auto'
                        justifyContent='start'
                    >
                        Next step
                    </RectangularButton>
                </InnerButtonContainer>
            </DetailModal>
        </>
    );
};

export default AnalysisMenu;
