import React from 'react';

const Undo = () => (
    <svg
        width='19'
        height='18'
        viewBox='0 0 19 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g clipPath='url(#clip0_368_173435)'>
            <path
                d='M5 1.92859L1.14286 5.78573L5 9.64287'
                stroke='white'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M1.14314 5.78577H12.7146C14.0785 5.78577 15.3866 6.3276 16.3511 7.29207C17.3156 8.25655 17.8574 9.56465 17.8574 10.9286C17.8574 12.2926 17.3156 13.6007 16.3511 14.5652C15.3866 15.5296 14.0785 16.0715 12.7146 16.0715H6.28599'
                stroke='white'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </g>
        <defs>
            <clipPath id='clip0_368_173435'>
                <rect
                    width='18'
                    height='18'
                    fill='white'
                    transform='matrix(-1 0 0 1 18.5 0)'
                />
            </clipPath>
        </defs>
    </svg>
);

export default Undo;
