import React from 'react';

const CheckBox = ({ checked }) => {
    if (checked) {
        return (
            <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <rect
                    width='16'
                    height='16'
                    fill='white'
                    style={{ mixBlendMode: 'multiply' }}
                />
                <path
                    d='M13 2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V13C2 13.2652 2.10536 13.5196 2.29289 13.7071C2.48043 13.8946 2.73478 14 3 14H13C13.2652 14 13.5196 13.8946 13.7071 13.7071C13.8946 13.5196 14 13.2652 14 13V3C14 2.73478 13.8946 2.48043 13.7071 2.29289C13.5196 2.10536 13.2652 2 13 2ZM7 10.75L4.5 8.27135L5.2954 7.5L7 9.1728L10.7044 5.5L11.5002 6.2886L7 10.75Z'
                    fill='#F4F4F4'
                />
            </svg>
        );
    } else {
        return (
            <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <rect
                    width='16'
                    height='16'
                    fill='white'
                    style={{ mixBlendMode: 'multiply' }}
                />
                <path
                    d='M13 2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V13C2 13.2652 2.10536 13.5196 2.29289 13.7071C2.48043 13.8946 2.73478 14 3 14H13C13.2652 14 13.5196 13.8946 13.7071 13.7071C13.8946 13.5196 14 13.2652 14 13V3C14 2.73478 13.8946 2.48043 13.7071 2.29289C13.5196 2.10536 13.2652 2 13 2ZM3 13V3H13V13H3Z'
                    fill='#F4F4F4'
                />
            </svg>
        );
    }
};

export default CheckBox;
