import React, { useRef, useState, useMemo, useEffect } from 'react';
import { Link, Outlet } from "react-router-dom";
import styled from 'styled-components/macro';

import MeshManagerPanel from 'containers/dev/MeshManagerPanel';
import SceneManagerPanel from 'containers/dev/SceneManagerPanel';

import { Panel } from 'components/dev/ui/panels';
import { useDisplay } from 'modules/stores/display';

const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
`;

const LeftPanel = styled(Panel)`
`;

const Workspace = styled(Panel)`
`;

const WorkspaceBodyWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: calc(100vw - 364px);
    height: calc(100vh - 170px);
    margin-top: 5px;
`;

const Title = styled.div`
    position: absolute;
    top: -23px;
    left: 0px;
`;

export const WorkspaceBody = ({ title, controls, children}) => {
    return (
        <WorkspaceBodyWrapper>
            <Title>{title}</Title>
            {children}
        </WorkspaceBodyWrapper>
    )
}

const WorkspaceLayout = ({ }) => {
    const controlPanels = useDisplay(state => state.controlPanels);
    return (
        <Root>
            <LeftPanel>
                <MeshManagerPanel />
                <SceneManagerPanel />
                { controlPanels.map((CP, i) => <CP key={i} />) }
            </LeftPanel>
            <Workspace>
                <Outlet />  { /* this renders the child route that is currently active in the <App> routes for "/ws"  */ }
            </Workspace>
        </Root>
    );
};

export default WorkspaceLayout;