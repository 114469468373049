import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';
import Radio from 'components/icons/Radio';
import EmptyRadio from 'components/icons/EmptyRadio';

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    margin-right: 16px;
    margin-top: 5px;
`;

const Check = styled.span`
    pointer-events: none;
    margin-right: 5px;
    svg {
        pointer-events: none;
    }
`;

const RadioButton = ({ name, checked, onSelect, children, value }) => {
    const [active, setActive] = useState(checked || false);

    const handleClick = (e) => {
        if (e.target.dataset.checked === 'true') return;
        setActive(!active);
        if (onSelect) onSelect(e);
    };

    useEffect(() => {
        try {
            setActive(checked);
        } catch (error) {
            console.log(error);
        }
    }, [checked]);

    return (
        <ButtonWrapper
            name={name}
            onClick={handleClick}
            checked={checked}
            value={value}
        >
            <Check checked={active}>
                {active ? <Radio /> : <EmptyRadio />}
            </Check>
            {children}
        </ButtonWrapper>
    );
};

export default RadioButton;
