import React from 'react';

const Show = () => (
    <svg
        width='20'
        height='20'
        viewBox='0 0 15 9'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M7.55908 1.20024C9.9709 1.20024 12.1218 2.47824 13.1718 4.50024C12.1218 6.52224 9.9709 7.80024 7.55908 7.80024C5.14726 7.80024 2.99635 6.52224 1.94635 4.50024C2.99635 2.47824 5.14726 1.20024 7.55908 1.20024ZM7.55908 0.000244141C4.37726 0.000244141 1.65999 1.86624 0.559082 4.50024C1.65999 7.13424 4.37726 9.00024 7.55908 9.00024C10.7409 9.00024 13.4582 7.13424 14.5591 4.50024C13.4582 1.86624 10.7409 0.000244141 7.55908 0.000244141ZM7.55908 3.00024C8.43726 3.00024 9.14999 3.67224 9.14999 4.50024C9.14999 5.32824 8.43726 6.00024 7.55908 6.00024C6.6809 6.00024 5.96817 5.32824 5.96817 4.50024C5.96817 3.67224 6.6809 3.00024 7.55908 3.00024ZM7.55908 1.80024C5.9809 1.80024 4.69545 3.01224 4.69545 4.50024C4.69545 5.98824 5.9809 7.20024 7.55908 7.20024C9.13726 7.20024 10.4227 5.98824 10.4227 4.50024C10.4227 3.01224 9.13726 1.80024 7.55908 1.80024Z'
            fill='#0F62FE'
        />
    </svg>
);

export default Show;
