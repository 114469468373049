import React from 'react';

const Left = ({ width, height, color }) => (
    <svg
        width={width || '5'}
        height={height || '8'}
        viewBox='0 0 5 8'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path d='M5 8L0 4L5 0V8Z' fill={color || '#F4F4F4'} />
    </svg>
);

export default Left;
