import ChartLinkContainer from 'containers/ChartLinkContainer';
import React from 'react';

const ChartLink = ({ windowWidth, windowHeight }) => {
    return (
        <ChartLinkContainer
            windowWidth={windowWidth}
            windowHeight={windowHeight}
        />
    );
};

export default ChartLink;
