import { useRef } from 'react';
import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import '@babylonjs/loaders/STL';
import { ActionManager, SceneLoader } from "@babylonjs/core";
import {useMeshTrimStore} from "./mesh-trimming";

// state store for scene meshes and their management

const useMeshStore = create((set, get) => ({
    loadedMesh: null,   // this needs to be a table of loaded meshes

    methods: {
        downloadMesh: (urlPath, filename, material, scene) => {
            // The first parameter can be set to null to load all meshes and skeletons
            SceneLoader.ImportMeshAsync(null, `http://localhost:1337${urlPath}`, filename, scene)
                .then(({ meshes }) => { // Result has meshes, particleSystems, skeletons, animationGroups and transformNodes
                    // we only take the first mesh fuond, for now. assign material and set state-store reference to it
                    const mesh = meshes[0];
                    mesh.material = material;
                    mesh.isPickable = false;
                    if (!mesh.actionManager)
                        mesh.actionManager = new ActionManager(mesh.getScene());
                    mesh.computeWorldMatrix();
                    mesh.updateFacetData();
                    mesh.partitioningBBoxRatio = 1.10;
                    mesh.partitioningSubdivisions = 10;
                    mesh.updateFacetData();
                    console.log('facetNb', mesh.facetNb);
                    set({ loadedMesh: mesh})
                });
        },
        uploadMesh: (mesh, name) => { /* to be implemented - for now use the Strapi Media Library uploader */ },
        clear: () => set({ loadedMesh: null, methods: { ...get().methods } }, true),
    }
}));

// const mesh = useMeshStore(state => state.loadedMeshw);
// const { clear, downloadMesh } = useMeshStoreMethods();
// const [mesh, { downloadMesh }] = useMeshStoreAndMethods(state => state.loadedMesh);
// const [{ mesh, foo}, { downloadMesh }] = useMeshStoreAndMethods(state => ({ mesh: state.loadedMesh, foo: state.foo }, shallow);

export const useMeshStoreMethods = () => useMeshStore(state => state.methods, shallow);
export const useMeshStoreAndMethods = (stateGetter, flags) => [
    useMeshStore(stateGetter, flags),
    useMeshStore(state => state.methods, shallow),
];

export default useMeshStore;