import React from 'react';

const Tooth48 = ({ color }) => (
    <svg
        style={{
            position: 'absolute',
            bottom: '116px',
            left: '0',
        }}
        width='37'
        height='21'
        viewBox='0 0 37 21'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M28.7469 20.2107C27.4429 20.2107 26.1119 20.0216 24.7971 19.6541C20.1006 18.3303 18.0716 15.0233 18.5964 9.53327C18.986 5.42656 19.3701 4.30802 20.5767 2.31951C21.1935 1.30364 22.7897 0.74707 25.0622 0.74707C26.3121 0.74707 27.6972 0.914586 29.0715 1.2334C29.2934 1.28203 29.5098 1.34147 29.7262 1.3955C35.5914 2.96794 36.8359 8.91185 36.8521 13.6184C36.8575 16.1688 36.1812 17.7197 34.7744 18.3627C34.7203 18.3843 34.6716 18.4167 34.6283 18.4546C33.1891 19.5893 31.1005 20.2161 28.7469 20.2161V20.2107Z'
            fill={color || 'white'}
        />
        <path
            // opacity='0.8'
            d='M3.18164 14.1035V13.0075H0.485641V12.4155L2.82164 8.51952H3.82164V12.4475H4.62964V13.0075H3.82164V14.1035H3.18164ZM1.11764 12.4475H3.18164V9.03952H3.14164L1.11764 12.4475ZM7.49467 14.1995C7.17467 14.1995 6.89467 14.1595 6.65467 14.0795C6.41467 13.9942 6.212 13.8795 6.04667 13.7355C5.88667 13.5915 5.764 13.4208 5.67867 13.2235C5.59867 13.0262 5.55867 12.8128 5.55867 12.5835C5.55867 12.1995 5.66267 11.8955 5.87067 11.6715C6.07867 11.4475 6.35067 11.2902 6.68667 11.1995V11.1355C6.39334 11.0342 6.15867 10.8715 5.98267 10.6475C5.812 10.4182 5.72667 10.1435 5.72667 9.82352C5.72667 9.39685 5.88134 9.05818 6.19067 8.80752C6.5 8.55152 6.93467 8.42352 7.49467 8.42352C8.05467 8.42352 8.48934 8.55152 8.79867 8.80752C9.108 9.05818 9.26267 9.39685 9.26267 9.82352C9.26267 10.1435 9.17467 10.4182 8.99867 10.6475C8.828 10.8715 8.596 11.0342 8.30267 11.1355V11.1995C8.63867 11.2902 8.91067 11.4475 9.11867 11.6715C9.32667 11.8955 9.43067 12.1995 9.43067 12.5835C9.43067 12.8128 9.388 13.0262 9.30267 13.2235C9.22267 13.4208 9.1 13.5915 8.93467 13.7355C8.77467 13.8795 8.57467 13.9942 8.33467 14.0795C8.09467 14.1595 7.81467 14.1995 7.49467 14.1995ZM7.49467 13.6155C7.884 13.6155 8.18534 13.5302 8.39867 13.3595C8.612 13.1835 8.71867 12.9355 8.71867 12.6155V12.4475C8.71867 12.1275 8.612 11.8822 8.39867 11.7115C8.18534 11.5355 7.884 11.4475 7.49467 11.4475C7.10534 11.4475 6.804 11.5355 6.59067 11.7115C6.37734 11.8822 6.27067 12.1275 6.27067 12.4475V12.6155C6.27067 12.9355 6.37734 13.1835 6.59067 13.3595C6.804 13.5302 7.10534 13.6155 7.49467 13.6155ZM7.49467 10.8875C7.84667 10.8875 8.11334 10.8128 8.29467 10.6635C8.476 10.5142 8.56667 10.2955 8.56667 10.0075V9.88752C8.56667 9.59952 8.476 9.38085 8.29467 9.23152C8.11334 9.08218 7.84667 9.00752 7.49467 9.00752C7.14267 9.00752 6.876 9.08218 6.69467 9.23152C6.51334 9.38085 6.42267 9.59952 6.42267 9.88752V10.0075C6.42267 10.2955 6.51334 10.5142 6.69467 10.6635C6.876 10.8128 7.14267 10.8875 7.49467 10.8875Z'
            fill={color || '#F4F4F4'}
        />
    </svg>
);

export default Tooth48;
