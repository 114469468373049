import React from 'react';

const Tooth45 = ({ color }) => (
    <svg
        style={{
            position: 'absolute',
            bottom: '49px',
            left: '22px',
        }}
        width='34'
        height='20'
        viewBox='0 0 34 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M26.2736 13.3388C26.1329 13.3388 25.9922 13.3388 25.8569 13.328H25.8028C25.6351 13.3118 25.4728 13.2956 25.3105 13.2686C25.1373 13.2416 24.9804 13.2092 24.8235 13.1713C24.6666 13.1335 24.5205 13.0903 24.3798 13.0416C24.3798 13.0416 24.3365 13.0254 24.3311 13.0254C24.1959 12.9768 24.066 12.9228 23.9361 12.8687L23.9091 12.8579C23.763 12.7931 23.6386 12.7282 23.5141 12.658C23.5141 12.658 23.5087 12.658 23.5033 12.658C23.3788 12.5877 23.2598 12.5121 23.1462 12.4364C23.0109 12.3446 22.9081 12.2635 22.7999 12.1825L22.7566 12.15C22.6538 12.069 22.5564 11.9771 22.459 11.8907L22.4211 11.8582C22.2264 11.6745 22.0424 11.4692 21.8693 11.2585L21.8368 11.2206C21.761 11.1234 21.6799 11.0207 21.6041 10.9126L21.5663 10.8586C21.4905 10.7505 21.4202 10.6424 21.3552 10.5398L21.3228 10.4858C21.2578 10.3831 21.1929 10.2642 21.128 10.1507C21.0793 10.0643 21.0306 9.96701 20.9819 9.87515L20.944 9.8049C20.8953 9.71304 20.8466 9.61038 20.7979 9.50771L20.7763 9.45907C20.7384 9.37261 20.6951 9.27535 20.6518 9.17268C20.6518 9.17268 20.6302 9.11865 20.6302 9.11325C20.5869 9.00517 20.5436 8.8917 20.5003 8.77823L20.4841 8.7296C20.4462 8.61612 20.4084 8.50264 20.3705 8.38376C20.3272 8.23787 20.2947 8.11359 20.2623 7.98931L20.2515 7.93527C20.1919 7.6759 20.1432 7.41653 20.1162 7.15716V7.11392C20.0891 6.83834 20.0837 6.56277 20.1 6.298C20.1108 6.13589 20.127 6.0008 20.1486 5.86031C20.1973 5.55771 20.2731 5.27671 20.3759 5.00654C20.4949 4.69313 20.641 4.41214 20.8088 4.14196L20.8358 4.09874C21.3877 3.25038 22.2588 2.46687 23.4275 1.78602C25.1157 0.797166 26.4575 0.332458 27.6425 0.332458C29.5903 0.332458 31.3326 1.62931 33.1181 4.41755C33.789 5.46044 33.8702 6.87077 33.3454 8.38917C32.6528 10.4047 31.0242 12.1663 29.2927 12.7823C28.7571 12.9714 28.2268 13.1173 27.7182 13.2145C27.7074 13.2145 27.5884 13.2362 27.5775 13.2416C27.188 13.3118 26.7876 13.3496 26.4034 13.3604H26.2573L26.2736 13.3388Z'
            fill={color || 'white'}
        />
        <path
            // opacity='0.8'
            d='M3.68848 19.209V18.113H0.992477V17.521L3.32848 13.625H4.32848V17.553H5.13648V18.113H4.32848V19.209H3.68848ZM1.62448 17.553H3.68848V14.145H3.64848L1.62448 17.553ZM9.58551 14.217H7.13751L6.97751 16.345H7.03351C7.16684 16.1583 7.31617 16.009 7.48151 15.897C7.65217 15.785 7.88417 15.729 8.17751 15.729C8.41751 15.729 8.63884 15.769 8.84151 15.849C9.04417 15.929 9.22017 16.0437 9.36951 16.193C9.51884 16.337 9.63351 16.5157 9.71351 16.729C9.79884 16.9423 9.84151 17.185 9.84151 17.457C9.84151 17.729 9.79884 17.9797 9.71351 18.209C9.62817 18.433 9.50284 18.6277 9.33751 18.793C9.17751 18.953 8.98017 19.0783 8.74551 19.169C8.51617 19.2597 8.25484 19.305 7.96151 19.305C7.72684 19.305 7.51884 19.2783 7.33751 19.225C7.16151 19.1717 7.00417 19.0997 6.86551 19.009C6.72684 18.9183 6.60417 18.817 6.49751 18.705C6.39617 18.593 6.30284 18.4757 6.21751 18.353L6.72151 17.969C6.79084 18.081 6.86284 18.1823 6.93751 18.273C7.01751 18.3637 7.10284 18.441 7.19351 18.505C7.28951 18.569 7.39884 18.6197 7.52151 18.657C7.64417 18.689 7.79084 18.705 7.96151 18.705C8.34551 18.705 8.63617 18.6037 8.83351 18.401C9.03084 18.1983 9.12951 17.913 9.12951 17.545V17.465C9.12951 17.097 9.03084 16.8117 8.83351 16.609C8.63617 16.4063 8.34551 16.305 7.96151 16.305C7.70551 16.305 7.50284 16.353 7.35351 16.449C7.20951 16.5397 7.07884 16.649 6.96151 16.777L6.39351 16.697L6.59351 13.625H9.58551V14.217Z'
            fill={color || '#F4F4F4'}
        />
    </svg>
);

export default Tooth45;
