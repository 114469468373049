import React from 'react';

const SelectedTeeth = ({ width, height }) => (
    <svg
        width={width || '16'}
        height={height || '16'}
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M1.3538 5.80992C0.854626 5.80992 0.478862 5.36784 0.573149 4.8934L0.871265 3.36497C1.14997 1.93357 2.43811 0.897095 3.937 0.897095H11.1791C12.678 0.897095 13.9662 1.93357 14.2463 3.36362L14.5444 4.89205C14.6345 5.35301 14.2851 5.78297 13.8081 5.80723'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M3.12429 7.85719C2.80121 7.85988 2.49755 7.87606 2.29372 7.88954C1.97897 7.90975 1.67808 7.72915 1.53804 7.4151C1.42572 7.16171 1.34114 6.78027 1.35501 6.2088C1.35501 6.12927 1.3661 4.39867 2.37969 3.4808C2.66533 3.22201 3.03277 3.10071 3.40161 3.10071H3.44043C3.80926 3.10071 4.17671 3.22201 4.46234 3.4808C5.47594 4.39867 5.48703 6.13062 5.48703 6.2088C5.50228 6.72771 5.44404 7.08893 5.35808 7.33962C5.23328 7.69814 4.90466 7.91515 4.56079 7.89628C4.32923 7.8828 3.9812 7.86528 3.71775 7.85854H3.12429V7.85719Z'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M7.34694 7.85719C7.02387 7.85988 6.72021 7.87606 6.51638 7.88954C6.20163 7.90975 5.90074 7.72914 5.76069 7.4151C5.64838 7.16171 5.5638 6.78027 5.57766 6.2088C5.57766 6.12927 5.58875 4.39866 6.60235 3.4808C6.88798 3.22201 7.25543 3.10071 7.62426 3.10071H7.66309C8.03192 3.10071 8.39936 3.22201 8.685 3.4808C9.69859 4.39866 9.70969 6.13062 9.70969 6.2088C9.72494 6.72771 9.6667 7.08893 9.58073 7.33962C9.45594 7.69814 9.12732 7.91514 8.78345 7.89627C8.55189 7.8828 8.20385 7.86528 7.9404 7.85854H7.34694V7.85719Z'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M11.469 7.85719C11.1459 7.85988 10.8423 7.87606 10.6384 7.88954C10.3237 7.90975 10.0228 7.72915 9.88276 7.4151C9.77045 7.16171 9.68587 6.78027 9.69973 6.2088C9.69973 6.12927 9.71083 4.39867 10.7244 3.4808C11.0101 3.22201 11.3775 3.10071 11.7463 3.10071H11.7852C12.154 3.10071 12.5214 3.22201 12.8071 3.4808C13.8207 4.39867 13.8318 6.13062 13.8318 6.2088C13.847 6.72771 13.7888 7.08893 13.7028 7.33962C13.578 7.69814 13.2494 7.91515 12.9055 7.89628C12.674 7.8828 12.3259 7.86528 12.0625 7.85854H11.469V7.85719Z'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M11.9929 7.937C12.316 7.93431 12.6196 7.91813 12.8235 7.90465C13.1382 7.88444 13.4391 8.06504 13.5792 8.37909C13.6915 8.63248 13.776 9.01391 13.7622 9.58539C13.7622 9.66491 13.7511 11.3955 12.7375 12.3134C12.4519 12.5722 12.0844 12.6935 11.7156 12.6935L11.6768 12.6935C11.3079 12.6935 10.9405 12.5722 10.6548 12.3134C9.64125 11.3955 9.63016 9.66357 9.63016 9.58539C9.61491 9.06648 9.67314 8.70526 9.75911 8.45457C9.8839 8.09604 10.2125 7.87904 10.5564 7.89791C10.788 7.91139 11.136 7.92891 11.3994 7.93565L11.9929 7.93565L11.9929 7.937Z'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M3.64817 7.937C3.97125 7.93431 4.27491 7.91813 4.47874 7.90465C4.79349 7.88444 5.09438 8.06504 5.23443 8.37909C5.34674 8.63248 5.43132 9.01391 5.41746 9.58539C5.41746 9.66491 5.40636 11.3955 4.39277 12.3134C4.10713 12.5722 3.73969 12.6935 3.37086 12.6935L3.33203 12.6935C2.9632 12.6935 2.59576 12.5722 2.31012 12.3134C1.29653 11.3955 1.28543 9.66357 1.28543 9.58539C1.27018 9.06648 1.32842 8.70526 1.41438 8.45457C1.53918 8.09604 1.8678 7.87904 2.21167 7.89791C2.44323 7.91139 2.79126 7.92891 3.05471 7.93565L3.64817 7.93565L3.64817 7.937Z'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M13.7634 9.98427C14.2626 9.98427 14.6383 10.4264 14.544 10.9008L14.2459 12.4292C13.9672 13.8606 12.6791 14.8971 11.1802 14.8971L3.93805 14.8971C2.43915 14.8971 1.15102 13.8606 0.870927 12.4306L0.572812 10.9021C0.482684 10.4412 0.832104 10.0112 1.30909 9.98696'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M7.77024 7.937C8.09332 7.93431 8.39698 7.91813 8.60081 7.90465C8.91556 7.88444 9.21645 8.06504 9.3565 8.37909C9.46881 8.63248 9.55339 9.01391 9.53953 9.58539C9.53953 9.66491 9.52843 11.3955 8.51484 12.3134C8.2292 12.5722 7.86176 12.6935 7.49293 12.6935L7.4541 12.6935C7.08527 12.6935 6.71782 12.5722 6.43219 12.3134C5.41859 11.3955 5.4075 9.66357 5.4075 9.58539C5.39225 9.06648 5.45049 8.70526 5.53646 8.45457C5.66125 8.09604 5.98987 7.87904 6.33374 7.89791C6.5653 7.91139 6.91333 7.92891 7.17678 7.93565L7.77024 7.93565L7.77024 7.937Z'
            fill='#0F62FE'
            stroke='#0F62FE'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export default SelectedTeeth;
