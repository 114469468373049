import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import { persist, createJSONStorage } from 'zustand/middleware';

export const useProcess = create((set, get) => ({
  treatmentProcess: 'Preparation',
  treatmentPhase: (context) => set({ treatmentProcess: context }),
}));

//! persist 사용법. 하지만 여기서는 Persist가 필요가 없다.
// export const useProcess = create(
//   persist(
//     (set, get) => ({
//       treatmentProcess: 'preparation',
//       treatmentPhase: (context) => set({ treatmentProcess: context }),
//     }),
//     {
//       name: 'my-app-state',
//       storage: createJSONStorage(() => localStorage),
//     }
//   )
// );
