import React from 'react';
import styled from 'styled-components/macro';

import { useDisplayMethods } from 'modules//stores/display';

import { LabeledBox } from 'components/dev/ui/panels';
import { ButtonSet } from 'components/dev/ui/buttons';

const MeshManager = styled(LabeledBox)`
    padding: 20px;
    &:before { content: 'Scan mesh manager'; }
`;

const Uploaders = styled(LabeledBox)`  
    &:before { content: 'Upload scans'; }
`;

const Loaders = styled(LabeledBox)`  
    &:before { content: 'Load scans'; }
`;

const Hint = styled.div`
  font-size: 10px;
  text-align: center;
  color: #919191;
  width: 125px;
  margin-top: 6px;
  a { color: #7daab9; }
`;

const MeshManagerPanel = ({ }) => {

    const { showMeshListDialog, showUploader } = useDisplayMethods();

    const upload = (type) => {
        showUploader(true, { type });
    };

    const load = (type) => {
        showMeshListDialog(true, { type });
    };

    return (
        <MeshManager>
            <Uploaders>
                <ButtonSet width={125}>
                    <button disabled onClick={() => upload('maxilary')}>Upload maxillary</button>
                    <button disabled onClick={() => upload('mandibular')}>Upload mandibular</button>
                    <Hint>For now, use the Strapi <a href="http://localhost:1337/admin/plugins/upload">Media Library Uploader</a></Hint>
                </ButtonSet>
            </Uploaders>
            <Loaders>
                <ButtonSet width={125}>
                    <button onClick={() => load('maxilary')}>Load maxillary</button>
                    <button onClick={() => load('mandibular')}>Load mandibular</button>
                </ButtonSet>
            </Loaders>
        </MeshManager>
    );
};

export default MeshManagerPanel;