import { PBRMaterial, ShaderMaterial, StandardMaterial, Effect, Color3, SSAORenderingPipeline, SSAO2RenderingPipeline, DefaultRenderingPipeline } from '@babylonjs/core';

// a library of useful Babylon JS materials

export const makeGumsMaterial = (scene, options={}) => {
    const {
        name = "gumsMaterial",
        albedoColo = new Color3(0.7, 0.35, 0.3),
        tintColor = new Color3(0.7, 0.35, 0.3),
    } = options;
    const gumsMaterial = new PBRMaterial(name, scene);

    // Set the base color and roughness of the material
    gumsMaterial.albedoColor = albedoColo;
    gumsMaterial.metallic = 0.05;
    gumsMaterial.roughness = 0.5;

    // Set the translucency of the material
    gumsMaterial.subSurface.isTranslucencyEnabled = true;
    gumsMaterial.subSurface.tintColor = tintColor;
    gumsMaterial.subSurface.thickness = 0.5;
    gumsMaterial.subSurface.minimumThickness = 0.02;
    gumsMaterial.subSurface.maximumThickness = 0.5;
    gumsMaterial.subSurface.scatteringIntensity = 0.5;
    gumsMaterial.subSurface.refractionIntensity = 0.5;

    // Set the subsurface scattering profile to simulate the appearance of human gums
    gumsMaterial.subSurface.useIntensityAsIntensityScale = false;
    gumsMaterial.subSurface.useAlbedoToTintSubsurface = true;
    gumsMaterial.subSurface.radius = 0.75;
    gumsMaterial.subSurface.intensity = 1.0;
    gumsMaterial.subSurface.tintColorAtDistance = 0.5;

    return gumsMaterial;
};

export const skinMaterialMaker = (scene, options={}) => {
    
    
    const skinMaterial = new PBRMaterial("skinMaterial", scene);

    // Set the base color and roughness of the material
    skinMaterial.albedoColor = new Color3(0.91, 0.67, 0.52);
    skinMaterial.metallic = 0.05;
    skinMaterial.roughness = 0.5;

    // Set the subsurface scattering properties to simulate skin appearance
    skinMaterial.subSurface.isTranslucencyEnabled = true;
    skinMaterial.subSurface.tintColor = new Color3(1, 0.9, 0.6);
    skinMaterial.subSurface.thickness = 0.5;
    skinMaterial.subSurface.minimumThickness = 0.02;
    skinMaterial.subSurface.maximumThickness = 0.5;
    skinMaterial.subSurface.scatteringIntensity = 1.0;
    skinMaterial.subSurface.refractionIntensity = 1.0;
    
    
    var simpleMateral = new StandardMaterial("boxMaterail", scene);
    simpleMateral.diffuseColor = new Color3(0.9,0.9,0.9);
    simpleMateral.specularPower = 8;
    simpleMateral.specularColor = new Color3(0.3,0.3,0.3);

    return skinMaterial;
};

export const makeMaterialLibrary = (scene) => {
    // build set of useful materials for the scene
    const redMaterial = new StandardMaterial('redMaterial', scene);
    redMaterial.diffuseColor = new Color3(1, 0, 0);
    redMaterial.emissiveColor = Color3.FromHexString('#292929');

    const blueMaterial = new StandardMaterial('blueMaterial', scene);
    blueMaterial.diffuseColor = new Color3(0, 0, 1);

    const greenMaterial = new StandardMaterial('greenMaterial', scene);
    greenMaterial.diffuseColor = new Color3(0, 1, 0);
    greenMaterial.emissiveColor = Color3.FromHexString('#292929');

    const darkRedMaterial = new StandardMaterial('darkRedMaterial', scene);
    darkRedMaterial.diffuseColor = Color3.FromHexString('#9C0A0A')

    const yellowMaterial = new StandardMaterial('yellowMaterial', scene);
    yellowMaterial.diffuseColor = Color3.FromHexString('#FFEA7D')
    yellowMaterial.emissiveColor = Color3.FromHexString('#292929');

    const skinMaterial = skinMaterialMaker(scene);

    const gumsMaterial = makeGumsMaterial(scene);

    return {
        redMaterial,
        blueMaterial,
        greenMaterial,
        darkRedMaterial,
        yellowMaterial,
        skinMaterial,
        gumsMaterial,
    }
}

export const setupSSAO = (scene) => {
    // add ambient occlusion pipeline
    // Create SSAO and configure all properties
    const ssaoRatio = {
        ssaoRatio: 0.5, // Ratio of the SSAO post-process, in a lower resolution
        combineRatio: 1.0 // Ratio of the combine post-process (combines the SSAO and the scene)
    };

    const ssao = new SSAORenderingPipeline("ssao", scene, ssaoRatio);
    ssao.fallOff = 0.000001;
    ssao.area = 1;
    ssao.radius = 0.0001;
    ssao.totalStrength = 1.0;
    ssao.base = 0.5;

    const camera = scene.cameras[0];

    // Attach camera to the SSAO render pipeline
    scene.postProcessRenderPipelineManager.attachCamerasToRenderPipeline("ssao", camera);
    scene.postProcessRenderPipelineManager.enableEffectInPipeline("ssao", ssao.SSAOCombineRenderEffect, camera);

    // Manage SSAO
    let isAttached = true;
    window.addEventListener("keydown", function (evt) {
        // draw SSAO with scene when pressed "1"
        if (evt.keyCode === 49) {
            if (!isAttached) {
                isAttached = true;
                scene.postProcessRenderPipelineManager.attachCamerasToRenderPipeline("ssao", camera);
            }
            scene.postProcessRenderPipelineManager.enableEffectInPipeline("ssao", ssao.SSAOCombineRenderEffect, camera);
        }
            // draw without SSAO when pressed "2"
        else if (evt.keyCode === 50) {
            isAttached = false;
            scene.postProcessRenderPipelineManager.detachCamerasFromRenderPipeline("ssao", camera);
        }
            // draw only SSAO when pressed "2"
        else if (evt.keyCode === 51) {
            if (!isAttached) {
                isAttached = true;
                scene.postProcessRenderPipelineManager.attachCamerasToRenderPipeline("ssao", camera);
            }
            scene.postProcessRenderPipelineManager.disableEffectInPipeline("ssao", ssao.SSAOCombineRenderEffect, camera);
        }
    });
};

export const setupSSAO2 = (scene) => {
    var ssao = new SSAO2RenderingPipeline("ssao2", scene, {
        ssaoRatio: 0.5, // Ratio of the SSAO post-process, in a lower resolution
        blurRatio: 1 // Ratio of the combine post-process (combines the SSAO and the scene)
    });
    ssao.radius = 8;
    ssao.totalStrength = 0.9;
    ssao.expensiveBlur = true;
    ssao.samples = 16;
    ssao.maxZ = 100;

    const camera = scene.cameras[0];

    scene.postProcessRenderPipelineManager.attachCamerasToRenderPipeline("ssao2", camera)

    var defaultpipeline = new DefaultRenderingPipeline("default", true, scene, [camera]);
    defaultpipeline.fxaaEnabled = true;

    // Manage SSAO
    let isAttached = true;
    window.addEventListener("keydown", function (evt) {
        // draw SSAO with scene when pressed "1"
        if (evt.keyCode === 49) {
            if (!isAttached) {
                isAttached = true;
                scene.postProcessRenderPipelineManager.attachCamerasToRenderPipeline("ssao2", camera);
            }
            scene.postProcessRenderPipelineManager.enableEffectInPipeline("ssao2", ssao.SSAOCombineRenderEffect, camera);
        }
            // draw without SSAO when pressed "2"
        else if (evt.keyCode === 50) {
            isAttached = false;
            scene.postProcessRenderPipelineManager.detachCamerasFromRenderPipeline("ssao2", camera);
        }
            // draw only SSAO when pressed "2"
        else if (evt.keyCode === 51) {
            if (!isAttached) {
                isAttached = true;
                scene.postProcessRenderPipelineManager.attachCamerasToRenderPipeline("ssao2", camera);
            }
            scene.postProcessRenderPipelineManager.disableEffectInPipeline("ssao2", ssao.SSAOCombineRenderEffect, camera);
        }
    });
};

    

// the following does not work (from GPT-4)
Effect.ShadersStore['customShader'] = `
precision highp float;

// Uniforms
uniform mat4 viewProjection;
uniform mat4 world;
uniform vec3 cameraPosition;
uniform vec3 lightPosition;

// Attributes
attribute vec3 position;
attribute vec3 normal;

// Varying
varying vec3 vPositionW;
varying vec3 vNormalW;

void main() {
  // Transform position and normal to world space
  vec4 positionW = world * vec4(position, 1.0);
  vec4 normalW = world * vec4(normal, 0.0);
  normalW = normalize(normalW);

  // Calculate ambient occlusion using a simple dot product
  float ambientOcclusion = dot(normalW.xyz, normalize(lightPosition - positionW.xyz));

  // Pass variables to the fragment shader
  vPositionW = positionW.xyz;
  vNormalW = normalW.xyz;

  // Output final position
  gl_Position = viewProjection * positionW;
}
`;
// console.log(Effect.ShadersStore);

export const makeAOMaterial = (scene, options={}) => {
    const customMaterial = new ShaderMaterial("customMaterial", scene, {
        vertex: "customShader",
        fragment: "customShader",
    }, {
        attributes: ["position", "normal"],
        uniforms: ["world", "viewProjection", "cameraPosition", "lightPosition"],
    });

    // Set the color and emissive color of the material
    customMaterial.setColor3("color", 0.8, 0.8, 0.8);
    customMaterial.setColor3("emissiveColor", 0.0, 0.0, 0.0);

    // Set the light position and camera position uniforms
    scene.registerBeforeRender(function() {
      customMaterial.setVector3("lightPosition", scene.lights[0].position);
      customMaterial.setVector3("cameraPosition", scene.activeCamera.position);
    });

    return customMaterial;
};
