import React from 'react';

const Check = () => (
    <svg
        width='12'
        height='9'
        viewBox='0 0 12 9'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M4.5 8.45505L0 3.95505L0.707 3.24805L4.5 7.04055L11.293 0.248047L12 0.955047L4.5 8.45505Z'
            fill='#F4F4F4'
        />
    </svg>
);

export default Check;
