import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';
import SideMenu from 'components/icons/SideMenu';
import { InnerButtonContainer } from 'components/ui/containers';
import Information from 'components/icons/Information';
import { RectangularButton } from 'components/ui/webButtons';

import Preview from './Preview';
import { zIndex } from 'modules/defines/styles';

const BoardBodyContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
`;

const GridContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: ${(props) => (props.width ? props.width : '100%')};
    height: ${(props) => (props.type === 'list' ? '100%' : '')};
    background-color: ${palette.primary.gray};
    ${(props) =>
        !props.patientList ||
        (props.patientList.length === 0 &&
            `
        align-items: center;
        justify-content: center;
    `)}
`;

const GridRow = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    border-left: ${(props) =>
        props.type === 'preview' && `1px solid ${palette.gray[2]}`};
    border-bottom: 1px solid ${palette.gray[2]};
    height: 10%;
    ${(props) =>
        props.backgroundColor
            ? `background-color: ${palette.gray[5]}; 
            :before {
                    content: '';
                    position: absolute;
                    left: 0;
                        top: 0;
                        bottom: 0;
                        width: 3px;
                        background-color: ${palette.primary.blue};
                        }
                } `
            : `background-color: ${palette.primary.gray}`};
    ${(props) =>
        props.type === 'preview' &&
        `
        justify-content: space-between;
        padding-right: 16px;
        `};
    ${(props) =>
        props.type !== 'preview' &&
        `
        cursor: pointer;
        :hover {
        background-color: ${palette.gray[4]};
        }
        :active {
                background-color: ${palette.gray[5]};
                :before {
                    content: '';
                    position: absolute;
                    left: 0;
                        top: 0;
                        bottom: 0;
                        width: 3px;
                        background-color: ${palette.primary.blue};
                        }
                }
      `};
`;

const PreviewBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90%;
    border-left: 1px solid ${palette.gray[2]};
`;

const SideMenuWrapper = styled.div`
    width: 150px;
    height: 192px;
    position: absolute;
    left: 94%;
    top: 100%;
    z-index: ${zIndex.popupDialog};
    border: 1px solid ${palette.gray[1]};
`;

const MenuItem = styled.button`
    height: 48px;
    width: 100%;
    color: ${palette.gray[0]};
    padding: 0 16px;
    display: flex;
    font-size: 14px;
    align-items: center;
    border-bottom: 1px solid ${palette.gray[2]};
    background: ${palette.gray[5]};

    :hover {
        background: ${palette.gray[4]};
    }
`;

export const GridCell = styled.div`
    display: flex;
    padding-left: ${(props) =>
        props.paddingLeft ? props.paddingLeft : '16px'};
    justify-content: ${(props) => (props.justifyContent ? 'center' : '')};
    width: ${(props) => props.w};
    height: 100%;
    font-size: 14px;
    color: ${palette.gray[3]};
    align-items: center;
    background-color: ${(props) =>
        props.backgroundColor ? palette.gray[8] : ''};
`;

const SIDE_MENU = ['Edit', 'Delete', 'Share', 'Export'];

// ! 파일을 받아서 올리는 거랑 보이는 것을 어떻게 할지 고민해보기
// TODO: 파일 있을 때 슬라이드 및 버튼 컬러 변경
const BoardBody = ({ patientList, onShare, onModal }) => {
    const [listItems, setListItems] = useState(
        patientList.map((el) => ({ ...el, showMenu: false }))
    );
    const modalRef = useRef(null);

    const handleToggleMenu = (e, idx) => {
        e.stopPropagation();
        const newList = listItems.map((item, index) => ({
            ...item,
            showMenu: index === idx ? !item.showMenu : false,
        }));
        setListItems(newList);
    };

    const handleSideMenuClick = (e, idx, i) => {
        // idx : side menu index
        // i : patient list index
        if (idx === 0) {
            onModal('edit');
        } else if (idx === 2) {
            onShare();
        }
        // TODO: delete, export
        handleToggleMenu(e, i);
    };

    const handleOutsideClick = useCallback(
        (event) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target) &&
                !event.target.classList.contains('sideMenuButton')
            ) {
                const newList = listItems.map((item) => ({
                    ...item,
                    showMenu: false,
                }));
                setListItems(newList);
            }
        },
        [listItems]
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [handleOutsideClick]);

    const handleClickRow = (e, idx) => {
        const newList = listItems.map((item, index) => ({
            ...item,
            preview: index === idx ? !item.preview : false,
        }));

        setListItems(newList);
    };

    const handleDbClickRow = (e) => {
        console.log(e);
    };

    return (
        <BoardBodyContainer>
            <GridContainer type='list' width='63%' patientList={patientList}>
                {patientList && patientList.length !== 0 ? (
                    patientList.map((el, i) => (
                        <GridRow
                            onClick={(e) => handleClickRow(e, i)}
                            onDoubleClick={handleDbClickRow}
                            key={i}
                            backgroundColor={listItems[i].preview}
                        >
                            <GridCell w={'16%'}>{el.patientID}</GridCell>
                            <GridCell w={'9.5%'}>{el.gender}</GridCell>
                            <GridCell w={'17%'}>{el.birthDate}</GridCell>
                            <GridCell w={'22.5%'}>{el.name}</GridCell>
                            <GridCell w={'29%'}>{el.fileName}</GridCell>
                            <GridCell
                                w={'6%'}
                                justifyContent='center'
                                paddingLeft='0'
                                backgroundColor={listItems[i].showMenu}
                                onClick={(e) => handleToggleMenu(e, i)}
                            >
                                <InnerButtonContainer
                                    className='sideMenuButton'
                                    width='100%'
                                    height='100%'
                                    display='flex'
                                    justifyContent='center'
                                    alignItems='center'
                                    marginTop='0'
                                >
                                    <SideMenu />
                                </InnerButtonContainer>

                                {listItems[i].showMenu && (
                                    <SideMenuWrapper ref={modalRef}>
                                        {SIDE_MENU.map((d, idx) => (
                                            <MenuItem
                                                key={idx}
                                                onClick={(e) =>
                                                    handleSideMenuClick(
                                                        e,
                                                        idx,
                                                        i
                                                    )
                                                }
                                            >
                                                {d}
                                            </MenuItem>
                                        ))}
                                    </SideMenuWrapper>
                                )}
                            </GridCell>
                        </GridRow>
                    ))
                ) : (
                    <>
                        <div>No Result, please add new patient</div>
                        <RectangularButton
                            width='157px'
                            height='48px'
                            backgroundColor={palette.primary.blue}
                            alignItems='center'
                            style={{ marginTop: '15px' }}
                        >
                            Add new patient
                        </RectangularButton>
                    </>
                )}
            </GridContainer>
            <GridContainer type='list' width='37%'>
                <GridRow type='preview'>
                    <GridCell>
                        <Information />
                        <p style={{ marginLeft: '10px' }}>
                            No result, please add new files
                        </p>
                    </GridCell>
                    <RectangularButton
                        width='16%'
                        height='50%'
                        backgroundColor={palette.primary.blue}
                        alignItems='center'
                        disabled={!patientList || patientList.length === 0}
                        onClick={onShare}
                    >
                        Share
                    </RectangularButton>
                </GridRow>
                <Preview />
            </GridContainer>
        </BoardBodyContainer>
    );
};

export default BoardBody;
