import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { shallow } from 'zustand/shallow';

import '@babylonjs/loaders/glTF';

import { useMeshStoreMethods } from 'modules//stores/mesh-manager';
import { useDisplay } from 'modules//stores/display';
import { useSceneManager } from 'modules//stores/scene-manager';

import PopupDialog from 'components/dev/ui/PopupDialog';
import { httpAPI } from 'modules//apis/http';
import { APIServer, apiPaths } from 'modules//defines/paths';
import {useDisplayAndMethods} from "../../../modules/stores/display";

const MeshTable = styled.table`
`;

const MeshRow = styled.tr`
    cursor: pointer;

    &:hover {
        background-color: #edfaff;
    }
`;

const MeshColumn = styled.td`
  text-align: right;
  padding: 2px 6px;
`;

const MeshListDialog = ({ }) => {
    const [listing, setListing] = useState([]);
    const [meshListDialog, { showMeshListDialog }] = useDisplayAndMethods(state => state.meshListDialog);
    const { downloadMesh } = useMeshStoreMethods();
    const scene = useSceneManager(state => state.scene);
    const materials = useSceneManager(state => state.materials);

    useEffect(() => {
        httpAPI(APIServer, apiPaths.listMeshes, { method: 'GET' })
            .then(response => setListing(response.data));
    }, []);

    const loadMesh = (m) => {
        const [, urlPath, filename] = m.url.match(/(.*\/)(\w+)/);
        downloadMesh(urlPath, `${filename}${m.ext}`, materials.skinMaterial, scene, meshListDialog.context);
        showMeshListDialog(false);
    };

    return (
        <PopupDialog title="Mesh select" onClose={() => showMeshListDialog(false)}>
            <MeshTable>
                <tbody>
                    { listing.map(m => (
                        <MeshRow key={m.hash} onClick={() => loadMesh(m)}>
                            <MeshColumn style={{ textAlign: 'left' }}>{m.name}</MeshColumn>
                            <MeshColumn>{m.createdAt.replace('T', ' ').replace(/\.\d\d\dZ/i, '')}</MeshColumn>
                            <MeshColumn>{m.size.toString().replace(/\..*/, '')}MB</MeshColumn>
                        </MeshRow>
                    ))}
                </tbody>
            </MeshTable>
        </PopupDialog>
    )
}

export default MeshListDialog;