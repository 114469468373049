import React, { useRef, useState, useMemo, useEffect } from 'react';

import { Vector3, Color3, MeshBuilder, StandardMaterial, Mesh } from '@babylonjs/core';
import { useBeforeRender, useClick,  useHover, useScene } from 'react-babylonjs';
import { STLExport } from '@babylonjs/serializers';

// Collect the methods for MeshWriter
import { Vector2 } from "@babylonjs/core/Maths/math.vector";
import { Path2, Curve3 } from "@babylonjs/core/Maths/math.path";
import { CSG } from "@babylonjs/core/Meshes/csg";
import { PolygonMeshBuilder} from "@babylonjs/core/Meshes/polygonMesh";
import { SolidParticleSystem } from "@babylonjs/core/Particles/solidParticleSystem";
// Methods assembled, onward!
import MeshWriter from "meshwriter";

import Workspace3D from 'containers/dev/Workspace3D';

// Put them in an object
const meshWriterMethods = {Vector2, Vector3, Path2, Curve3, Color3, SolidParticleSystem, PolygonMeshBuilder, CSG, StandardMaterial, Mesh};

const DefaultScale = new Vector3(1, 1, 1);
const BiggerScale = new Vector3(1.25, 1.25, 1.25);

const SpinningBox = (props) => {
    // access Babylon scene objects with same React hook as regular DOM elements
    const boxRef = useRef(null)

    const [clicked, setClicked] = useState(false)
    useClick((args) => {
        setClicked((clicked) => !clicked);
    }, boxRef);

    const [hovered, setHovered] = useState(false)
    useHover(
        () => setHovered(true),
        () => setHovered(false),
        boxRef
    )

    // This will rotate the box on every Babylon frame.
    const rpm = 5
    useBeforeRender((scene) => {
        if (boxRef.current) {
            // Delta time smoothes the animation.
            var deltaTimeInMillis = scene.getEngine().getDeltaTime()
            boxRef.current.rotation.y +=
                (rpm / 60) * Math.PI * 2 * (deltaTimeInMillis / 1000)
        }
    })

    return (
        <box
            name={props.name}
            ref={boxRef}
            size={2}
            position={props.position}
            scaling={clicked ? BiggerScale : DefaultScale}
        >
            <standardMaterial
                name={`${props.name}-mat`}
                diffuseColor={hovered ? props.hoveredColor : props.color}
                specularColor={Color3.Black()}
            />
        </box>
    )
};

const Text = ({ text, color="white", fontSize=32, thickness=10 }) => {
    const scene = useScene();

    useEffect(() => {
        if (!scene) return;
        // const textMesh = useMemo(() => {
        // const textMesh = () => {
            const Writer = MeshWriter(scene, { scale: 0.25, defaultFont: "Arial", methods: meshWriterMethods });
            const textMesh = new Writer(text, {
                "font-family": "Arial",
                "letter-height": fontSize,
                "letter-thickness": thickness,
                color,
                anchor: "center",
                colors: {
                    diffuse: "#F0F0F0",
                    specular: "#000000",
                    ambient: "#F0F0F0",
                    emissive: "#ff00f0",
                },
                position: {
                    x: 0,
                    y: 10,
                    z: 0,
                },
            });
        // };
        return () => textMesh.dispose();
    }, [scene, text]);

    return null;
};

const createStarPolygon = (numPoints, innerRadius, outerRadius) => {
    const points = [];
    const deltaAngle = (2 * Math.PI) / numPoints;

    for (let i = 0; i < numPoints; i++) {
        const angleOuter = i * deltaAngle;
        const angleInner = angleOuter + deltaAngle / 2;

        const outerPoint = new Vector3(
            outerRadius * Math.cos(angleOuter),
            outerRadius * Math.sin(angleOuter),
            0
        );

        const innerPoint = new Vector3(
            innerRadius * Math.cos(angleInner),
            innerRadius * Math.sin(angleInner),
            0
        );

        points.push(outerPoint);
        points.push(innerPoint);
    }

    points.push(points[0]); // Close the polygon by repeating the first point

    return points;
};

const starPolygon = createStarPolygon(5, 2, 4);
const extrusionPath = [
    new Vector3(0, 0, 0),
    new Vector3(0, 0, 2),
];

const SphereWithHole = () => {
    const scene = useScene();

    const resultMesh = useMemo(() => {
        if (!scene) return;

        const sphere = MeshBuilder.CreateSphere("sphere", { segments: 32, diameter: 2 }, scene);
        // sphere.position = new Vector3(-2, 0, 0);

        const cylinder = MeshBuilder.CreateCylinder("cylinder", {
            height: 2,
            diameterTop: 0.5,
            diameterBottom: 0.5,
            tessellation: 32
        }, scene);
        // cylinder.position = new Vector3(-2, 0, 0);

        const extrudedStar = MeshBuilder.ExtrudeShape("extrudedStar", {
            shape: starPolygon,
            path: extrusionPath,
            sideOrientation: Mesh.DOUBLESIDE,
            updatable: false,
            cap: Mesh.CAP_ALL,
        }, scene);
        extrudedStar.scaling = new Vector3(0.15, 0.15, 1.2);
        extrudedStar.position = new Vector3(0, 0, 0.5);

        const material = new StandardMaterial("starMaterial", scene);
        material.diffuseColor = new Color3(1, 1, 0.6);
        extrudedStar.material = material;

        const sphereCSG = CSG.FromMesh(sphere);
        const cylinderCSG = CSG.FromMesh(extrudedStar);
        const resultCSG = sphereCSG.subtract(cylinderCSG);

        const resultMesh = resultCSG.toMesh("result", material, scene);
        // resultMesh.material.wireframe = true;

        sphere.dispose();
        cylinder.dispose();
        extrudedStar.dispose();

        return resultMesh;
    }, [scene]);

    return <mesh name="csgMesh" fromInstance={resultMesh}/>;
};

const BabylonTest = () => (
    <Workspace3D title="Babylon tests">
        <SpinningBox
            name="left"
            position={new Vector3(-2, 0, 0)}
            color={Color3.FromHexString('#EEB5EB')}
            hoveredColor={Color3.FromHexString('#C26DBC')}
        />
        <SpinningBox
            name="right"
            position={new Vector3(2, 0, 0)}
            color={Color3.FromHexString('#C8F4F9')}
            hoveredColor={Color3.FromHexString('#3CACAE')}
        />
        <Text text="Hello world" fontSize={2} thickness={2} />
        <SphereWithHole />
    </Workspace3D>
);

export default BabylonTest;