import React from 'react';

const Export = () => (
    <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <rect
            width='20'
            height='20'
            fill='white'
            style={{ mixBlendMode: 'multiply' }}
        />
        <path
            d='M16.25 15V17.5H3.75V15H2.5V17.5L2.50475 17.4969C2.50416 17.661 2.53591 17.8236 2.59818 17.9754C2.66045 18.1271 2.75203 18.2652 2.86768 18.3815C2.98333 18.4979 3.12078 18.5904 3.27217 18.6536C3.42357 18.7168 3.58594 18.7496 3.75 18.75H16.25C16.5815 18.75 16.8995 18.6183 17.1339 18.3839C17.3683 18.1495 17.5 17.8315 17.5 17.5V15H16.25Z'
            fill='#C6C6C6'
        />
        <path
            d='M3.75 7.5L4.63187 8.37812L9.375 3.64062V15H10.625V3.64062L15.3694 8.37812L16.25 7.5L10 1.25L3.75 7.5Z'
            fill='#C6C6C6'
        />
    </svg>
);

export default Export;
