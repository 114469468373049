import React from 'react';
import styled from 'styled-components/macro';

import { useDisplay } from 'modules//stores/display';

import MeshListDialog from 'components/dev/PopupDialogs/MeshListDialog';

const PopupsWrapper = styled.div`
`;

const PopupDialogs = ({ }) => {

    const meshListDialog = useDisplay(state => state.meshListDialog);

    return (
        <PopupsWrapper>
            { meshListDialog.show && <MeshListDialog /> }
        </PopupsWrapper>
    );
};

export default PopupDialogs;