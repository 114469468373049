import React, { useEffect, useState } from 'react';
import { fonts, palette } from 'modules/defines/styles';
import styled from 'styled-components/macro';
import RadioButton from 'components/buttons/RadioButton';

const FormItemWrapper = styled.div`
    width: 100%;
    height: 72px;

    & + & {
        margin-left: 32px;
    }
`;
const FormInput = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    padding: 0 16px;
    ${(props) =>
        props.type !== 'gender' && props.focus === 'true'
            ? 'border: 2px solid white'
            : `border-bottom: 1px solid ${palette.gray[1]}`};

    input {
        width: 100%;
        height: 48px;
        color: ${palette.gray[0]};
        font-size: 14px;
        font-family: ${fonts.ibm};
        text-indent: 4px;
        line-height: 18px;

        ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        ::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        :disabled {
            color: ${palette.gray[1]};
        }
        :read-only {
            color: ${palette.gray[1]};
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`;

const Label = styled.div`
    height: 20px;
    font-size: 13px;
    color: white;
    line-height: 20px;
    margin-bottom: 2px;
    font-weight: 200;
    letter-spacing: 0.32px;
`;

const GENDER_TYPE = {
    m: { code: 0, text: 'Male' },
    f: { code: 1, text: 'Female' },
};

const FormItem = ({
    type,
    label,
    value,
    onBlur,
    onChange,
    placeholder,
    disabled,
    name,
    onSelect,
}) => {
    const [formValue, setFormValue] = useState(value);
    const [focus, setFocus] = useState(false);

    useEffect(() => {
        try {
            setFormValue(value);
        } catch (error) {
            console.log(error);
        }
    }, [value]);

    const handleBlur = (e) => {
        if (onBlur) onBlur(e);
        setFocus(false);
    };

    const handleFocus = () => {
        setFocus(true);
    };

    const handleChange = (e) => {
        if (onChange) {
            onChange(e);
        }
    };

    const handleSelect = (e) => {
        if (onSelect) onSelect(e);
    };

    if (type === 'gender') {
        return (
            <FormItemWrapper type={type}>
                <Label>{label} </Label>
                <FormRow>
                    {Object.entries(GENDER_TYPE).map((d, i) => (
                        <RadioButton
                            key={i}
                            value={d[1].code}
                            name={name}
                            checked={value === d[1].code}
                            onSelect={handleSelect}
                        >
                            {d[1].text}
                        </RadioButton>
                    ))}
                </FormRow>
            </FormItemWrapper>
        );
    } else {
        return (
            <FormItemWrapper>
                <Label>{label} </Label>
                <FormInput type={type} focus={focus.toString()}>
                    <input
                        name={name}
                        type='text'
                        value={formValue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        autoComplete='off'
                        placeholder={placeholder}
                    />
                </FormInput>
            </FormItemWrapper>
        );
    }
};

export default FormItem;

export const FormRow = styled.div`
    display: flex;
    /* flex-wrap: wrap; */
    width: 100%;
    & + & {
        margin-top: 32px;
    }
`;

export const FormWrapper = styled.div`
    padding: 0 16px;
`;
