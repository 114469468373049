import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import styled from 'styled-components/macro';

import { paths } from 'modules//defines/paths';

const AuthWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AuthLayout = ({}) => {
  return (
    <AuthWrapper>
      <h1>Ashley 2 Authentication</h1>
      <Outlet />
      {/* <PopupDialogs /> */}
    </AuthWrapper>
  );
};

export default AuthLayout;
