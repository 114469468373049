import axios from 'axios';

export const defaultOptions = {
    method: 'POST',
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
};

export const httpAPI = function (host, path, options={}) {
    const callOptions = { ...defaultOptions, ...options };
    return axios(host + path, callOptions);
};
