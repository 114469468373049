import React from 'react';

const OrientationTeeth = () => (
    <svg
        width='20'
        height='16'
        viewBox='0 0 20 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M14.4343 8.03991C14.7574 8.03721 15.061 8.02104 15.2649 8.00756C15.5796 7.98734 15.8805 8.16795 16.0206 8.48199C16.1329 8.73538 16.2175 9.11682 16.2036 9.6883C16.2036 9.76782 16.1925 11.4984 15.1789 12.4163C14.8933 12.6751 14.5258 12.7964 14.157 12.7964L14.1182 12.7964C13.7493 12.7964 13.3819 12.6751 13.0963 12.4163C12.0827 11.4984 12.0716 9.76647 12.0716 9.6883C12.0563 9.16938 12.1145 8.80817 12.2005 8.55747C12.3253 8.19895 12.6539 7.98195 12.9978 8.00082C13.2294 8.0143 13.5774 8.03182 13.8408 8.03856L14.4343 8.03856L14.4343 8.03991Z'
            stroke='white'
            strokeWidth='0.7'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M10.2116 8.03991C10.5347 8.03721 10.8384 8.02104 11.0422 8.00756C11.357 7.98734 11.6579 8.16795 11.7979 8.48199C11.9102 8.73538 11.9948 9.11682 11.9809 9.6883C11.9809 9.76782 11.9698 11.4984 10.9562 12.4163C10.6706 12.6751 10.3032 12.7964 9.93433 12.7964L9.89551 12.7964C9.52668 12.7964 9.15923 12.6751 8.87359 12.4163C7.86 11.4984 7.84891 9.76647 7.84891 9.6883C7.83366 9.16938 7.89189 8.80817 7.97786 8.55747C8.10265 8.19895 8.43127 7.98195 8.77515 8.00082C9.00671 8.0143 9.35474 8.03182 9.61819 8.03856L10.2116 8.03856L10.2116 8.03991Z'
            stroke='white'
            strokeWidth='0.7'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M6.08958 8.03991C6.41265 8.03721 6.71632 8.02104 6.92014 8.00756C7.2349 7.98734 7.53579 8.16795 7.67583 8.48199C7.78815 8.73538 7.87273 9.11682 7.85886 9.6883C7.85886 9.76782 7.84777 11.4984 6.83418 12.4163C6.54854 12.6751 6.18109 12.7964 5.81226 12.7964L5.77344 12.7964C5.40461 12.7964 5.03716 12.6751 4.75153 12.4163C3.73793 11.4984 3.72684 9.76647 3.72684 9.6883C3.71159 9.16938 3.76982 8.80817 3.85579 8.55747C3.98058 8.19895 4.3092 7.98195 4.65308 8.00082C4.88464 8.0143 5.23267 8.03182 5.49612 8.03856L6.08958 8.03856L6.08958 8.03991Z'
            stroke='white'
            strokeWidth='0.7'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M16.2048 10.0872C16.704 10.0872 17.0797 10.5293 16.9854 11.0037L16.6873 12.5321C16.4086 13.9635 15.1205 15 13.6216 15L6.37946 15C4.88056 15 3.59242 13.9635 3.31233 12.5335L3.01422 11.005C2.92409 10.5441 3.27351 10.1141 3.7505 10.0899'
            stroke='white'
            strokeWidth='0.7'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M3.7952 5.91283C3.29603 5.91283 2.92027 5.47074 3.01456 4.99631L3.31267 3.46787C3.59137 2.03648 4.87951 1 6.37841 1H13.6205C15.1194 1 16.4076 2.03648 16.6877 3.46652L16.9858 4.99496C17.0759 5.45592 16.7265 5.88587 16.2495 5.91013'
            stroke='white'
            strokeWidth='0.7'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M5.56569 7.96009C5.24262 7.96279 4.93896 7.97896 4.73513 7.99244C4.42038 8.01266 4.11949 7.83205 3.97944 7.51801C3.86713 7.26462 3.78255 6.88318 3.79641 6.3117C3.79641 6.23218 3.8075 4.50157 4.8211 3.5837C5.10674 3.32492 5.47418 3.20361 5.84301 3.20361H5.88184C6.25067 3.20361 6.61811 3.32492 6.90375 3.5837C7.91734 4.50157 7.92844 6.23353 7.92844 6.3117C7.94369 6.83061 7.88545 7.19183 7.79948 7.44253C7.67469 7.80105 7.34607 8.01805 7.0022 7.99918C6.77064 7.9857 6.4226 7.96818 6.15915 7.96144H5.56569V7.96009Z'
            stroke='white'
            strokeWidth='0.7'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M9.78835 7.96009C9.46528 7.96279 9.16161 7.97896 8.95779 7.99244C8.64303 8.01266 8.34214 7.83205 8.2021 7.51801C8.08978 7.26461 8.0052 6.88318 8.01907 6.3117C8.01907 6.23218 8.03016 4.50157 9.04375 3.5837C9.32939 3.32492 9.69684 3.20361 10.0657 3.20361H10.1045C10.4733 3.20361 10.8408 3.32492 11.1264 3.5837C12.14 4.50157 12.1511 6.23353 12.1511 6.3117C12.1663 6.83061 12.1081 7.19183 12.0221 7.44253C11.8973 7.80105 11.5687 8.01805 11.2249 7.99918C10.9933 7.9857 10.6453 7.96818 10.3818 7.96144H9.78835V7.96009Z'
            stroke='white'
            strokeWidth='0.7'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M13.9104 7.96009C13.5873 7.96279 13.2837 7.97896 13.0799 7.99244C12.7651 8.01266 12.4642 7.83205 12.3242 7.51801C12.2119 7.26462 12.1273 6.88318 12.1411 6.3117C12.1411 6.23218 12.1522 4.50157 13.1658 3.5837C13.4515 3.32492 13.8189 3.20361 14.1877 3.20361H14.2266C14.5954 3.20361 14.9628 3.32492 15.2485 3.5837C16.2621 4.50157 16.2732 6.23353 16.2732 6.3117C16.2884 6.83061 16.2302 7.19183 16.1442 7.44253C16.0194 7.80105 15.6908 8.01805 15.3469 7.99918C15.1154 7.9857 14.7673 7.96818 14.5039 7.96144H13.9104V7.96009Z'
            stroke='white'
            strokeWidth='0.7'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path d='M19.5 8L0.5 8' stroke='white' />
    </svg>
);
export default OrientationTeeth;
