import React from 'react';
import styled from 'styled-components/macro';

export const Panel = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    ${props => props.bordered && 'border: thin solid lightgray;'}
    border-radius: 2px;
    padding: 20px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    margin: 5px;
`;

export const LabeledBox = styled.div`
      position: relative;
      // width: calc(100% - 38px);
      font-size: 12px;
      padding: 12px;
      //white-space: nowrap;
      border: thin solid lightgray;
      border-radius: 5px;
    
      & + & {
        margin-top: 12px;
      }
    
      &:focus {
        outline-width: 1px;
        outline-color: lightblue;
      }
    
      &:before {
        position: absolute;
        left: 15px;
        top: -8px;
        font-size: 11px;
        background-color: white;
        padding: 0 4px;
      }
`;

