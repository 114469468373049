import React, { useEffect, useState } from 'react';
import PatientListComponent from 'components/PatientListComponent';
import Modal from 'components/common/Modal';
import PatientInfoForm from 'components/forms/PatientInfoForm';
import ShareFileForm from 'components/forms/ShareFileForm';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';
import { useDataExportStoreMethods } from 'modules/stores/dataExport';

const PatientTitle = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 9%;
    background-color: ${palette.primary.gray};
    padding-left: 16px;
    font-size: 20px;
`;

const PatientListContainer = () => {
    const [searchInput, setSearchInput] = useState('');
    const [resultInput, setResultInput] = useState('');
    const [settingDisabled, setSettingDisabled] = useState(false);
    const [isPatientModalOpen, setIsPatientModalOpen] = useState(false);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [maxillaryFile, setMaxillaryFile] = useState();
    const [mandibularFile, setMandibularFile] = useState();
    const [modalType, setModalType] = useState();
    const { setPathname } = useDataExportStoreMethods();

    useEffect(() => {
        setPathname(window.location.pathname);
    }, []);

    useEffect(() => {
        if (searchInput === resultInput) setSettingDisabled(false);
        else setSettingDisabled(true);
    }, [searchInput, resultInput]);

    // TODO : add new patient save function
    const handleSave = () => {
        console.log('save');
    };

    // TODO: share file
    const handleShare = () => {
        console.log('share');
    };

    const handlModalOpen = (type) => {
        setIsPatientModalOpen(!isPatientModalOpen);
        setModalType(type);
    };

    const handleFileChange = (e, type) => {
        console.log(e, type);
        const file = e.target.files[0];
        if (type === 'man') setMandibularFile(file);
        else setMaxillaryFile(file);
        // You can now do something with the selected file
        console.log(file);
    };

    return (
        <>
            <PatientTitle>Patient table</PatientTitle>
            <PatientListComponent
                setSearchInput={setSearchInput}
                searchInput={searchInput}
                setResultInput={setResultInput}
                settingDisabled={settingDisabled}
                onModal={handlModalOpen}
                onShare={() => setIsShareModalOpen(!isShareModalOpen)}
            />
            {isPatientModalOpen && (
                <Modal
                    title={
                        modalType === 'edit'
                            ? 'Edit patient info'
                            : 'Add new patient'
                    }
                    primaryText='Save'
                    secondaryText='Cancel'
                    iconType='delete'
                    onClose={() => setIsPatientModalOpen(!isPatientModalOpen)}
                    onClick={handleSave}
                >
                    <PatientInfoForm
                        onChange={handleFileChange}
                        maxillaryFile={maxillaryFile}
                        mandibularFile={mandibularFile}
                        setMaxillaryFile={setMaxillaryFile}
                        setMandibularFile={setMandibularFile}
                    />
                </Modal>
            )}

            {isShareModalOpen && (
                <Modal
                    title='Share this file'
                    primaryText='Share'
                    secondaryText='Cancel'
                    iconType='delete'
                    onClose={() => setIsShareModalOpen(!isShareModalOpen)}
                    onClick={handleShare}
                >
                    <ShareFileForm />
                </Modal>
            )}
        </>
    );
};

export default PatientListContainer;
