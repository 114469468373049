import React from 'react';
import { palette } from 'modules/defines/styles';
import DetailModal from 'components/ui/DetailModal';
import { RectangularButton } from 'components/ui/webButtons';
import Tooth18 from 'components/icons/teeth/Tooth18';
import Tooth11 from 'components/icons/teeth/Tooth11';
import Tooth12 from 'components/icons/teeth/Tooth12';
import Tooth13 from 'components/icons/teeth/Tooth13';
import Tooth14 from 'components/icons/teeth/Tooth14';
import Tooth15 from 'components/icons/teeth/Tooth15';
import Tooth16 from 'components/icons/teeth/Tooth16';
import Tooth17 from 'components/icons/teeth/Tooth17';
import Tooth21 from 'components/icons/teeth/Tooth21';
import Tooth22 from 'components/icons/teeth/Tooth22';
import Tooth23 from 'components/icons/teeth/Tooth23';
import Tooth24 from 'components/icons/teeth/Tooth24';
import Tooth25 from 'components/icons/teeth/Tooth25';
import Tooth26 from 'components/icons/teeth/Tooth26';
import Tooth27 from 'components/icons/teeth/Tooth27';
import Tooth28 from 'components/icons/teeth/Tooth28';
import Tooth38 from 'components/icons/teeth/Tooth38';
import Tooth31 from 'components/icons/teeth/Tooth31';
import Tooth32 from 'components/icons/teeth/Tooth32';
import Tooth33 from 'components/icons/teeth/Tooth33';
import Tooth34 from 'components/icons/teeth/Tooth34';
import Tooth35 from 'components/icons/teeth/Tooth35';
import Tooth36 from 'components/icons/teeth/Tooth36';
import Tooth37 from 'components/icons/teeth/Tooth37';
import Tooth41 from 'components/icons/teeth/Tooth41';
import Tooth42 from 'components/icons/teeth/Tooth42';
import Tooth43 from 'components/icons/teeth/Tooth43';
import Tooth44 from 'components/icons/teeth/Tooth44';
import Tooth45 from 'components/icons/teeth/Tooth45';
import Tooth46 from 'components/icons/teeth/Tooth46';
import Tooth47 from 'components/icons/teeth/Tooth47';
import Tooth48 from 'components/icons/teeth/Tooth48';
import {
    InnerButtonContainer,
    InnerContainers,
} from 'components/ui/containers';
import Plus from 'components/icons/Plus';
import Minus from 'components/icons/Minus';

const SegmentationMenu = () => {
    return (
        <>
            <DetailModal
                height='410px'
                backgroundColor={palette.primary.gray}
                borderBottom={`1px solid ${palette.gray[2]}`}
                justifyContent='flex-start'
                style={{ paddingBottom: '20px' }}
            >
                <InnerContainers
                    id='PreparationTitle'
                    height='21px'
                    fontSize='14px'
                    justifyContent='center'
                >
                    Segmentation
                </InnerContainers>
                <InnerContainers
                    fontSize='14px'
                    color={palette.gray[6]}
                    height='21px'
                >
                    Teeth Edit
                </InnerContainers>
                <InnerContainers
                    fontSize='14px'
                    color={palette.gray[6]}
                    height='292px'
                    style={{ position: 'relative' }}
                >
                    <Tooth11 />
                    <Tooth12 />
                    <Tooth13 />
                    <Tooth14 />
                    <Tooth15 />
                    <Tooth16 />
                    <Tooth17 />
                    <Tooth18 />
                    <Tooth21 />
                    <Tooth22 />
                    <Tooth23 />
                    <Tooth24 />
                    <Tooth25 />
                    <Tooth26 />
                    <Tooth27 />
                    <Tooth28 />
                    <Tooth31 />
                    <Tooth32 />
                    <Tooth33 />
                    <Tooth34 />
                    <Tooth35 />
                    <Tooth36 />
                    <Tooth37 />
                    <Tooth38 />
                    <Tooth41 />
                    <Tooth42 />
                    <Tooth43 />
                    <Tooth44 />
                    <Tooth45 />
                    <Tooth46 />
                    <Tooth47 />
                    <Tooth48 />
                </InnerContainers>
            </DetailModal>
            <DetailModal
                height='145px'
                backgroundColor={palette.primary.gray}
                padding='0'
            >
                <InnerContainers
                    height='105px'
                    style={{ padding: '20px 12px 12px' }}
                >
                    <div style={{ fontSize: '14px', color: palette.gray[6] }}>
                        Teeth assign/delete
                    </div>
                    <InnerButtonContainer type='menuButton' height='32px'>
                        <RectangularButton backgroundColor={palette.gray[1]}>
                            <Plus />
                        </RectangularButton>
                        <RectangularButton backgroundColor={palette.gray[1]}>
                            <Minus />
                        </RectangularButton>
                    </InnerButtonContainer>
                </InnerContainers>
                <InnerButtonContainer height='40px' style={{ marginTop: '0' }}>
                    <RectangularButton
                        backgroundColor={palette.primary.blue}
                        width='50%'
                        marginLeft='auto'
                        justifyContent='start'
                    >
                        Next step
                    </RectangularButton>
                </InnerButtonContainer>
            </DetailModal>
        </>
    );
};

export default SegmentationMenu;
