import React from 'react';
import styled from 'styled-components';
import { fonts } from 'modules/defines/styles';

const ModalContainer = styled.div`
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : 'inherit'};
    display: flex;
    flex-direction: ${(props) =>
        props.flexDirection ? props.flexDirection : 'column'};
    justify-content: ${(props) =>
        props.justifyContent ? props.justifyContent : 'center'};
    align-items: ${(props) => (props.alignItems ? props.alignItems : '')};
    width: 100%;
    padding: ${(props) => (props.padding ? props.padding : '12px')};
    font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
    font-family: ${fonts.ibm};
    height: ${(props) => (props.height ? props.height : '')};
    /* min-height: ${(props) =>
        props.minHeight ? props.minHeight : '44px'}; */
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '')};
    border-bottom: ${(props) => (props.borderBottom ? props.borderBottom : '')};
    &:not(#treatmentDetailModal) > div:not(:first-child) {
        margin-top: 20px;
    }
    #brushSlide {
        margin-top: 9px;
    }
    #brushSlide > div + div,
    &#rightSlide > div + div {
        margin-top: 10px;
    }
    :hover {
        background-color: ${(props) =>
            props.hoverColor ? props.hoverColor : ''};
    }
`;

const DetailModal = (props) => {
    return <ModalContainer {...props}>{props.children}</ModalContainer>;
};

export default DetailModal;
