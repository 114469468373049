import React from 'react';

const Hide = ({ width, height }) => (
    <svg
        width={width || '15'}
        height={height || '13'}
        viewBox='0 0 15 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M12.8492 4.95886C13.2292 5.29886 13.5492 5.62886 13.7892 5.88886C13.955 6.07261 14.0468 6.31134 14.0468 6.55886C14.0468 6.80638 13.955 7.04511 13.7892 7.22886C12.7392 8.35886 10.3492 10.5589 7.55918 10.5589H7.15918'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M4.42893 9.68884C3.27056 9.04008 2.2239 8.20951 1.32893 7.22884C1.16309 7.04509 1.07129 6.80636 1.07129 6.55884C1.07129 6.31132 1.16309 6.07259 1.32893 5.88884C2.37893 4.75884 4.76893 2.55884 7.55893 2.55884C8.6587 2.58182 9.73504 2.881 10.6889 3.42884'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M13.0586 1.05884L2.05859 12.0588'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M6.14859 7.96884C5.77301 7.59549 5.56083 7.08841 5.55859 6.55884C5.55859 6.0284 5.76931 5.5197 6.14438 5.14462C6.51945 4.76955 7.02816 4.55884 7.55859 4.55884C8.08817 4.56107 8.59524 4.77325 8.96859 5.14884'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M9.29859 7.55884C9.12058 7.86314 8.86529 8.11498 8.55859 8.28884'
            stroke='#525252'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
export default Hide;
