import React from 'react';

const Tooth12 = ({ color }) => (
    <svg
        style={{
            position: 'absolute',
            top: '8px',
            left: '59px',
        }}
        width='17'
        height='29'
        viewBox='0 0 17 29'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M11.7939 28.3243C10.1707 28.3243 8.61779 27.4543 6.74569 25.5144C6.73487 25.5036 6.70781 25.4766 6.69699 25.4658C6.52926 25.3037 6.36694 25.1254 6.21544 24.9417C4.79784 23.3476 4.20266 21.8994 4.42991 20.635C4.67339 19.3003 5.8421 18.0737 7.90358 16.9876C9.61877 16.0906 11.0526 15.6367 12.1726 15.6367C14.1151 15.6367 15.2188 16.939 16.1224 20.2892C17.7078 26.1358 15.9439 27.2544 13.8337 27.9568C13.06 28.2108 12.4107 28.3351 11.7939 28.3351V28.3243Z'
            fill={color || 'white'}
        />
        <path
            d='M0.430563 6.36328V5.77128H1.99056V1.27528H1.93456L0.526563 2.58728L0.134563 2.16328L1.61456 0.779281H2.66256V5.77128H4.10256V6.36328H0.430563ZM8.90359 6.36328H5.23959V5.67528L7.06359 3.99528C7.32492 3.75528 7.54359 3.50195 7.71959 3.23528C7.89559 2.96328 7.98359 2.69128 7.98359 2.41928V2.32328C7.98359 2.01395 7.89826 1.76328 7.72759 1.57128C7.55692 1.37928 7.29826 1.28328 6.95159 1.28328C6.78092 1.28328 6.63159 1.30728 6.50359 1.35528C6.37559 1.40328 6.26359 1.46995 6.16759 1.55528C6.07692 1.64061 5.99959 1.74195 5.93559 1.85928C5.87692 1.97128 5.82892 2.09395 5.79159 2.22728L5.19159 1.99528C5.24492 1.82995 5.31692 1.66995 5.40759 1.51528C5.50359 1.35528 5.62092 1.21395 5.75959 1.09128C5.90359 0.968614 6.07426 0.869948 6.27159 0.795281C6.47426 0.720614 6.71159 0.683281 6.98359 0.683281C7.25559 0.683281 7.49826 0.723281 7.71159 0.803281C7.92492 0.883281 8.10359 0.995281 8.24759 1.13928C8.39159 1.28328 8.50092 1.45395 8.57559 1.65128C8.65559 1.84861 8.69559 2.06728 8.69559 2.30728C8.69559 2.52595 8.66359 2.72861 8.59959 2.91528C8.54092 3.10195 8.45826 3.28061 8.35159 3.45128C8.24492 3.61661 8.11426 3.77928 7.95959 3.93928C7.81026 4.09928 7.64759 4.25928 7.47159 4.41928L5.96759 5.77128H8.90359V6.36328Z'
            fill={color || '#F4F4F4'}
        />
    </svg>
);

export default Tooth12;
