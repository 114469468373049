import React from 'react';

const Search = ({ width, height, color, opacity }) => (
    <svg
        width={width || '16'}
        height={height || '17'}
        viewBox='0 0 16 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <rect
            width={width || '16'}
            height={height || '16'}
            transform='translate(0 0.280518)'
            fill='none'
            // style='mix-blend-mode:multiply'
        />
        <path
            d='M14.5001 14.0735L10.7241 10.2974C11.6314 9.20809 12.0839 7.8109 11.9873 6.39647C11.8908 4.98205 11.2526 3.6593 10.2057 2.7034C9.15867 1.7475 7.78347 1.23203 6.36612 1.26424C4.94877 1.29645 3.5984 1.87385 2.59593 2.87632C1.59345 3.8788 1.01605 5.22917 0.983845 6.64652C0.951637 8.06387 1.4671 9.43907 2.423 10.486C3.37891 11.533 4.70165 12.1712 6.11608 12.2677C7.5305 12.3643 8.9277 11.9118 10.017 11.0045L13.7931 14.7805L14.5001 14.0735ZM2.00012 6.78051C2.00012 5.8905 2.26404 5.02047 2.75851 4.28045C3.25297 3.54043 3.95578 2.96365 4.77804 2.62306C5.60031 2.28246 6.50511 2.19335 7.37802 2.36698C8.25094 2.54061 9.05276 2.9692 9.6821 3.59853C10.3114 4.22787 10.74 5.02969 10.9137 5.90261C11.0873 6.77552 10.9982 7.68032 10.6576 8.50259C10.317 9.32486 9.7402 10.0277 9.00018 10.5221C8.26016 11.0166 7.39013 11.2805 6.50012 11.2805C5.30705 11.2792 4.16323 10.8047 3.3196 9.96103C2.47597 9.11741 2.00144 7.97358 2.00012 6.78051Z'
            fill={color || '#F4F4F4'}
            fillOpacity={opacity || '0.25'}
        />
    </svg>
);

export default Search;
