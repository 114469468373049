import React from 'react';

const SliderFoward = ({ color }) => (
    <svg
        width='15'
        height='12'
        viewBox='0 0 15 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M12.7 12V0H14.3154V12H12.7ZM0.31543 12V0L9.63081 6L0.31543 12Z'
            fill={color || '#525252'}
        />
    </svg>
);

export default SliderFoward;
